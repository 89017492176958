<div class="header-container">
  <div *ngIf="!inputData" class="header-filter">
    <div class="header-filter-left">
      <app-search-site-list
        (manageInput)="manageFilterSite($event)"
        [type]="'site'"
        [sites]="listSites"
        [inputData]="filterSite"
        [inputActive]="activeSite"
      ></app-search-site-list>

      <app-button-list
        [label]="'containerType'"
        [type]="'checkbox'"
        [icon]="'container'"
        [data]="containersList"
        [checkedData]="filterContainers"
        (manageList)="manageContainers($event)"
        [isEmpty]="!containersList.length"
      ></app-button-list>

      <app-button-list
        [label]="'device'"
        [type]="'checkbox'"
        [icon]="'device'"
        [data]="devicesList"
        [checkedData]="checkedDevices"
        (manageList)="manageDevice($event)"
        [isEmpty]="!devicesList.length"
      ></app-button-list>

      <app-button-list
        [label]="'lastAuthorizedSite'"
        [type]="'checkbox'"
        [icon]="'last-site'"
        [data]="lastSitesList"
        [checkedData]="checkedLastSites"
        (manageList)="manageLAS($event)"
        [isEmpty]="!lastSitesList.length"
      ></app-button-list>

      <app-button-list
        [label]="'days'"
        [type]="'checkbox'"
        [icon]="'calendar'"
        [data]="statusDaysList"
        [checkedData]="checkedStatusDays"
        (manageList)="manageStatusDays($event)"
        [isEmpty]="!statusDaysList.length"
        [disabled]="!statusDaysList.length"
      ></app-button-list>

      <app-button-list
        [label]="'radius'"
        [type]="'checkbox'"
        [icon]="'radius'"
        [data]="confidenceRadiusList"
        [checkedData]="checkedConfidenceRadius"
        (manageList)="manageConfidenceRadius($event)"
        [isEmpty]="!confidenceRadiusList.length"
      ></app-button-list>

      <app-button-list
        [label]="'incident'"
        [type]="'checkbox'"
        [icon]="'incident'"
        [data]="listIncidents"
        [checkedData]="checkedIncidents"
        (manageList)="manageIncidents($event)"
        [isEmpty]="isEmptyIncident && listIncidents === undefined"
      ></app-button-list>

      <app-button-list
        [label]="'torn'"
        [type]="'radio'"
        [icon]="'warn'"
        [data]="tornFilterOptions"
        [defaultRadioValue]="tornFilterOptions[0]"
        (manageList)="manageTorn($event)"
        [isEmpty]="!tornFilterOptions.length"
        [pathTranslate]="'filter.torn.label.{{item}}'"
      ></app-button-list>

      <div class="loadingSites">
        <div *ngIf="loading">
          <img
            id="loadingSites"
            src="../../../../../assets/img/spinner-sites.gif"
          />
        </div>
      </div>
    </div>

    <div class="header-filter-right">
      <app-button-list
        [iconOnly]="true"
        [label]="'addIncident'"
        [icon]="'add-incident'"
        [type]="'checkbox'"
        [data]="listAddIncident"
        [isLoading]="isLoading"
        [isEmpty]="isEmptyIncidentDevice"
        [disabled]="!isEmptyIncidentDevice"
        (manageList)="addIncident($event)"
      ></app-button-list>

      <app-status-dots [status]="statusList"></app-status-dots>

      <app-button-list
        [label]="'colorStatus'"
        [type]="'colorStatus'"
        [icon]="'color-status'"
        [data]="statusList"
        (manageList)="manageFilters($event)"
      ></app-button-list>
    </div>
  </div>
  <div *ngIf="inputData" class="header-filter">
    <div class="header-filter-left">
      <app-button
        class="back-button"
        (clicked)="backTolist()"
        iconLeft="arrow-left"
        color="grey"
        [rounded]="true"
      ></app-button>
      <app-button-list
        [label]="'inputData'"
        [type]="'inputData'"
        [data]="{ img: 'device', label: inputData.deviceId }"
      ></app-button-list>
      <app-button-list
        [label]="'inputData'"
        [type]="'inputData'"
        [data]="{ img: 'containerType', label: inputData.containerType }"
      ></app-button-list>
      <app-incident-tag
        *ngIf="inputData.isIncident"
        [currentState]="inputData"
      ></app-incident-tag>

      <div class="loadingSites">
        <div *ngIf="loading">
          <img
            id="loadingSites"
            src="../../../../../assets/img/spinner-sites.gif"
          />
        </div>
      </div>
    </div>

    <div class="header-filter-right">
      <app-status-dots
        [status]="statusList"
        [showType]="'showDetail'"
      ></app-status-dots>

      <app-button-list
        [label]="'colorStatus'"
        [type]="'colorStatus'"
        [data]="statusList"
        [showType]="'showDetail'"
        (manageList)="manageFilters($event)"
      ></app-button-list>
    </div>
  </div>
</div>
