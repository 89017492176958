import { ISiteDTO } from "psa-tnt-models";

export class SiteDTO implements ISiteDTO {
  code: string;
  category: string;
  label: string;
  toleranceRadiusInMeter: number;
  longitude: number;
  latitude: number;
  macAddresses?;
  addresses?;
  polyvalentsCount?;

  constructor(jsonObj: any) {
    this.code = jsonObj.code;
    this.category = jsonObj.category;
    this.label = jsonObj.label;
    this.toleranceRadiusInMeter = jsonObj.toleranceRadiusInMeter;
    this.longitude = jsonObj.longitude;
    this.latitude = jsonObj.latitude;

    this.macAddresses = jsonObj.macAddresses;
    this.addresses = jsonObj.addresses;
    this.polyvalentsCount = jsonObj.polyvalentsCount;
  }
}
