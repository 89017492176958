import { SiteDTO } from "./site";

export class IncidentDTO {
  id: number;
  title: string;
  type: string;
  latitude: number;
  longitude: number;
  currentSite?: string | SiteDTO;
  creationDate: number;
  status: string;
  createdBy: string;
  lastAuthorizedSite: string;
  assignedTo: string;
  priority: string;
  origin: string;
  containerType: string;
  tantQuantity: number;
  totalQuantity: number;
  checked?: boolean;

  constructor(jsonObj: any) {
    this.id = jsonObj.id;
    this.title = jsonObj.title;
    this.type = jsonObj.type;
    this.latitude = jsonObj.latitude;
    this.longitude = jsonObj.longitude;
    this.currentSite = jsonObj.currentSite;
    this.creationDate = jsonObj.creationDate;
    this.status = jsonObj.status;
    this.createdBy = jsonObj.createdBy;
    this.lastAuthorizedSite = jsonObj.lastAuthorizedSite;
    this.assignedTo = jsonObj.assignedTo;
    this.priority = jsonObj.priority;
    this.origin = jsonObj.origin;
    this.containerType = jsonObj.containerType;
    this.tantQuantity = jsonObj.tantQuantity;
    this.totalQuantity = jsonObj.totalQuantity;
  }
}

export class IncidentAssociatedDeviceDTO {
  id: number;
  incidentId: number;
  deviceId: string;
  containerType: string;
  lastAuthorizedSite: string;
  openingDate: number;
  assignedBy: string;
  isOpened: boolean;
  closingDate?: number;
  checked?: boolean;

  constructor(jsonObj: any) {
    this.id = jsonObj.id;
    this.incidentId = jsonObj.incidentId;
    this.containerType = jsonObj.containerType;
    this.lastAuthorizedSite = jsonObj.lastAuthorizedSite;
    this.openingDate = jsonObj.openingDate;
    this.assignedBy = jsonObj.assignedBy;
    this.isOpened = jsonObj.isOpened;
    this.closingDate = jsonObj.closingDate;
  }
}

export class IncidentCommentDTO {
  id: number;
  incidentId: number;
  comment: string;
  commentedBy: string;
  creationDate: number;

  constructor(jsonObj: any) {
    this.id = jsonObj.id;
    this.incidentId = jsonObj.incidentId;
    this.comment = jsonObj.comment;
    this.commentedBy = jsonObj.commentedBy;
    this.creationDate = jsonObj.creationDate;
  }
}
