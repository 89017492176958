<button
  type="button"
  [ngClass]="buttonClass"
  (click)="onClicked($event)"
  [disabled]="disabled"
>
  <svg-icon
    *ngIf="iconLeft"
    [name]="iconLeft"
    [svgStyle]="{ 'height.px': 16, 'width.px': 16 }"
  ></svg-icon>
  <p *ngIf="labelPath">
    {{ labelPath | translate }}
  </p>
  <svg-icon
    *ngIf="iconRight && !loading"
    [name]="iconRight"
    [svgStyle]="{ 'height.px': 16, 'width.px': 16 }"
  ></svg-icon>
  <ng-container *ngIf="loading">
    <mat-spinner [diameter]="16" class="mat-spinner-color"></mat-spinner>
  </ng-container>
</button>
