import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ReferenceSummary } from "../../../../models/reference-summary";
import { ReferenceNameControllerService } from "../../../../services/reference-name-controller.service";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from "@angular/material/dialog";
import { UploadDialogComponent } from "../upload-dialog/upload-dialog.component";
import {
  UploadDialogData,
  expectedColumnNames
} from "../upload-dialog/upload-dialog.typing";
import * as FileSaver from "file-saver";
import { getDateDayMonthYear } from "../../../../services/format-date";

@Component({
  selector: "app-matching",
  templateUrl: "./matching.component.html",
  styleUrls: ["./matching.component.scss"]
})
export class MatchingComponent implements OnInit {
  private bfrCuDownloadLoading: boolean = false;
  private hybridDownloadLoading: boolean = false;

  private readonly HYBRIDES_REFERENCE = "matching:Hybrides";
  private readonly BFR_CU_REFERENCE = "matching:BFR-CU";
  private readonly references: any = {
    Hybrides: this.HYBRIDES_REFERENCE,
    BFR_CU: this.BFR_CU_REFERENCE
  };
  private subscription: Subscription = new Subscription();
  private rawFile: string;
  private file: File;
  private dialogRef: MatDialogRef<UploadDialogComponent>;

  public readonly HYBRIDES: string = "Hybrides";
  public readonly BFR_CU: string = "BFR_CU";
  public isUploaded: boolean = false;
  public uploadMode: "Replace" | "Add" = "Replace";
  public typeSelected: string;
  public uploadDatas: any[];
  public fileName: string;
  public columnNames: string[];
  public referenceSummary: ReferenceSummary;
  public separatorError: boolean = false;
  public separatorErrorWarnings: string[] = [];

  public properties: any = {
    Hybrides: [
      { property: "racine" },
      { property: "expediteur" },
      { property: "hyb" },
      { property: "hybride" }
    ],
    BFR_CU: [{ property: "bfp" }, { property: "cu" }]
  };

  constructor(
    private referenceNameControllerService: ReferenceNameControllerService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}

  private onSelect(event: string) {
    this.typeSelected = event;
  }

  loadFile(file: File) {
    if (file) {
      let reader: FileReader = new FileReader();
      this.uploadDatas = [];
      this.isUploaded = true;
      reader.readAsText(file);
      reader.onload = (_e) => {
        let csv: string = reader.result as string;
        this.rawFile = csv;
        this.fileName = file.name;
        this.parseCSVData(csv);
      };
    }
  }

  private parseCSVData(csv: string) {
    let separator: string;
    let dataLines: string[] = csv.split("\r\n");
    this.separatorError = false;
    this.separatorErrorWarnings = [];
    let dataLinesError: Array<[number, string]> = [];
    dataLines = dataLines.length === 1 ? csv.split("\n") : dataLines;
    const columnNamesColon: string[] = dataLines[0].split(",");
    const columnNamesSemiColon: string[] = dataLines[0].split(";");
    separator =
      columnNamesSemiColon.length >= columnNamesColon.length ? ";" : ",";
    this.columnNames = dataLines[0].split(separator);
    dataLines.shift();
    let uploadDatas: string[][] = [];
    let props: string[];
    let count = 1;
    for (const dataLine of dataLines) {
      count += 1;
      if (dataLine.length > this.properties[this.typeSelected].length * 2) {
        props = dataLine.split(separator);
        if (props.length !== this.columnNames.length) {
          this.separatorError = true;
          dataLinesError.push([count, dataLine]);
        }
        uploadDatas.push(props);
      }
    }
    if (this.separatorError) {
      this.separatorErrorWarnings.push(
        `Separator isn't adequate for the file :`
      );
      this.separatorErrorWarnings.push(
        ` • The separator "${separator}" in the file isn't only used to separate columns. This could lead to some errors while uploading datas.`
      );
      separator === ","
        ? this.separatorErrorWarnings.push(
            ` • Please review your file and/or consider changing your separator to ";"`
          )
        : this.separatorErrorWarnings.push(
            ` • Please review your file and consider using ";" only to separate columns.`
          );
      this.separatorErrorWarnings.push(`Lines with a problem : `);
      for (const dataLineError of dataLinesError) {
        this.separatorErrorWarnings.push(
          ` • line ${dataLineError[0]} : ${dataLineError[1]}`
        );
      }
    }
    this.uploadDatas = uploadDatas;
  }

  public backOnList() {
    this.typeSelected = undefined;
    this.isUploaded = false;
  }

  public doProvisioning() {
    const date: number = Date.now();
    const userName: string = localStorage.getItem("username");
    this.saveFile(this.references[this.typeSelected], this.rawFile);
    this.saveFile(
      `reference:${userName}:${this.typeSelected}:${date}`,
      this.rawFile
    );
  }

  saveFile(referenceNameId: string, text: string) {
    this.subscription.add(
      this.referenceNameControllerService
        .createReferenceFile(
          { referenceNameId, uploadDate: Date.now() },
          text,
          this.uploadMode
        )
        .subscribe((_result) => {
          this.referenceSummary = {
            info: [`L'upload du fichier ${this.fileName} a réussi`],
            warnings: [],
            details: [`Nombre d'entrées: ${this.uploadDatas.length}`]
          };
        })
    );
  }

  public changeUploadMode(mode: "Replace" | "Add") {
    this.uploadMode = mode;
  }

  public setDownloadLoading(
    referenceType: "Hybrides" | "BFR_CU",
    value: boolean
  ): void {
    if (referenceType === "Hybrides") {
      this.hybridDownloadLoading = value;
    } else {
      this.bfrCuDownloadLoading = value;
    }
  }

  public async downloadFile(referenceType: "Hybrides" | "BFR_CU") {
    this.setDownloadLoading(referenceType, true);
    try {
      const result = await this.referenceNameControllerService
        .getMatching(referenceType)
        .toPromise();
      if (!result?.text) {
        console.error(
          `An error occurred while retrieving the ${referenceType} file`
        );
        return;
      }
      const blob = new Blob([result.text], { type: "text/csv" });
      FileSaver.saveAs(blob, `${referenceType}_${getDateDayMonthYear()}.csv`);
    } catch (error) {
      console.error("Error downloading hybrid file:", error);
    }
    this.setDownloadLoading(referenceType, false);
  }

  public downloadTemplate(referenceType: "Hybrides" | "BFR_CU") {
    const csvContent = expectedColumnNames[referenceType].join(",") + "\r\n";
    const blob = new Blob([csvContent], { type: "text/csv" });
    FileSaver.saveAs(blob, `${referenceType}_${getDateDayMonthYear()}.csv`);
  }

  public async getPreviousRowNumber(referenceType: "Hybrides" | "BFR_CU") {
    this.subscription.add(
      this.referenceNameControllerService
        .getMatching(referenceType)
        .subscribe((result) => {
          if (result?.rowNumber) {
            this.dialogRef.componentInstance.data.previousRowNumber =
              result.rowNumber;
          }
        })
    );
  }

  public async openDialog(referenceType: "Hybrides" | "BFR_CU") {
    this.typeSelected = referenceType;

    await this.getPreviousRowNumber(referenceType);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;

    this.dialogRef = this.dialog.open(UploadDialogComponent, {
      panelClass: "upload-dialog-container",
      autoFocus: false,
      data: {
        previousRowNumber: undefined,
        loadFile: this.loadFile.bind(this),
        changeUploadMode: this.changeUploadMode.bind(this),
        referenceType: this.typeSelected
      } as UploadDialogData
    });
  }
}
