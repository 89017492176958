<div class="date-range">
  <div class="date-range-txt">{{ placeholderPath | translate }}</div>
  <mat-form-field appearance="outline">
    <input
      class="date-range-input"
      matInput
      [matDatepickerFilter]="dateFilter"
      [matDatepicker]="picker"
      (dateChange)="onDateChange($event)"
      [(ngModel)]="selectedDate"
    />
    <mat-datepicker-toggle
      class="date-range-datepicker"
      matSuffix
      [for]="picker"
    ></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
