import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[infiniteScroll]"
})
export class InfiniteScrollDirective {
  constructor(private element: ElementRef) {}

  @Input("infiniteScroll") loadMore: () => void;
  @Input() scrollRatio: number = 0.8;
  @Input() initScrollThreshold: number; // px
  @HostListener("scroll")
  public onScroll() {
    const scrolled: number = this.element.nativeElement.scrollTop;
    const clientHeight: number = this.element.nativeElement.clientHeight;
    const scrollHeight: number = this.element.nativeElement.scrollHeight;

    if (
      clientHeight + scrolled >= scrollHeight * this.scrollRatio &&
      scrolled >= this.initScrollThreshold
    ) {
      this.loadMore();
    }
  }

  public resetScrollbar() {
    this.element.nativeElement.scrollTop = 0;
  }
}
