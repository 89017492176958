<div class="column-names-wrapper">
  <div class="columns">
    <div class="incident-checkbox-column state-displayer-column">
      <mat-checkbox
        *ngIf="hasCheckbox"
        color="primary"
        (click)="$event.stopPropagation()"
        (change)="checkboxUpdate.emit($event)"
      >
      </mat-checkbox>
    </div>
    <div class="timestamp-column state-displayer-column">
      <p class="column-name">{{ "list.htDate" | translate }}</p>
    </div>
    <div class="device-column state-displayer-column">
      <p class="column-name">{{ "list.htDeviceId" | translate }}</p>
    </div>
    <div class="container-column state-displayer-column">
      <p class="column-name">{{ "list.htContainer" | translate }}</p>
    </div>
    <div class="status-column state-displayer-column">
      <p class="column-name">{{ "list.htStatus" | translate }}</p>
    </div>
    <div class="radius-column state-displayer-column">
      <p class="column-name">{{ "list.htRadius" | translate }}</p>
    </div>
    <div
      class="position-column state-displayer-column"
      [class.large-state-column]="type === 'List' || type === 'ListDetail'"
    >
      <p class="column-name">{{ "list.htPosition" | translate }}</p>
    </div>
    <div
      class="last-authorized-site-column state-displayer-column"
      [class.large-state-column]="type === 'List' || type === 'ListDetail'"
    >
      <p class="column-name">{{ "list.htLastSite" | translate }}</p>
    </div>
    <div
      *ngIf="type === 'List' || type === 'ListDetail'"
      class="time-last-site-column state-displayer-column"
    >
      <p class="column-name">
        {{ "list.htTimeLastAuthorizedSite" | translate }}
      </p>
    </div>
    <div class="labels-column state-displayer-column">
      <p class="column-name">{{ "list.htLabels" | translate }}</p>
    </div>
    <ng-container *ngIf="type === 'List' || type === 'ListDetail'">
      <div
        *ngIf="isAdmin('IBMAdmin')"
        class="seq-number-column state-displayer-column"
      >
        <p class="column-name">{{ "list.htSeq" | translate }}</p>
      </div>
      <div
        *ngIf="isAdmin('IBMAdmin')"
        class="source-column state-displayer-column"
      >
        <p class="column-name">{{ "list.htSource" | translate }}</p>
      </div>
      <div class="battery-column state-displayer-column">
        <p class="column-name">{{ "list.htBattery" | translate }}</p>
      </div>
      <div
        *ngIf="type === 'List' || type === 'ListDetail'"
        class="maps-column state-displayer-column"
      >
        <p class="column-name">{{ "list.htMaps" | translate }}</p>
      </div>
    </ng-container>
  </div>
  <div class="information-divider"></div>
</div>
