<div class="body-main" *ngIf="!isUploaded && !isAddObject">
  <!-- Header Bar Labels -->
  <div class="bar-header">
    <div class="bar-title">
      {{ "manager.refName." + formConfig.title + ".manage" | translate }}
    </div>
    <div class="bar-description">
      {{ lastUpload }}
    </div>
  </div>
  <!-- Page Content Main -->
  <div class="page-content">
    <app-manager-list
      *ngIf="!isAddObject"
      (add)="addObject($event)"
      (selectElement)="selectObject($event)"
      (changeFilter)="changeFilterObject($event)"
      (changePage)="changePageObject($event)"
      (loadFile)="loadFile($event)"
      (changeUploadMode)="changeUploadMode($event)"
      [displayObjects]="objects"
      [maxItems]="numberOfAllObjects"
      [properties]="properties"
      [hasModalUpload]="true"
      [uploadMode]="uploadMode"
      [referenceType]="MATCHING_TYPE[formConfig.name]"
      [service]="service"
    ></app-manager-list>
  </div>
</div>

<div class="body-main" *ngIf="!isUploaded && isAddObject">
  <!-- Header Bar Buttons -->
  <div class="title-sample-header">
    <div class="page-title-left" (click)="backToList()">
      <div class="path">
        {{ "manager.refName." + formConfig.title + ".manage" | translate }}
      </div>
      <div class="separator">/</div>
    </div>

    <div class="page-title-right">
      <div class="delete-image" (click)="deleteObject()">
        <svg-icon
          name="bin"
          [svgStyle]="{ fill: '#E40101', 'height.px': 16, 'width.px': 16 }"
        ></svg-icon>
      </div>
      <div class="action-buttons">
        <div class="cancel-button" (click)="backToList()">
          <div class="text">{{ "misc.cancel" | translate }}</div>
        </div>
        <div
          [class]="
            newObjectIsValid === true ? 'save-button' : 'save-button-disabled'
          "
          (click)="saveChanges()"
        >
          <div class="text">
            {{ "manager.form.validFormLabel." + updateModeLabel | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Page Content Detail -->
  <div class="page-content">
    <!-- Side Nav -->
    <div class="nav-section-list">
      <div class="navigator">
        <div class="nav-selector-open">
          {{ "manager.navName." + formConfig.title | translate }}
        </div>
      </div>
    </div>
    <!-- Nav Content -->
    <div class="main-section">
      <!-- Tab 1 -->
      <div class="form-holder">
        <app-manager-form
          (newObject)="changeSelectedObject($event)"
          (formIsValid)="isFormValid($event)"
          [formConfig]="formConfig"
          [update]="updateMode"
        ></app-manager-form>
      </div>
    </div>
  </div>
</div>

<!-- Uploader -->
<div class="body-main" *ngIf="isUploaded">
  <app-file-uploader
    [datas]="uploadDatas"
    [properties]="provisioningProperties"
    [fileName]="fileName"
    [referenceSummary]="referenceSummary"
    [columnNames]="columnNames"
    [loadingPercentage]="percentage"
    [uploaderType]="uploaderType"
    [separatorError]="separatorError"
    [separatorErrorWarnings]="separatorErrorWarnings"
    (goBack)="backOnList()"
    (doProvisioning)="doProvisioning($event)"
  >
  </app-file-uploader>
</div>
