<app-manager-list
  (selectElement)="isOpen($event)"
  (changePage)="changePageDevice($event)"
  (changeFilter)="changeFilterDevice($event)"
  (delete)="deleteDevices()"
  [displayObjects]="deviceList"
  [maxItems]="numberOfAllDevice"
  [properties]="deviceProperties"
  (selectAll)="selectAllDevices()"
  [allCheck]="selectAll"
></app-manager-list>
