import {
  Component,
  OnChanges,
  Input,
  SimpleChanges,
  OnDestroy
} from "@angular/core";
import { CurrentStateDTO } from "../../../../models/current-state";
import { IncidentDTO } from "../../../../models/incident";
import { IncidentControllerService } from "../../../../services/incident-controller.service";
import { Subscription } from "rxjs";
import * as moment from "moment/moment";

@Component({
  selector: "app-incident-tag",
  templateUrl: "./incident-tag.component.html",
  styleUrls: ["./incident-tag.component.scss"]
})
export class IncidentTagComponent implements OnChanges, OnDestroy {
  @Input() currentState: CurrentStateDTO;

  public incidentTooltip: string = "";

  private subscription: Subscription = new Subscription();
  private incidentData: IncidentDTO;

  constructor(private incidentCtrl: IncidentControllerService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentState) {
      this.getIncidentData();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getIncidentData(): void {
    this.subscription.add(
      this.incidentCtrl.getFirstIncident(this.currentState.deviceId).subscribe(
        (incident) => {
          this.incidentData = incident;
          this.incidentTooltip = `${incident.assignedTo}\n${moment
            .unix(incident.creationDate)
            .format("DD/MM/YY")} - ${incident.type}\n${incident.title}`;
        },
        (err) => {
          console.log(err);
          this.incidentTooltip = "";
        }
      )
    );
  }
}
