import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { DeviceOffContractDTO } from "../models/device-off-contract";
import { CountDTO } from "../models/historical-state";
import { ReferenceSummary } from "../models/reference-summary";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * DeviceOffContractController service call api rest for DeviceOffContract
 */

@Injectable()
export class DeviceOffContractControllerService {
  constructor(
    public httpClientWrapper: HttpClientWrapper<DeviceOffContractDTO>,
    public httpClientCount: HttpClientWrapper<CountDTO>,
    public httpClientRef: HttpClientWrapper<ReferenceSummary>
  ) {}

  getDevices(
    skip: number,
    limit: number,
    filterInput?: any
  ): Observable<DeviceOffContractDTO[]> {
    let filter: any = {};
    if (filterInput) {
      filter.where = filterInput;
    }
    let filterFormated: string = "?filter=";
    filter.skip = skip;
    filter.limit = limit;
    filter.order = "date DESC";
    filterFormated = filterFormated + JSON.stringify(filter);

    return this.httpClientWrapper.get(
      environment.apiBaseUrl + "devices-off-contract" + filterFormated
    );
  }

  getCount(filterInput?: any): Observable<CountDTO> {
    let filterFormated: string = "?where=";
    let filter: any = {};
    if (filterInput) {
      filter = filterInput;
    }
    filterFormated = filterFormated + JSON.stringify(filter);
    return this.httpClientCount.getUnique(
      environment.apiBaseUrl + "devices-off-contract/count" + filterFormated
    );
  }

  doVerifying(devices: DeviceOffContractDTO[]) {
    return this.httpClientRef.postUnique(
      environment.apiBaseUrl + "devices-off-contract/verify",
      devices
    );
  }

  doProvisioning(devices: DeviceOffContractDTO[]) {
    return this.httpClientRef.postUnique(
      environment.apiBaseUrl + "devices-off-contract/upload",
      devices
    );
  }

  getProvisioningProperties(): any[] {
    return [{ property: "deviceId" }];
  }
}
