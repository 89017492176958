<div class="site-sidenav" [@slideInOut]="openingState">
  <div class="site-title-wrapper">
    <p class="site-title">{{ siteTitle }}</p>
  </div>
  <div class="information-divider"></div>
  <div class="site-info-wrapper">
    <p class="sidenav-subtitle">{{ "sideSite.siteInfo" | translate }}</p>
    <div class="icon-info-wrapper">
      <div class="single-icon-info-wrapper">
        <svg-icon name="container" class="icon"></svg-icon>
        <p class="sidenav-small-text">
          {{
            "sideSite.containers"
              | translate: { totalNumber: totalDeviceNumber }
          }}
        </p>
      </div>
      <div class="single-icon-info-wrapper">
        <svg-icon
          [name]="macAddressesMapped ? 'wifi' : 'no-wifi'"
          class="icon"
        ></svg-icon>
        <p class="sidenav-small-text">
          {{
            (macAddressesMapped
              ? "sideSite.macAddressesMapped"
              : "sideSite.macAddressesNotMapped"
            ) | translate
          }}
        </p>
      </div>
    </div>
  </div>
  <div class="information-divider"></div>
  <div class="container-info-wrapper">
    <div class="container-type-pie-chart">
      <p class="sidenav-subtitle">
        {{ "sideSite.containerType" | translate }}
      </p>
      <div
        id="containerTypePieChart"
        [class.empty-graph]="
          !containerTypeLabels.length || !containerTypeValues.length
        "
      >
        <p
          class="sidenav-small-text"
          *ngIf="!containerTypeLabels.length || !containerTypeValues.length"
        >
          {{ "sideSite.noData" | translate }}
        </p>
      </div>
    </div>
    <div class="status-bar-chart">
      <p class="sidenav-subtitle">
        {{ "sideSite.status" | translate }}
      </p>
      <div
        id="statusBarChart"
        [class.empty-graph]="
          !containerTypeLabels.length || !containerTypeValues.length
        "
      >
        <p
          class="sidenav-small-text"
          *ngIf="!containerTypeLabels.length || !containerTypeValues.length"
        >
          {{ "sideSite.noData" | translate }}
        </p>
      </div>
    </div>
  </div>

  <div class="information-divider"></div>
  <div class="bar-charts">
    <div class="single-bar-chart">
      <p class="sidenav-subtitle">
        {{
          (meanStaticDaysLabels.length >= 5
            ? "sideSite.staticBarChartTitleTop5"
            : "sideSite.staticBarChartTitle"
          ) | translate
        }}
      </p>
      <div
        id="staticBarChart"
        [class.empty-graph]="
          !meanStaticDaysLabels.length || !meanStaticDaysValues.length
        "
      >
        <p
          class="sidenav-small-text"
          *ngIf="!meanStaticDaysLabels.length || !meanStaticDaysValues.length"
        >
          {{ "sideSite.noData" | translate }}
        </p>
      </div>
    </div>
    <div class="single-bar-chart">
      <p class="sidenav-subtitle">
        {{
          (alertLabels.length >= 5
            ? "sideSite.alertBarChartTitleTop5"
            : "sideSite.alertBarChartTitle"
          ) | translate
        }}
      </p>
      <div
        id="alertBarChart"
        [class.empty-graph]="!alertLabels.length || !alertValues.length"
      >
        <p
          class="sidenav-small-text"
          *ngIf="!alertLabels.length || !alertValues.length"
        >
          {{ "sideSite.noData" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
