import {
  Component,
  OnChanges,
  ElementRef,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { NotifierService } from "angular-notifier";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-searchbar-number",
  templateUrl: "./searchbar-number.component.html",
  styleUrls: ["./searchbar-number.component.scss"]
})
export class SearchbarNumberComponent implements OnChanges {
  private readonly notifier: NotifierService;

  @ViewChild("inputNumberRef") inputNumberRef: ElementRef;

  @Output() number: EventEmitter<number> = new EventEmitter<number>();

  @Input() label: string = "";
  @Input() icon: string = "";
  @Input() inputActive: boolean = false;
  @Input() inputData: string = "";

  private savedInputData: string = "";

  constructor(
    private notifierService: NotifierService,
    private translate: TranslateService
  ) {
    this.notifier = notifierService;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputData) {
      this.savedInputData = this.inputData;
    }
  }

  public inputFocus(): void {
    this.inputNumberRef.nativeElement.focus();
  }

  public inputEnter(): void {
    const data: number = parseInt(this.inputData, 10);

    if (!isNaN(data)) {
      this.number.emit(data);
      this.inputNumberRef.nativeElement.blur();
    } else {
      this.inputData = "";
      this.number.emit(undefined);
      this.translate.get("filter.number.error").subscribe((msg) => {
        this.notifier.notify("error", msg);
      });
    }
    this.inputNumberRef.nativeElement.blur();
  }

  public inputBlur(): void {
    if (!this.inputData && !this.savedInputData) return;

    const data: number = parseInt(this.inputData, 10);

    if (isNaN(data)) {
      if (!this.savedInputData) {
        this.inputData = "";
        this.translate.get("filter.number.error").subscribe((msg) => {
          this.notifier.notify("error", msg);
        });
      } else {
        this.inputData = this.savedInputData;
      }
    } else {
      if (this.inputData !== this.savedInputData) {
        this.number.emit(data);
      }
    }
  }

  public clearInput(): void {
    this.inputData = "";
    this.number.emit(undefined);
  }
}
