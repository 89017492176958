import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { CountDTO } from "../models/historical-state";
import { RightDTO } from "../models/right";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * SitePostalController service call api rest for PostalAddress - return PostalAddressDTO
 */

@Injectable()
export class RightControllerService {
  constructor(
    public httpClientRight: HttpClientWrapper<RightDTO>,
    public httpClientCount: HttpClientWrapper<CountDTO>
  ) {}

  getRights(skip?: number, limit?: number): Observable<RightDTO[]> {
    let filter: any = {};

    if (skip) filter.skip = skip;
    if (limit) filter.limit = limit;
    filter.order = "rightId DESC";

    return this.httpClientRight.get(
      `${environment.apiBaseUrl}rights?filter=${JSON.stringify(filter)}`
    );
  }

  getCount(): Observable<CountDTO> {
    return this.httpClientCount.getUnique(
      environment.apiBaseUrl + "rights/count"
    );
  }
}
