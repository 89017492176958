import { Component, EventEmitter, Output, Input } from "@angular/core";

@Component({
  selector: "app-button-list-footer",
  templateUrl: "./button-list-footer.component.html",
  styleUrls: ["./button-list-footer.component.scss"]
})
export class ButtonListFooterComponent {
  @Output() displayMore: EventEmitter<void> = new EventEmitter();
  @Output() clearItems: EventEmitter<void> = new EventEmitter();
  @Output() applyItems: EventEmitter<boolean> = new EventEmitter();

  @Input() isNewIncident: boolean = false;
  @Input() label: string;
  @Input() type: string;
  @Input() isLoading: boolean = false;

  public footerType: any = {
    addIncident: "incident",
    incident: "incident",
    dateRange: "reset",
    graphic: "radio",
    torn: "radio"
  };

  constructor() {}

  public onClearItems(): void {
    this.clearItems.emit();
  }

  public onApplyItems(): void {
    if (this.isNewIncident) {
      this.applyItems.emit(true);
    } else {
      this.applyItems.emit();
    }
  }

  public onDisplayMore(): void {
    this.displayMore.emit();
  }
}
