import { ContainerTypeDTO } from "./container-type";
import { RightContainerTypesDTO } from "./right-container-types";

export class UserCreationDTO {
  user: UserDTO;
  rightContainerTypes: RightContainerTypesDTO[];

  constructor(jsonObj: any) {
    this.user = jsonObj.user;
    this.rightContainerTypes = jsonObj.rightContainerTypes;
  }
}
export class UserDTO {
  id?: string;
  name: string;
  email: string;

  profiles?;
  selectedContainerTypes?: ContainerTypeDTO[];
  password?: string;
  rightId: string;
  rightMobileApp?: string;
  profileContainerTypeId?: string;

  constructor(jsonObj: any) {
    this.id = jsonObj.id;
    this.name = jsonObj.name;
    this.email = jsonObj.email;
    this.profiles = jsonObj.profiles;
    this.selectedContainerTypes = jsonObj.selectedContainerTypes;
    this.rightId = jsonObj.rightId;
    this.profileContainerTypeId = jsonObj.profileContainerTypeId;
  }
}
