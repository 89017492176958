import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import * as io from "socket.io-client";
import { environment } from "../../environments/environment";
import { WsData } from "../models";
import { ReferenceSummary } from "../models/reference-summary";
import { AuthInterceptor } from "./auth-interceptor.service";
import { InternalUserControllerService } from "./internal-user-controller.service";

export interface WebSocketInstance {
  socket: io.Socket;
  message: Subject<string>;
  authValidate: boolean;
}

@Injectable()
export class WebSocketService {
  private readonly TIMEOUT: number = 10 * 60 * 1000; // 10 min max between each socket message before timeout

  sockets: Map<string, WebSocketInstance> = new Map();

  private timeout: NodeJS.Timeout;
  private timeoutAuth: NodeJS.Timeout;

  private authInterceptor: AuthInterceptor;

  messages: Subject<any>;

  // Our constructor calls our wsService connect method
  constructor(
    private router: Router,
    private _userService: InternalUserControllerService,
    public dialog: MatDialog
  ) {
    this.authInterceptor = new AuthInterceptor(router, _userService, dialog);
  }

  public async connect(namespace: string) {
    try {
      let socket: io.Socket = io(`${environment.apiBaseUrl}${namespace}`, {
        query: { token: this.getToken() },
        path: "/socket.io",
        transports: ["websocket"],
        secure: true
      });
      socket.on("error", (_message) => {
        this.authInterceptor.redirection();
      });
      let message: Subject<string> = new Subject();
      let authValidate: boolean = false;
      let webSocketInstance: WebSocketInstance = {
        socket: socket,
        message: message,
        authValidate: authValidate
      };
      this.sockets.set(namespace, webSocketInstance);
    } catch (e) {
      console.log(e);
    }
  }

  public async sendMessage(message: WsData, namespace: string) {
    let webSocketInstance: WebSocketInstance = this.sockets.get(namespace);
    if (webSocketInstance.authValidate) {
      message.userName = this.getName();
      await webSocketInstance.socket.emit("message", JSON.stringify(message));
    } else {
      this.timeoutAuth = setTimeout(() => {
        this.sendMessage(message, namespace);
      }, 100);
    }
  }

  public getNewMessage(namespace: string): Observable<string> {
    let webSocketInstance: WebSocketInstance = this.sockets.get(namespace);
    webSocketInstance.socket.on("message", (message) => {
      if (webSocketInstance.authValidate) {
        webSocketInstance.message.next(message);
        clearTimeout(this.timeout);
        this.timeout = setTimeout(
          () => this.handleTimeout(webSocketInstance.message),
          this.TIMEOUT
        );
      }
      if (!webSocketInstance.authValidate && message === this.getName()) {
        webSocketInstance.authValidate = true;
      }
    });
    return webSocketInstance.message.asObservable();
  }

  public async disconnect(namespace: string) {
    let webSocketInstance: WebSocketInstance = this.sockets.get(namespace);
    if (webSocketInstance) {
      webSocketInstance.authValidate = false;
      await webSocketInstance.socket.disconnect();
      clearTimeout(this.timeout);
      this.sockets.delete(namespace);
    }
  }

  private getToken(): string {
    return localStorage.getItem("password");
  }

  private getName(): string {
    return localStorage.getItem("username");
  }

  handleTimeout(message: Subject<string>) {
    const referenceSummary: ReferenceSummary = {
      info: [],
      warnings: ["Timeout error"]
    };
    const wsData: WsData = {
      messageType: "Error",
      userName: this.getName(),
      referenceType: "Timeout",
      referenceSummary: referenceSummary
    };
    message.next(JSON.stringify(wsData));
  }
}
