import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { PolyvalentMinMaxDTO } from "../models/polyvalent-min-max";
import { SiteDTO } from "../models/site";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * PolyvalentController service call api rest for PolyvalentMinMax - return PolyvalentMinMaxDTO
 */

@Injectable()
export class PolyvalentControllerService {
  constructor(
    public httpClientWrapper: HttpClientWrapper<PolyvalentMinMaxDTO>
  ) {}

  getPolyvalentBySiteId(site: SiteDTO): Observable<PolyvalentMinMaxDTO[]> {
    return this.httpClientWrapper.get(
      environment.apiBaseUrl + "/sites/" + site.code + "/polyvalent-min-max"
    );
  }
}
