import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment/moment";
import { CurrentStateDTO } from "../../../../../models/current-state";
import { getBatteryIconName } from "../../../../../services/get-icon";
import { ColorService } from "../../../../../services/color.service";
@Component({
  selector: "app-device-info-window",
  templateUrl: "./device-info-window.component.html",
  styleUrls: ["./device-info-window.component.scss"]
})
export class DeviceInfoWindowComponent implements OnInit {
  @Input() currentState: CurrentStateDTO;
  @Input() isAdmin: boolean;
  @Input() isAtHistoryEnd: boolean;
  @Input() isAtHistoryBeginning: boolean;

  @Output() previousPos: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextPos: EventEmitter<void> = new EventEmitter<void>();
  @Output() firstPos: EventEmitter<void> = new EventEmitter<void>();
  @Output() lastPos: EventEmitter<void> = new EventEmitter<void>();

  constructor(public colorService: ColorService) {}

  ngOnInit(): void {}

  public getPosition(): string {
    return this.currentState.currentSite
      ? this.currentState.currentSite.toString()
      : `${this.currentState.latitudeComputed.toFixed(
          5
        )}, ${this.currentState.longitudeComputed.toFixed(5)}`;
  }

  public getDate(): string {
    return moment
      .unix(this.currentState.receivedMessageTime)
      .format("DD/MM/YYYY HH:mm");
  }

  public getLocationSourceIcon(): string {
    return this.currentState.locationSource.includes("Wifi") ? "wifi" : "radio";
  }

  public getLocationSourceKey(): string {
    if (this.currentState.locationSource.includes("Private Wifi")) {
      return "map-detail.privateWifiLocation";
    } else if (this.currentState.locationSource.includes("Wifi")) {
      return "map-detail.publicWifiLocation";
    } else {
      return "map-detail.radioLocation";
    }
  }

  public getRadius(): string {
    return `${this.currentState.confidenceRadiusInMeter} m`;
  }

  public getBatteryLevel(): string | undefined {
    switch (this.currentState.batteryLevel) {
      case "Empty":
        return "map-detail.batteryLevel.empty";
      case "Almost Empty":
        return "map-detail.batteryLevel.almostEmpty";
      case "Good":
        return "map-detail.batteryLevel.good";
      case "Excellent":
        return "map-detail.batteryLevel.excellent";
      default:
        return undefined;
    }
  }

  public getBatteryIcon: (batteryLevel: string) => string | undefined =
    getBatteryIconName;

  public isLastAuthorizedSiteVisible(): boolean {
    return Boolean(
      this.currentState.lastAuthorizedSite &&
        this.currentState.timeLastAuthorizedSite
    );
  }

  public getLastAuthorizedSite(): string | undefined {
    if (
      this.isLastAuthorizedSiteVisible() &&
      this.currentState.lastAuthorizedSite?.length
    ) {
      return this.currentState.lastAuthorizedSite;
    }
  }

  public getTimeLastAuthorizedSite(): string | undefined {
    if (this.isLastAuthorizedSiteVisible()) {
      return moment
        .unix(this.currentState.timeLastAuthorizedSite)
        .format("DD/MM/YYYY HH:mm");
    } else {
      return undefined;
    }
  }
}
