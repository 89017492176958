import { Component, Input, OnChanges } from "@angular/core";
import { ContainerTypeDTO } from "../../../../models/container-type";
import { ContainerTypeAuthorizedSiteDTO } from "../../../../models/container-type-authorized-site";
import { SiteDTO } from "../../../../models/site";
import { ContainerTypeAuthSiteControllerService } from "../../../../services/container-type-auth-site-controller.service";
import { ContainerTypeControllerService } from "../../../../services/container-type-controller.service";
import { ReferenceNameControllerService } from "../../../../services/reference-name-controller.service";
import { SiteControllerService } from "../../../../services/site-controller.service";
import { UserRightsService } from "../../../../services/user-rights.service";
import { MacAddressControllerService } from "../../../../services/mac-address-controller.service";
import { MacAddressDTO } from "../../../../models/mac-address";

interface NavObject {
  navName?: string;
  isOpen?: boolean;
  selectorStyle?: string;
  navLogo?: string;
}

@Component({
  selector: "app-reference-manager",
  templateUrl: "./reference-manager.component.html",
  styleUrls: ["./reference-manager.component.scss"]
})
export class ReferenceManagerComponent implements OnChanges {
  private readonly SELECTOR_STYLE_OPEN = "nav-selector-open";
  private readonly SELECTOR_STYLE_CLOSE = "nav-selector-close";
  private readonly SELECTOR_STYLE_LOADING = "nav-selector-loading";
  selectedIndex: number;

  navObject: NavObject[] = [
    {
      navName: "Sites",
      isOpen: true,
      selectorStyle: this.SELECTOR_STYLE_OPEN
    },
    {
      navName: "containerTypes",
      isOpen: false,
      selectorStyle: this.SELECTOR_STYLE_CLOSE
    },
    {
      navName: "Routes",
      isOpen: false,
      selectorStyle: this.SELECTOR_STYLE_CLOSE
    },
    {
      navName: "macAddresses",
      isOpen: false,
      selectorStyle: this.SELECTOR_STYLE_CLOSE
    },
    {
      navName: "Matching",
      isOpen: false,
      selectorStyle: this.SELECTOR_STYLE_CLOSE
    }
  ];

  formConfigCT: any = {
    name: "Container Type",
    title: "containerTypes",
    type: ContainerTypeDTO,
    inputs: [
      {
        property: "code",
        title: "code",
        type: "text",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "category",
        title: "category",
        type: "string",
        selector: true,
        choices: ["specific", "polyvalent"],
        mandatory: true,
        visible: true
      },
      {
        property: "label",
        title: "label",
        type: "text",
        input: true,
        mandatory: true,
        visible: true
      }
    ]
  };

  ctProperties: any[] = [
    {
      label: "code",
      property: "code",
      canBeFiltered: true,
      width: 15
    },
    {
      label: "category",
      property: "category",
      canBeFiltered: true,
      width: 15
    },
    {
      label: "label",
      property: "label",
      canBeFiltered: true,
      width: 15
    }
  ];

  formConfigCTAuth: any = {
    name: "Route",
    title: "Routes",
    type: ContainerTypeAuthorizedSiteDTO,
    inputs: [
      {
        property: "containerTypeId",
        title: "ctType",
        type: "text",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "siteId",
        title: "siteId",
        type: "text",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "warningTransitTime",
        title: "transitTime",
        type: "text",
        input: true,
        mandatory: true,
        visible: true,
        transform: "number",
        validityConditions: [
          {
            type: "greater",
            value: 1440
          }
        ]
      }
    ]
  };

  ctAuthProperties: any[] = [
    {
      label: "ctType",
      property: "containerTypeId",
      canBeFiltered: true,
      width: 15
    },
    {
      label: "siteId",
      property: "siteId",
      canBeFiltered: true,
      width: 15
    },
    {
      label: "transitTime",
      property: "warningTransitTime",
      canBeFiltered: true,
      width: 15
    }
  ];

  formConfigSite: any = {
    name: "Site",
    title: "Sites",
    type: SiteDTO,
    inputs: [
      {
        property: "code",
        title: "code",
        type: "text",
        input: true,
        mandatory: true,
        visible: true,
        id: true,
        validityConditions: [
          {
            type: "like",
            value: /^[0-9A-Z]{2}$|^[0-9A-Z]{6}  [0-9A-Z]{2}$/
          }
        ]
      },
      {
        property: "category",
        title: "category",
        type: "string",
        selector: true,
        choices: ["client", "expediteur", "transporteur"],
        mandatory: true,
        visible: true
      },
      {
        property: "label",
        title: "label",
        type: "string",
        input: true,
        mandatory: true,
        visible: true
      },
      {
        property: "toleranceRadiusInMeter",
        title: "radius",
        type: "string",
        input: true,
        mandatory: true,
        visible: true,
        transform: "number",
        validityConditions: [
          {
            type: "greater",
            value: 100
          },
          {
            type: "lower",
            value: 3000
          }
        ]
      },
      {
        property: "longitude",
        title: "longitude",
        type: "string",
        input: true,
        mandatory: true,
        visible: true,
        transform: "number",
        validityConditions: [
          {
            type: "greater",
            value: -180
          },
          {
            type: "lower",
            value: 180
          }
        ]
      },
      {
        property: "latitude",
        title: "latitude",
        type: "string",
        input: true,
        mandatory: true,
        visible: true,
        transform: "number",
        validityConditions: [
          {
            type: "greater",
            value: -90
          },
          {
            type: "lower",
            value: 90
          }
        ]
      }
      /* {
        property: "country",
        title: "Country",
        placeHolder: "Enter a Country",
        type:"string",
        input:true,
        mandatory:true,
        visible: true,
      },
      {
        property: "city",
        title: "City",
        placeHolder: "Enter a City",
        type:"string",
        input:true,
        mandatory:true,
        visible: true,
      },
      {
        property: "postalCode",
        title: "postal Code",
        placeHolder: "Enter a Postal Code",
        type:"string",
        input:true,
        mandatory:true,
        visible: true,
      },
      {
        property: "street",
        title: "Adress",
        placeHolder: "Enter an adress",
        type:"string",
        input:true,
        mandatory:true,
        visible: true,
      }, */
    ]
  };

  siteProperties: any[] = [
    {
      label: "code",
      property: "code",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "category",
      property: "category",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "label",
      property: "label",
      canBeFiltered: true,
      width: 18
    },
    {
      label: "radius",
      property: "toleranceRadiusInMeter",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "longitude",
      property: "longitude",
      canBeFiltered: false,
      width: 5
    },
    {
      label: "latitude",
      property: "latitude",
      canBeFiltered: false,
      width: 5
    }
  ];

  formConfigMacAddresses: any = {
    name: "Mac Address",
    title: "macAddresses",
    type: MacAddressDTO,
    inputs: [
      {
        property: "address",
        title: "address",
        type: "text",
        input: true,
        mandatory: true,
        visible: true
      },
      {
        property: "longitude",
        title: "longitude",
        type: "string",
        input: true,
        mandatory: true,
        visible: true,
        transform: "number",
        validityConditions: [
          {
            type: "greater",
            value: -180
          },
          {
            type: "lower",
            value: 180
          }
        ]
      },
      {
        property: "latitude",
        title: "latitude",
        type: "string",
        input: true,
        mandatory: true,
        visible: true,
        transform: "number",
        validityConditions: [
          {
            type: "greater",
            value: -90
          },
          {
            type: "lower",
            value: 90
          }
        ]
      },
      {
        property: "siteId",
        title: "siteId",
        type: "text",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "precision",
        title: "precision",
        type: "string",
        selector: true,
        choices: ["High", "Low"],
        mandatory: true,
        visible: true
      }
    ]
  };

  macAddressesProperties: any[] = [
    {
      label: "address",
      property: "address",
      canBeFiltered: true,
      width: 18
    },
    {
      label: "longitude",
      property: "longitude",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "latitude",
      property: "latitude",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "siteId",
      property: "siteId",
      canBeFiltered: false,
      width: 10
    },
    {
      label: "precision",
      property: "precision",
      canBeFiltered: false,
      width: 5
    }
  ];

  selectedNav: string = "Sites";
  @Input() referenceLoading: boolean | undefined;

  constructor(
    private containerTypeControllerService: ContainerTypeControllerService,
    private siteControllerService: SiteControllerService,
    private containerTypeAuthSiteControllerService: ContainerTypeAuthSiteControllerService,
    private macAddressControllerService: MacAddressControllerService,
    private referenceNameControllerService: ReferenceNameControllerService,
    private _userRightsService: UserRightsService
  ) {}

  private isAdmin(event: string): boolean {
    return this._userRightsService.getUserRightLevel(event);
  }

  public getNavObject(): NavObject[] {
    return this.navObject.filter(
      (nav) =>
        nav.navName !== "macAddresses" ||
        (nav.navName === "macAddresses" && this.isAdmin("IBMAdmin"))
    );
  }

  ngOnChanges() {
    for (const nav of this.navObject) {
      nav.selectorStyle =
        nav.selectorStyle !== this.SELECTOR_STYLE_OPEN
          ? this.referenceLoading
            ? this.SELECTOR_STYLE_LOADING
            : this.SELECTOR_STYLE_CLOSE
          : this.SELECTOR_STYLE_OPEN;
    }
  }

  onNavChange(event: NavObject) {
    if (this.referenceLoading === undefined) {
      for (const nav of this.navObject) {
        nav.isOpen = false;
        nav.selectorStyle = this.SELECTOR_STYLE_CLOSE;
      }
      event.isOpen = true;
      event.selectorStyle = this.SELECTOR_STYLE_OPEN;
      this.selectedNav = event.navName;
    }
  }
}
