<div class="upload-dialog">
  <div class="modal-header">
    <p class="modal-title" [class.error]="errorPathList.length">
      {{ getTitlePath() | translate }}
    </p>
    <div class="close-icon" (click)="onCancel()">
      <svg-icon name="close"></svg-icon>
    </div>
  </div>

  <!-- Import Mode screen -->
  <ng-container *ngIf="currentScreen === 'ImportMode'">
    <app-callout [style]="'filled'" [type]="'warn'" class="import-mode-callout">
      <ng-container
        *ngFor="
          let item of 'manager.Reference.uploadDialog.importModeScreen.warningCallout.' +
            data.referenceType.toLowerCase() | translate
        "
      >
        <p [innerHTML]="item"></p>
      </ng-container>
    </app-callout>
    <mat-radio-group [(ngModel)]="importMode" class="radio-group">
      <app-radio-button
        titlePath="manager.Reference.uploadDialog.importModeScreen.replaceReferential.title"
        [subtitlePath]="
          'manager.Reference.uploadDialog.importModeScreen.replaceReferential.subtitle'
        "
        value="manager.Reference.uploadDialog.importModeScreen.replaceReferential.title"
        [selected]="
          importMode ===
          'manager.Reference.uploadDialog.importModeScreen.replaceReferential.title'
        "
        (changeElement)="data.changeUploadMode('Replace')"
      >
      </app-radio-button>
      <app-radio-button
        titlePath="manager.Reference.uploadDialog.importModeScreen.addFieldsToReferential.title"
        [subtitlePath]="
          'manager.Reference.uploadDialog.importModeScreen.addFieldsToReferential.subtitle'
        "
        value="manager.Reference.uploadDialog.importModeScreen.addFieldsToReferential.title"
        [selected]="
          importMode ===
          'manager.Reference.uploadDialog.importModeScreen.addFieldsToReferential.title'
        "
        (changeElement)="data.changeUploadMode('Add')"
      >
      </app-radio-button>
    </mat-radio-group>
    <div class="action-button-container">
      <app-button
        labelPath="manager.Reference.uploadDialog.importModeScreen.greyButton"
        color="grey"
        type="filled"
        [rounded]="true"
        (clicked)="onCancel()"
      >
      </app-button>
      <app-button
        labelPath="manager.Reference.uploadDialog.importModeScreen.blueButton"
        color="blue"
        type="filled"
        [rounded]="true"
        [disabled]="!importMode"
        (clicked)="onImportModeNext()"
      >
      </app-button>
    </div>
  </ng-container>

  <!-- File Upload screen -->
  <ng-container *ngIf="currentScreen === 'Import'">
    <app-callout *ngIf="importMode" [style]="'filled'" [type]="'info'">
      <p>{{ getImportModeDescription() | translate }}</p>
    </app-callout>
    <div class="select-file" appDragAndDrop (fileDropped)="selectFile($event)">
      <ng-container *ngIf="!file">
        <p>
          {{
            "manager.Reference.uploadDialog.fileUploadScreen.dragAndDrop"
              | translate
          }}
        </p>
        <p>
          {{ "manager.Reference.uploadDialog.fileUploadScreen.or" | translate }}
        </p>
        <input
          #uploader
          hidden
          type="file"
          (change)="selectFile($event.target.files)"
          accept=".csv"
        />
        <app-button
          labelPath="manager.Reference.uploadDialog.fileUploadScreen.uploadButtonTitle"
          [rounded]="true"
          type="outlined"
          color="blue"
          iconLeft="download"
          (clicked)="uploader.click()"
        >
        </app-button>
      </ng-container>
      <div *ngIf="file" class="selected-file">
        <p class="filename">{{ getFileName() }}</p>
        <div (click)="removeFile()">
          <svg-icon name="close" class="close-icon"></svg-icon>
        </div>
      </div>
    </div>
    <div class="action-button-container">
      <app-button
        labelPath="manager.Reference.uploadDialog.fileUploadScreen.greyButton"
        color="grey"
        type="filled"
        [rounded]="true"
        (clicked)="onFileUploadGoBack()"
      >
      </app-button>
      <app-button
        labelPath="manager.Reference.uploadDialog.fileUploadScreen.blueButton"
        color="blue"
        type="filled"
        [rounded]="true"
        [disabled]="!file || errorPathList.length"
        (clicked)="onFileUploadNext()"
      >
      </app-button>
    </div>
  </ng-container>

  <!-- Validation screen -->
  <ng-container *ngIf="currentScreen === 'Validation'">
    <app-callout [style]="'filled'" [type]="'warn'">
      <p
        *ngIf="data.previousRowNumber !== undefined"
        [innerHTML]="
          (importMode ===
          'manager.Reference.uploadDialog.importModeScreen.replaceReferential.title'
            ? 'manager.Reference.uploadDialog.validationScreen.warningCallout.replaceMode'
            : 'manager.Reference.uploadDialog.validationScreen.warningCallout.addMode'
          )
            | translate
              : {
                  sitename: getFileName(),
                  rowNumber: getRowNumber(),
                  columnNumber: getColumnNumber(),
                  previousRowNumber: data.previousRowNumber
                }
        "
      ></p>
      <p
        *ngIf="data.previousRowNumber === undefined"
        [innerHTML]="
          (importMode ===
          'manager.Reference.uploadDialog.importModeScreen.replaceReferential.title'
            ? 'manager.Reference.uploadDialog.validationScreen.warningCallout.replaceModeShorter'
            : 'manager.Reference.uploadDialog.validationScreen.warningCallout.addModeShorter'
          )
            | translate
              : {
                  sitename: getFileName(),
                  rowNumber: getRowNumber(),
                  columnNumber: getColumnNumber()
                }
        "
      ></p>
    </app-callout>
    <div class="csv-preview">
      <div class="column-names">
        <div class="column-name" *ngFor="let column of columnNames.slice(0, 4)">
          <p>
            <strong>{{ column }}</strong>
          </p>
        </div>
      </div>
      <div class="divider"></div>
      <div class="csv-rows">
        <div
          class="row-container"
          *ngFor="let row of uploadDatas.slice(0, 6); index as i"
        >
          <div class="full-row">
            <div class="index">
              <p>{{ i + 1 }}</p>
            </div>
            <div class="row-content" *ngFor="let item of row.slice(0, 4)">
              <p>{{ item }}</p>
            </div>
          </div>
          <div class="divider"></div>
        </div>
      </div>
    </div>
    <div class="action-button-container">
      <app-button
        labelPath="manager.Reference.uploadDialog.validationScreen.greyButton"
        color="grey"
        type="filled"
        [rounded]="true"
        (clicked)="onValidationGoBack()"
      >
      </app-button>
      <app-button
        labelPath="manager.Reference.uploadDialog.validationScreen.blueButton"
        color="blue"
        type="filled"
        [rounded]="true"
        [disabled]="!file || errorPathList.length"
        (clicked)="onValidationNext()"
      >
      </app-button>
    </div>
  </ng-container>

  <!-- Error screen -->
  <ng-container *ngIf="currentScreen === 'Error'">
    <app-callout [style]="'filled'" [type]="'warn'">
      <p>
        {{
          "manager.Reference.uploadDialog.fileUploadScreen.parsingError.warning"
            | translate
        }}
      </p>
      <ul *ngFor="let error of errorPathList">
        <li>{{ (error | translate) + getErrorLineList() }}</li>
      </ul>
    </app-callout>
    <div class="select-file" appDragAndDrop (fileDropped)="selectFile($event)">
      <ng-container *ngIf="!file">
        <p>
          {{
            "manager.Reference.uploadDialog.fileUploadScreen.dragAndDrop"
              | translate
          }}
        </p>
        <p>
          {{ "manager.Reference.uploadDialog.fileUploadScreen.or" | translate }}
        </p>
        <input
          #uploader
          hidden
          type="file"
          (change)="selectFile($event.target.files)"
          accept=".csv"
        />
        <app-button
          labelPath="manager.Reference.uploadDialog.fileUploadScreen.uploadButtonTitle"
          [rounded]="true"
          type="outlined"
          color="blue"
          iconLeft="download"
          (clicked)="uploader.click()"
        >
        </app-button>
      </ng-container>
      <div *ngIf="file" class="selected-file">
        <p class="filename">{{ getFileName() }}</p>
        <div (click)="removeFile()">
          <svg-icon name="close" class="close-icon"></svg-icon>
        </div>
      </div>
    </div>
    <div class="action-button-container">
      <app-button
        labelPath="manager.Reference.uploadDialog.fileUploadScreen.greyButton"
        color="grey"
        type="filled"
        [rounded]="true"
        (clicked)="onFileUploadGoBack()"
      >
      </app-button>
      <app-button
        labelPath="manager.Reference.uploadDialog.fileUploadScreen.blueButton"
        color="blue"
        type="filled"
        [rounded]="true"
        [disabled]="true"
      >
      </app-button>
    </div>
  </ng-container>
</div>
