import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UserRightsService } from "../../../services/user-rights.service";
import { StateDisplayerType } from "../state-displayer/state-displayer.component";

@Component({
  selector: "app-state-displayer-column-names",
  templateUrl: "./state-displayer-column-names.component.html",
  styleUrls: ["./state-displayer-column-names.component.scss"]
})
export class StateDisplayerColumnNamesComponent implements OnInit {
  @Output() checkboxUpdate?: EventEmitter<void> = new EventEmitter();

  @Input() type: StateDisplayerType;
  @Input() hasCheckbox: boolean;

  constructor(private _userRightsService: UserRightsService) {}

  ngOnInit(): void {}

  protected isAdmin(event: string): boolean {
    return this._userRightsService.getUserRightLevel(event);
  }
}
