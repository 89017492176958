import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnChanges
} from "@angular/core";
import { Store } from "@ngrx/store";
import { CheckedContainerType, CheckedSite } from "../../../../models/filters";
import { ContainerTypeDTO } from "../../../../models/container-type";
import { SiteDTO } from "../../../../models/site";
import { Subscription } from "rxjs";

@Component({
  selector: "app-header-analytics",
  templateUrl: "./header-analytics.component.html",
  styleUrls: ["./header-analytics.component.scss"]
})
export class HeaderAnalyticsComponent implements OnInit, OnChanges {
  @Output() departureSites: EventEmitter<SiteDTO[]> = new EventEmitter();
  @Output() arrivalSites: EventEmitter<SiteDTO[]> = new EventEmitter();
  @Output() containers: EventEmitter<ContainerTypeDTO[]> = new EventEmitter();
  @Output() sites: EventEmitter<SiteDTO[]> = new EventEmitter();
  @Output() graphic: EventEmitter<string> = new EventEmitter();
  @Output() dates: EventEmitter<Date[]> = new EventEmitter();
  @Output() days: EventEmitter<number> = new EventEmitter();

  @Input() filterContainers: CheckedContainerType[] = [];
  @Input() filterSites: string[] = [];
  @Input() filterDepartureSites: string[] = [];
  @Input() filterArrivalSites: string[] = [];
  @Input() filterDateStart: Date;
  @Input() filterDateEnd: Date;
  @Input() filterDays: number;

  @Input() sitesList: CheckedSite[];
  @Input() graphNames: string[];
  @Input() graphConfig: any;

  private subscription: Subscription = new Subscription();

  public containersList: CheckedContainerType[] = [];
  public checkedSites: CheckedSite[] = [];
  public filterDates: Date[];

  public departureSitesList: CheckedSite[];
  public checkedDepartureSites: CheckedSite[] = [];

  public arrivalSitesList: CheckedSite[];
  public checkedArrivalSites: CheckedSite[] = [];

  constructor(private store: Store<any>) {}

  public ngOnInit(): void {
    this.initStore();
  }
  public ngOnChanges(changes): void {
    if (changes.filterContainers) {
      this.filterContainers = this.filterContainers.map((container) => {
        return {
          ...container,
          key: container.code,
          keyDisplayed: `${container.code} - ${container.label}`,
          checked: !!this.filterContainers.find(
            ({ code }) => code === container.code
          )
        };
      });
    }

    if (changes.filterDateStart || changes.filterDateEnd) {
      this.filterDates = [this.filterDateStart, this.filterDateEnd];
    }

    if (changes.sitesList) {
      this.sitesList = this.sitesList.map((site) => {
        return {
          ...site,
          key: site.code,
          keyDisplayed: `${site.code} - ${site.label}`,
          checked: this.filterSites.includes(site.code)
        };
      });

      this.checkedSites = this.sitesList.filter(({ code }) =>
        this.filterSites.includes(code)
      );

      this.departureSitesList = this.sitesList.map((site) => {
        return {
          ...site,
          key: site.code,
          keyDisplayed: `${site.code} - ${site.label}`,
          checked: this.filterDepartureSites.includes(site.code)
        };
      });

      this.checkedDepartureSites = this.departureSitesList.filter(({ code }) =>
        this.filterDepartureSites.includes(code)
      );

      this.arrivalSitesList = this.sitesList.map((site) => {
        return {
          ...site,
          key: site.code,
          keyDisplayed: `${site.code} - ${site.label}`,
          checked: this.filterArrivalSites.includes(site.code)
        };
      });

      this.checkedArrivalSites = this.arrivalSitesList.filter(({ code }) =>
        this.filterArrivalSites.includes(code)
      );
    }
  }

  private initStore(): void {
    this.subscription.add(
      this.store.select("filters").subscribe((filters) => {
        if (filters?.containers) {
          this.containersList = filters.containers.map((container) => {
            return {
              ...container,
              key: container.code,
              keyDisplayed: `${container.code} - ${container.label}`,
              checked: !!this.filterContainers.find(
                ({ code }) => code === container.code
              )
            };
          });
        }
      })
    );
  }

  public manageGraphics(graphic: string): void {
    this.graphic.emit(graphic);
  }

  public manageContainers(containers): void {
    this.containers.emit(containers);
  }

  public manageSites(sites): void {
    this.sites.emit(sites.map(({ code }) => code));
  }

  public manageDepartureSites(sites): void {
    this.departureSites.emit(sites.map(({ code }) => code));
  }

  public manageArrivalSites(sites): void {
    this.arrivalSites.emit(sites.map(({ code }) => code));
  }

  public manageDates(dates) {
    this.dates.emit(dates);
  }

  public manageDays(days) {
    this.days.emit(days);
  }
}
