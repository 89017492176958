<div class="status">
  <div class="status-value" [ngClass]="statusColor.toLowerCase()">
    <ng-container *ngIf="isStatusDescriptionKey">
      {{ "stateDisplayer." + statusDescription | translate }}
    </ng-container>
    <ng-container *ngIf="!isStatusDescriptionKey">
      {{ "stateDisplayer." + getStatusDescriptionKey() | translate }}
    </ng-container>
    <ng-container *ngIf="isDayDisplayable()">
      {{ "stateDisplayer.statusDays" | translate: { days: statusDays } }}
    </ng-container>
  </div>
</div>
