import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

export const AVAILABLE_LANGUAGES: string[] = ["en", "fr"];
export const DEFAULT_LANGUAGE: string = "en";

@Component({
  selector: "app-lang-manager",
  templateUrl: "./lang-manager.component.html",
  styleUrls: ["./lang-manager.component.scss"]
})
export class LangManagerComponent implements OnInit {
  public activeLang: string;
  public langs: { code: string; name: string }[] = [
    {
      code: "en",
      name: "english"
    },
    {
      code: "fr",
      name: "français"
    }
  ];

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.langs = this.langs.filter((lang) =>
      AVAILABLE_LANGUAGES.includes(lang.code)
    );
    this.activeLang = this.translate.store.currentLang;
  }

  setLang(lang: string): void {
    if (
      !AVAILABLE_LANGUAGES.includes(lang) ||
      this.translate.store.currentLang === lang
    )
      return;

    this.translate.use(lang);
    this.activeLang = lang;
    localStorage.setItem("pref-lang", lang);
  }
}
