import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { MacAddressDTO } from "../models/mac-address";
import { SiteDTO } from "../models/site";
import { HttpClientWrapper } from "./http-client.wrapper";
import { CountDTO } from "../models/historical-state";

/**
 * MacAddressController service call api rest for MacAddress - return MacAddressDTO
 */

@Injectable()
export class MacAddressControllerService {
  constructor(
    public httpClientWrapper: HttpClientWrapper<MacAddressDTO>,
    public httpClientCount: HttpClientWrapper<CountDTO>
  ) {}

  get(
    skip: number,
    limit?: number,
    filterInput?: any
  ): Observable<MacAddressDTO[]> {
    let filter: any = {};
    if (filterInput) {
      filter.where = filterInput;
    }
    let filterFormated: string = "?filter=";
    filter.skip = skip;
    filter.limit = limit;
    filter.order = "address DESC";
    filterFormated = filterFormated + JSON.stringify(filter);
    return this.httpClientWrapper.get(
      environment.apiBaseUrl + "mac-addresses" + filterFormated
    );
  }

  getCount(filterInput?: any): Observable<CountDTO> {
    let filterFormatted: string = "?where=";
    let filter: any = {};
    if (filterInput) {
      filter = filterInput;
    }
    filterFormatted = filterFormatted + JSON.stringify(filter);
    return this.httpClientCount.getUnique(
      environment.apiBaseUrl + "mac-addresses/count" + filterFormatted
    );
  }

  getMacAddressBySiteId(site: SiteDTO): Observable<MacAddressDTO[]> {
    return this.httpClientWrapper.get(
      environment.apiBaseUrl + "/sites" + site.code + "/mac-addresses"
    );
  }

  create(macAddress: MacAddressDTO) {
    macAddress.latitude = Math.round(macAddress.latitude * 1000000) / 1000000;
    macAddress.longitude = Math.round(macAddress.longitude * 1000000) / 1000000;
    return this.httpClientWrapper.post(
      environment.apiBaseUrl + `mac-address`,
      macAddress
    );
  }

  patch(macAddress: MacAddressDTO) {
    macAddress.latitude = Math.round(macAddress.latitude * 1000000) / 1000000;
    macAddress.longitude = Math.round(macAddress.longitude * 1000000) / 1000000;
    return this.httpClientWrapper.patch(
      environment.apiBaseUrl + `mac-addresses/${macAddress.address}`,
      macAddress
    );
  }

  delete(macAddress: MacAddressDTO) {
    return this.httpClientWrapper.delete(
      environment.apiBaseUrl + `mac-addresses/${macAddress.address}`
    );
  }

  getProvisioningProperties(): any[] {
    return [
      { property: "address" },
      { property: "longitude" },
      { property: "latitude" },
      { property: "siteId" },
      { property: "precision" }
    ];
  }

  disableElements(_macAddresses: MacAddressDTO[]) {}
}
