<div
  [ngClass]="{
    'filter-number': true,
    'filter-number-active': inputActive
  }"
>
  <span (click)="inputFocus()">
    <svg-icon
      class="filter-number-icon"
      [name]="icon"
      [matTooltip]="'element.title.' + label | translate"
    ></svg-icon>
  </span>

  <input
    #inputNumberRef
    class="filter-number-input"
    type="number"
    title="Search"
    [(ngModel)]="inputData"
    [placeholder]="'element.title.' + label | translate"
    (keyup.enter)="inputEnter()"
    (blur)="inputBlur()"
  />

  <mat-icon *ngIf="inputData" (click)="clearInput()" class="filter-number-icon"
    >clear</mat-icon
  >
</div>
