import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-upload-list-element",
  templateUrl: "./upload-list-element.component.html",
  styleUrls: ["./upload-list-element.component.scss"]
})
export class UploadListElementComponent implements OnInit {
  @Input() displayObject: string[];
  @Input() id: number;

  constructor() {}

  ngOnInit() {}
}
