import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  Output
} from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from "@angular/material/dialog";
import { UploadDialogComponent } from "../../upload-dialog/upload-dialog.component";
import {
  UploadDialogData,
  expectedColumnNames
} from "../../upload-dialog/upload-dialog.typing";
import * as FileSaver from "file-saver";
import { SitePostalControllerService } from "../../../../../../app/services/site-postal-controller.service";
import { SiteDTO } from "../../../../../../app/models/site";
import { PostalAddressDTO } from "../../../../../../app/models/postal-address";
import { ContainerTypeDTO } from "../../../../../../app/models/container-type";
import { ContainerTypeAuthorizedSiteDTO } from "../../../../../../app/models/container-type-authorized-site";
import { getDateDayMonthYear } from "../../../../../../app/services/format-date";
import { MacAddressDTO } from "../../../../../../app/models/mac-address";

@Component({
  selector: "app-manager-list",
  templateUrl: "./manager-list.component.html",
  styleUrls: ["./manager-list.component.scss"]
})
export class ManagerListComponent implements OnInit, OnChanges, OnDestroy {
  @Output() selectElement: EventEmitter<any> = new EventEmitter();
  @Output() changeFilter: EventEmitter<any> = new EventEmitter();
  @Output() selectAll: EventEmitter<void> = new EventEmitter();
  @Output() changePage: EventEmitter<any> = new EventEmitter();
  @Output() loadFile: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();
  @Output() add: EventEmitter<any> = new EventEmitter();
  @Output() changeUploadMode = new EventEmitter<string>();

  @Input() displayObjects: any[];
  @Input() properties: any[];
  @Input() allCheck: boolean;
  @Input() maxItems: number;
  @Input() hasPaginatorPanel: boolean = true;
  @Input() hasModalUpload: boolean = false;
  @Input() referenceType?: "Containertype" | "Site" | "Route" | "MacAddress";
  @Input() service?: any;

  private readonly SEARCH_PATH =
    "../../../../../../assets/img/manager/search.svg";
  public readonly FILTER_PATH =
    "../../../../../../assets/img/manager/filter.svg";
  private readonly UPLOAD_PATH =
    "../../../../../../assets/img/manager/upload.svg";
  private readonly EXPAND_LESS_PATH =
    "../../../../../../assets/img/manager/expand-less.svg";
  private readonly EXPAND_MORE_PATH =
    "../../../../../../assets/img/manager/expand-more.svg";
  private readonly ADD_PATH = "../../../../../../assets/img/manager/plus.svg";

  private debounceTime: number = 400;
  private init: boolean = true;
  private downloadLoading: boolean = false;

  public searchValueUpdate = new Subject<string>();
  public filterValueUpdate = new Subject<string>();
  public arrow1: string = this.EXPAND_MORE_PATH;
  public arrow2: string = this.EXPAND_MORE_PATH;
  public previousPageAvailable: boolean = true;
  public nextPageAvailable: boolean = true;
  public hasDeleteButton: boolean = false;
  public filterClicked: boolean = false;
  public searchClicked: boolean = true;
  public hasAddButton: boolean = false;
  public loading: boolean = true;
  public search: string;
  public paginatorObject: any = {
    currentPage: 1,
    maxPage: 1,
    pageSelector: [1],
    currentItems: [1, 1],
    maxItems: 1,
    itemsPerPage: 25,
    itemsPerPageSelector: [25]
  };
  public dialogRef: MatDialogRef<UploadDialogComponent>;

  constructor(
    public dialog: MatDialog,
    private sitePostalControllerService: SitePostalControllerService
  ) {
    this.searchValueUpdate
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe((value) => {
        this.onSearchClicked(value);
      });
    this.filterValueUpdate
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe((value) => {
        this.onFilterChecked(value);
      });
  }

  ngOnInit() {
    this.hasDeleteButton = this.delete.observers.length !== 0;
    this.hasAddButton = this.add.observers.length !== 0;
  }

  ngOnChanges(changes) {
    if (this.displayObjects) {
      this.paginatorObject.currentItems = [
        (this.paginatorObject.currentPage - 1) *
          this.paginatorObject.itemsPerPage +
          1,
        (this.paginatorObject.currentPage - 1) *
          this.paginatorObject.itemsPerPage +
          this.displayObjects.length
      ];
      if (
        this.init &&
        changes.displayObjects &&
        changes.displayObjects.currentValue.length !== 0
      ) {
        this.init = false;
      }
    }
    if (changes.maxItems) {
      this.paginatorObject.maxItems = this.maxItems;
      this.paginatorObject.itemsPerPageSelector = [10, 25, 50, 100];
      this.paginatorObject.maxPage = Math.ceil(
        this.maxItems / this.paginatorObject.itemsPerPage
      );
      const pageList: number[] = [];
      for (let i = 1; i <= this.paginatorObject.maxPage; i++) {
        pageList.push(i);
      }
      this.paginatorObject.pageSelector = pageList;
    }

    this.previousPageAvailable = this.paginatorObject.currentPage === 1;
    this.nextPageAvailable =
      this.paginatorObject.currentPage === this.paginatorObject.maxPage;
    this.loading = this.init ? true : false;
  }

  ngOnDestroy() {
    for (const prop of this.properties)
      if (prop.filter) prop.filter = undefined;
    this.changeFilter.emit(undefined);
  }

  onSelect(event) {
    this.selectElement.emit(event);
  }

  changeItemsPerPage(event: number) {
    this.searchClicked = true;
    if (event !== this.paginatorObject.itemsPerPage) {
      this.paginatorObject.itemsPerPage = event;
      this.paginatorObject.maxPage = Math.ceil(
        this.maxItems / this.paginatorObject.itemsPerPage
      );
      const pageList: number[] = [];
      for (let i = 1; i <= this.paginatorObject.maxPage; i++) {
        pageList.push(i);
      }
      this.paginatorObject.pageSelector = pageList;
      this.paginatorObject.currentPage = 1;
      this.nextPageAvailable =
        this.paginatorObject.currentPage === this.paginatorObject.maxPage;
      this.previousPageAvailable = this.paginatorObject.currentPage === 1;
      this.sendChangePageEvent();
    }
  }

  changePageSelector(event: number) {
    this.searchClicked = true;
    if (event !== this.paginatorObject.currentPage) {
      this.paginatorObject.currentPage = event;
      this.sendChangePageEvent();
    }
  }

  nextPage() {
    this.searchClicked = true;
    this.paginatorObject.currentPage += 1;
    this.nextPageAvailable =
      this.paginatorObject.currentPage === this.paginatorObject.maxPage;
    this.previousPageAvailable = this.paginatorObject.currentPage === 1;
    this.sendChangePageEvent();
  }

  previousPage() {
    this.searchClicked = true;
    this.paginatorObject.currentPage -= 1;
    this.nextPageAvailable =
      this.paginatorObject.currentPage === this.paginatorObject.maxPage;
    this.previousPageAvailable = this.paginatorObject.currentPage === 1;
    this.sendChangePageEvent();
  }

  sendChangePageEvent() {
    this.loading = true;
    this.changePage.emit({
      limit: this.paginatorObject.itemsPerPage,
      page: this.paginatorObject.currentPage
    });
  }

  onSearchClicked(_event) {
    this.searchClicked = true;
    this.changeFilter.emit(
      this.search === "" || this.search === undefined || this.search === null
        ? undefined
        : { search: { regexp: `^${this.search}/i` } }
    );
  }

  onFilterClicked() {
    this.searchClicked = true;
    this.filterClicked = !this.filterClicked;
  }

  onDeleteclicked() {
    this.delete.emit();
  }

  onFilterChecked(_event) {
    const filterObject: any = {};
    this.searchClicked = true;
    for (const prop of this.properties) {
      if (prop.filter) {
        filterObject[prop.property] = { regexp: `^${prop.filter}/i` };
      }
    }
    this.paginatorObject.currentPage = 1;
    this.nextPageAvailable =
      this.paginatorObject.currentPage === this.paginatorObject.maxPage;
    this.previousPageAvailable = this.paginatorObject.currentPage === 1;
    this.changeFilter.emit(
      Object.getOwnPropertyNames(filterObject).length === 0
        ? undefined
        : filterObject
    );
  }

  selectAllDevices(event) {
    event.preventDefault();
    this.selectAll.emit();
  }

  onAddElement() {
    this.add.emit();
  }

  onLoadFileClicked(file: File) {
    this.loadFile.emit(file);
  }

  onChangeUploadMode(mode: "Replace" | "Add") {
    this.changeUploadMode.emit(mode);
  }

  public async downloadSiteFile() {
    try {
      const siteResult: SiteDTO[] = await this.service.get(0).toPromise();
      if (!siteResult) {
        console.error("An error occurred while retrieving the site file");
        return;
      }
      const postalResult: PostalAddressDTO[] =
        await this.sitePostalControllerService
          .getPolyvalentBySiteIdList(siteResult)
          .toPromise();
      if (!postalResult) {
        console.error("An error occurred while retrieving postal list");
        return;
      }
      let csvContent: string =
        expectedColumnNames[this.referenceType].join(",") + "\r\n";
      siteResult.forEach((row) => {
        const postalCoordinates = postalResult.find(
          (postal) => postal.siteId === row.code
        );
        let address = ",,,";
        if (postalCoordinates) {
          address = `"${postalCoordinates.country}","${postalCoordinates.city}","${postalCoordinates.postalCode}","${postalCoordinates.street}"`;
        }
        csvContent += `"${row.code}","${row.label}",${address}\r\n`;
        return csvContent;
      });
      const blob = new Blob([csvContent], { type: "text/csv" });
      FileSaver.saveAs(blob, `${this.referenceType}_${getDateDayMonthYear()}`);
    } catch (error) {
      console.error("Error downloading site file:", error);
    }
  }

  public async downloadContainerTypeFile() {
    try {
      const result: ContainerTypeDTO[] = await this.service.get(0).toPromise();
      if (!result) {
        console.error(
          "An error occurred while retrieving the container type file"
        );
        return;
      }
      let csvContent: string =
        expectedColumnNames[this.referenceType].join(",") + "\r\n";
      result.forEach((row) => {
        csvContent += `${row.code},${row.category},${row.label}\r\n`;
      });
      const blob = new Blob([csvContent], { type: "text/csv" });
      FileSaver.saveAs(blob, `${this.referenceType}_${getDateDayMonthYear()}`);
    } catch (error) {
      console.error("Error downloading container type file:", error);
    }
  }

  public async downloadRouteFile(): Promise<void> {
    try {
      const result: ContainerTypeAuthorizedSiteDTO[] = await this.service
        .get(0)
        .toPromise();
      if (!result) {
        console.error("An error occurred while retrieving the route file");
        return;
      }
      let csvContent: string =
        expectedColumnNames[this.referenceType].slice(1).join(",") + "\r\n";
      result.forEach((row) => {
        csvContent += `${row.containerTypeId},${row.siteId},${row.warningTransitTime}\r\n`;
      });
      const blob = new Blob([csvContent], { type: "text/csv" });
      FileSaver.saveAs(blob, `${this.referenceType}_${getDateDayMonthYear()}`);
    } catch (error) {
      console.error("Error downloading route file:", error);
    }
  }

  public async downloadMacAddressFile(): Promise<void> {
    try {
      const result: MacAddressDTO[] = await this.service.get(0).toPromise();
      if (!result) {
        console.error(
          "An error occurred while retrieving the MAC address file"
        );
        return;
      }
      let csvContent: string =
        expectedColumnNames[this.referenceType].join(",") + "\r\n";
      result.forEach((row) => {
        csvContent += `${row.address},${row.longitude},${row.latitude},${row.siteId},${row.precision}\r\n`;
      });
      const blob = new Blob([csvContent], { type: "text/csv" });
      FileSaver.saveAs(blob, `${this.referenceType}_${getDateDayMonthYear()}`);
    } catch (error) {
      console.error("Error downloading MAC address file:", error);
    }
  }

  public async downloadFile() {
    this.downloadLoading = true;
    if (this.referenceType === "Site") {
      await this.downloadSiteFile();
    } else if (this.referenceType === "Containertype") {
      await this.downloadContainerTypeFile();
    } else if (this.referenceType === "Route") {
      await this.downloadRouteFile();
    } else if (this.referenceType === "MacAddress") {
      await this.downloadMacAddressFile();
    }
    this.downloadLoading = false;
  }

  public downloadTemplate() {
    if (!this.referenceType) return;
    const csvContent: string =
      expectedColumnNames[this.referenceType].join(",") + "\r\n";
    const blob = new Blob([csvContent], { type: "text/csv" });
    FileSaver.saveAs(
      blob,
      `${this.referenceType}_${getDateDayMonthYear()}.csv`
    );
  }

  public openDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.hasBackdrop = true;

    this.dialogRef = this.dialog.open(UploadDialogComponent, {
      panelClass: "upload-dialog-container",
      autoFocus: false,
      data: {
        previousRowNumber: this.paginatorObject.maxItems,
        loadFile: this.onLoadFileClicked.bind(this),
        changeUploadMode: this.onChangeUploadMode.bind(this),
        referenceType: this.referenceType
      } as UploadDialogData
    });
  }
}
