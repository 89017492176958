import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { timeout } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { HttpClientWrapper } from "./http-client.wrapper";
import { SiteDTO } from "../models/site";
import { ContainerTypeDTO } from "../models/container-type";

export class DatesParameter {
  dateMin: number;
  dateMax: number;
}

export type AnalyticsDates = {
  start: number;
  end: number;
};

export type AnalyticsForGraphics = {
  keys: string[];
  reference: string[];
  datas: any[][][];
  labels: string[];
};

export type AnalyticsFilters = {
  containers: ContainerTypeDTO[];
  dates: AnalyticsDates;
  sites?: string[];
  departureSites?: string[];
  arrivalSites?: string[];
  days?: number;
};

export type AnalyticsBodyRequest = {
  containers: string[];
  dates?: AnalyticsDates;
  sites?: string[];
  departureSites?: string[];
  arrivalSites?: string[];
  days?: number;
};

@Injectable()
export class AnalyticsControllerService {
  private analyticsRemainingTimeSubject: Subject<number | undefined> =
    new Subject();

  constructor(
    public httpClientWrapperHist: HttpClientWrapper<AnalyticsForGraphics>,
    public httpClientWrapperConfig: HttpClientWrapper<any>
  ) {}

  public getAnalyticsConfigs(): Observable<any> {
    return this.httpClientWrapperConfig
      .getUnique(environment.apiBaseUrl + "analytics/config")
      .pipe(timeout(1000000));
  }

  public generateAnalyticsData(
    requestName: string,
    graphConfig: any,
    filters: AnalyticsFilters
  ): Observable<AnalyticsForGraphics> {
    const containers: string[] = filters.containers.map(({ code }) => code);

    const data: AnalyticsBodyRequest = {
      containers
    };

    if (graphConfig.frontConfig?.dateRange) {
      data.dates = filters.dates;
    }

    if (graphConfig.frontConfig?.sites && filters.sites?.length) {
      data.sites = filters.sites;
    }

    if (
      graphConfig.frontConfig?.departureSites &&
      filters.departureSites?.length
    ) {
      data.departureSites = filters.departureSites;
    }

    if (graphConfig.frontConfig?.arrivalSites && filters.arrivalSites?.length) {
      data.arrivalSites = filters.arrivalSites;
    }

    if (graphConfig.frontConfig?.days && filters.days) {
      data.days = filters.days;
    }

    return this.httpClientWrapperHist.postUnique(
      environment.apiBaseUrl + `analytics/data/${requestName}`,
      JSON.stringify(data)
    );
  }

  public getRemainingTime(): Subject<number | undefined> {
    return this.analyticsRemainingTimeSubject;
  }

  public setRemainingTime(remainingTime: number | undefined) {
    this.analyticsRemainingTimeSubject.next(remainingTime);
  }

  private getRoundedZoomedYmax(numberArray: number[]): number {
    let max = Math.max(...numberArray);
    let upperFence = this.UpperFence(numberArray);
    return Math.round(
      upperFence > max ? max + max / 10 : upperFence + upperFence / 10
    );
  }

  private Quantile(data: number[], q: number) {
    data = this.ArraySortNumbers(data);
    var pos = (data.length - 1) * q;
    var base = Math.floor(pos);
    var rest = pos - base;
    if (data[base + 1] !== undefined) {
      return data[base] + rest * (data[base + 1] - data[base]);
    } else {
      return data[base];
    }
  }

  private UpperFence(data: number[]) {
    let q1 = this.Quantile(data, 0.25);
    let q3 = this.Quantile(data, 0.75);
    return q3 + 1.5 * (q3 - q1);
  }

  private ArraySortNumbers(inputarray) {
    return inputarray.sort(function (a, b) {
      return a - b;
    });
  }

  private formatStorageData(data: AnalyticsForGraphics, graph: any): any {
    let hoverTexts: string[] = [];
    let siteLabel: string;
    let firstElement = true;

    for (let i = 0; i < data.reference.length; i++) {
      if (!data.datas[i] || data.datas[i][1].length < 10) continue;
      hoverTexts = [];
      if (data.labels[i]) {
        data.labels[i].length > 24
          ? (siteLabel = data.labels[i].slice(0, 21) + "[...]")
          : (siteLabel = data.labels[i]);
      } else siteLabel = "";
      for (let j = 0; j < data.datas[i][0].length; j++) {
        hoverTexts.push(
          "<b> storage time (D) : </b>" +
            Math.round(data.datas[i][1][j] * 10) / 10 +
            "<br>" +
            "<b> deviceId : </b>" +
            data.datas[i][0][j] +
            "<br>" +
            "<b> site label : </b>" +
            siteLabel +
            "<br>" +
            "<b> sample size : </b>" +
            data.datas[i][1].length
        );
      }

      graph.data.push({
        y: data.datas[i][1],
        name: `${data.reference[i]}`,
        text: hoverTexts,
        hovertemplate: "%{text}",
        boxpoints: "all",
        pointpos: 0,
        jitter: 0.6,
        line: { width: 1 },
        type: "box"
      });
      if (firstElement) {
        firstElement = false;
        graph.layout.yaxis["range"] = [
          0,
          this.getRoundedZoomedYmax(data.datas[i][1].map(Number))
        ];
      }
    }

    return graph;
  }

  formatStaticAlertData(data: AnalyticsForGraphics, graph: any): any {
    let hoverTexts: string[] = [];
    let firstElement = true;
    for (let i = 0; i < data.reference.length; i++) {
      if (!data.datas[i]) continue;
      hoverTexts = [];

      for (let j = 0; j < data.datas[i][0].length; j++) {
        hoverTexts.push(
          "<b> Site : </b>" +
            data.reference[i] +
            "<br>" +
            "<b> Time (D) : </b>" +
            data.datas[i][3][j] +
            "<br>" +
            "<b> DeviceId : </b>" +
            data.datas[i][0][j] +
            "<br>" +
            "<b> Nbr device(s) : </b>" +
            data.datas[i][1][j] +
            "<br>" +
            "<b> Time : </b>" +
            data.datas[i][2][j] +
            "<br>" +
            "<b> Site label : </b>" +
            data.labels[i]
        );
      }
      graph.data.push({
        y: data.datas[i][3],
        name: `${data.reference[i]}`,
        text: hoverTexts,
        boxpoints: "all",
        hovertemplate: "%{text}",
        pointpos: 0,
        jitter: 0.6,
        line: { width: 1 },
        type: "box",
        marker_color: "blue"
      });
      if (firstElement) {
        firstElement = false;
        graph.layout.yaxis["range"] = [
          0,
          this.getRoundedZoomedYmax(data.datas[i][3].map(Number))
        ];
      }
    }
    return graph;
  }

  private formatTransitData(data: AnalyticsForGraphics, graph: any): any {
    let hoverTexts: string[] = [];
    let site1: string;
    let site2: string;
    let routeLabel: string;
    let firstElement = true;
    for (let i = 0; i < data.reference.length; i++) {
      if (!data.datas[i] || data.datas[i][1].length < 10) continue;
      hoverTexts = [];
      if (data.labels[i]) {
        site1 = data.labels[i].split("/")[0];
        site2 = data.labels[i].split("/")[1];
        site1.length > 24
          ? (routeLabel =
              "<b> route label : </b>" + site1.slice(0, 21) + "[...]<br>  / ")
          : (routeLabel = "<b> route label : </b>" + site1 + "<br>  / ");
        site2.length > 24
          ? (routeLabel += site2.slice(0, 21) + "[...]")
          : (routeLabel += site2);
        routeLabel += "<br>";
      } else routeLabel = "";
      for (let j = 0; j < data.datas[i][0].length; j++) {
        hoverTexts.push(
          "<b> transit time (H) : </b>" +
            data.datas[i][4][j] +
            "<br>" +
            "<b> deviceId : </b>" +
            data.datas[i][0][j] +
            "<br>" +
            "<b> start : </b>" +
            data.datas[i][2][j] +
            "<br>" +
            "<b> end : </b>" +
            data.datas[i][3][j] +
            "<br>" +
            routeLabel +
            "<b> sample size : </b>" +
            data.datas[i][1].length
        );
      }
      graph.data.push({
        y: data.datas[i][4],
        name: `${data.reference[i]}`,
        text: hoverTexts,
        boxpoints: "all",
        hovertemplate: "%{text}",
        pointpos: 0,
        jitter: 0.6,
        line: { width: 1 },
        type: "box",
        marker_color: "blue"
      });
      if (firstElement) {
        firstElement = false;
        graph.layout.yaxis["range"] = [
          0,
          this.getRoundedZoomedYmax(data.datas[i][4].map(Number))
        ];
      }
    }
    return graph;
  }

  private formatStockDistributionData(
    data: AnalyticsForGraphics,
    graph: any
  ): any {
    let hoverTexts: string[] = [];
    const datas: number[] = [];
    let siteLabel: string;
    let index = 0;
    for (const item of data.datas) {
      datas.push(item[0][0]);
      if (data.labels[index]) {
        data.labels[index].length > 24
          ? (siteLabel =
              "<br><b> site label : </b>" +
              data.labels[index].slice(0, 21) +
              "[...]")
          : (siteLabel = "<br><b> site label : </b>" + data.labels[index]);
      } else siteLabel = "";
      hoverTexts.push(
        "<b> avg on site : </b>" +
          item[0][0] +
          "<br><b> cofor : </b>" +
          data.reference[index] +
          siteLabel
      );
      index++;
    }
    graph.data.push({
      labels: data.reference,
      name: data.reference,
      text: hoverTexts,
      hoverinfo: "text+percent",
      textinfo: "percent",
      values: datas,
      textposition: "inside",
      sort: false,
      type: "pie"
    });

    return graph;
  }

  private formatStockLevelData(
    data: AnalyticsForGraphics,
    graph: any,
    site?: SiteDTO
  ): any {
    let hoverTexts: string[] = [];
    let siteLabel: string;
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
      timeZoneName: "short"
    };
    for (let i = 0; i < data.reference.length; i++) {
      if (!data.datas[i]) continue;
      let v: any = true;
      let sum: number = 0;
      for (const val of data.datas[i][3]) {
        sum += val;
      }
      if (site !== undefined && data.reference[i] !== site.code)
        v = "legendonly";
      else if (
        data.reference[i] === "MOVING" ||
        sum / data.datas[i][3].length < 1
      )
        v = "legendonly";
      hoverTexts = [];
      if (data.labels[i]) {
        data.labels[i].length > 24
          ? (siteLabel =
              "<br><b> site label : </b>" +
              data.labels[i].slice(0, 21) +
              "[...]")
          : (siteLabel = "<br><b> site label : </b>" + data.labels[i]);
      } else siteLabel = "";
      for (let j = 0; j < data.datas[i][0].length; j++) {
        hoverTexts.push(
          "<b> Total equiped : </b>" +
            data.datas[i][2][j] +
            "<br>" +
            "<b> % on site : </b>" +
            Math.round(data.datas[i][3][j] * 10) / 10 +
            "%" +
            "<br>" +
            "<b> n on site : </b>" +
            data.datas[i][0][j] +
            "<br>" +
            "<b> date : </b>" +
            new Date(data.datas[i][1][j]).toLocaleDateString(
              undefined,
              options
            ) +
            siteLabel
        );
      }
      graph.data.push({
        y: data.datas[i][0],
        x: data.datas[i][1],
        name: `${data.reference[i]}`,
        text: hoverTexts,
        hoverinfo: "name+text",
        type: "scatter",
        visible: v
      });
    }
    return graph;
  }

  private formatTruckData(
    data: AnalyticsForGraphics,
    graph: any,
    _site?: SiteDTO
  ): any {
    let hoverTexts: string[] = [];
    let site1: string;
    let site2: string;
    let routeLabel: string;
    for (let i = 0; i < data.reference.length; i++) {
      if (!data.datas[i]) continue;
      hoverTexts = [];
      if (data.labels[i]) {
        site1 = data.labels[i].split("/")[0];
        site2 = data.labels[i].split("/")[1];
        site1.length > 24
          ? (routeLabel =
              "<br><b> route label : </b>" +
              site1.slice(0, 21) +
              "[...]<br>  / ")
          : (routeLabel = "<br><b> route label : </b>" + site1 + "<br>  / ");
        site2.length > 24
          ? (routeLabel += site2.slice(0, 21) + "[...]")
          : (routeLabel += site2);
      } else routeLabel = "";
      for (let j = 0; j < data.datas[i][0].length; j++) {
        hoverTexts.push(
          "<b> travel time : </b>" +
            Math.round(data.datas[i][1][j] * 10) / 10 +
            "H" +
            "<br>" +
            "<b> n departures : </b>" +
            data.datas[i][2][j] +
            routeLabel
        );
      }
      graph.data.push({
        y: data.datas[i][1],
        x: data.datas[i][0],
        name: `${data.reference[i]}`,
        text: hoverTexts,
        boxpoints: "all",
        hoverinfo: "name+text",
        pointpos: 0,
        jitter: 0.6,
        line: {
          width: 1,
          shape: "hv"
        },
        type: "scatter",
        marker_color: "blue"
      });
    }

    return graph;
  }

  public formatGraphData(
    graphName: string,
    data: AnalyticsForGraphics,
    graph: any,
    site?: SiteDTO
  ): any {
    switch (graphName) {
      case "storage":
        return this.formatStorageData(data, graph);
      case "transit":
        return this.formatTransitData(data, graph);
      case "stockDistribution":
        return this.formatStockDistributionData(data, graph);
      case "stockLevel":
        return this.formatStockLevelData(data, graph, site);
      case "truck":
        return this.formatTruckData(data, graph);
      case "staticContainers":
        return this.formatStaticAlertData(data, graph);
      case "alertContainers":
        return this.formatStaticAlertData(data, graph);

      default:
        console.log("default switch analytics");
        return;
    }
  }
}
