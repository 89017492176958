import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { PostalAddressDTO } from "../models/postal-address";
import { SiteDTO } from "../models/site";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * SitePostalController service call api rest for PostalAddress - return PostalAddressDTO
 */

@Injectable()
export class SitePostalControllerService {
  constructor(public httpClientWrapper: HttpClientWrapper<PostalAddressDTO>) {}

  getPolyvalentBySiteId(site: SiteDTO): Observable<PostalAddressDTO[]> {
    return this.httpClientWrapper.get(
      environment.apiBaseUrl + "sites/" + site.code + "/postal-addresses"
    );
  }

  getPolyvalentBySiteIdList(
    siteList: SiteDTO[]
  ): Observable<PostalAddressDTO[]> {
    return this.httpClientWrapper.post(
      environment.apiBaseUrl + "sites/postal-addresses",
      siteList
    );
  }
}
