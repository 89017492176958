<div *ngIf="type.includes('button')">
  <app-button
    (clicked)="!loading && exportXLSX()"
    labelPath="misc.export"
    type="filled"
    [rounded]="true"
    iconRight="download"
    [loading]="loading"
    [disabled]="disabled"
  ></app-button>
</div>
<img
  *ngIf="type.includes('icon') && !loading"
  [ngClass]="'exportIcon ' + type"
  src="./assets/img/search/export@2x.png"
  (click)="exportXLSX(); $event.stopPropagation()"
/>
<div [ngClass]="'exportIcon ' + type" *ngIf="type.includes('icon') && loading">
  <span class="loader"></span>
</div>
