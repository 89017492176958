import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment/moment";

@Pipe({ name: "customDate" })
export class CustomDate implements PipeTransform {
  transform(date: any): string {
    return moment(date).format("DD/MM/YYYY HH:mm");
  }
}

@Pipe({ name: "customDateComment" })
export class CustomDateComment implements PipeTransform {
  transform(date: any): string {
    return moment.unix(date).format("DD/MM/YYYY HH:mm");
  }
}

@Pipe({ name: "dateNullable" })
export class DateNullable implements PipeTransform {
  transform(date: any): string | undefined {
    return !date ? null : moment.unix(date).format("DD/MM/YYYY HH:mm");
  }
}
