import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "customRight"
})
export class CustomRight implements PipeTransform {
  transform(value: string): string {
    return value === null ? "Custom" : value;
  }
}
