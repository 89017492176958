import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { NotifierService } from "angular-notifier";
import { ExternalUserControllerService } from "../../../services/external-user-controller.service";
import { InternalUserControllerService } from "../../../services/internal-user-controller.service";
import { environment } from "../../../../environments/environment";
import { HttpClientWrapper } from "../../../services/http-client.wrapper";
import packageInfo from "../../../../../package.json";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
  private ssoReg: RegExp =
    /mpsa|opel|citroen|dsautomobiles|eurorepar|pcmarus|psa|peugeot|shiftup|vauxhall|stellantis/;
  private emailReg: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/i;

  private readonly logUrl: string = "/users/log-version";
  private readonly notifier: NotifierService;
  private subscription: Subscription = new Subscription();
  private helper = new JwtHelperService();
  private id: string;
  private jwt: string;

  public loginForm: FormGroup;
  public sso: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private _internaUserService: InternalUserControllerService,
    private _externalUserService: ExternalUserControllerService,
    private _router: Router,
    private _route: ActivatedRoute,
    private store: Store<any>,
    private httpClientVersionLog: HttpClientWrapper<Boolean>,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
    this.loginForm = this.formBuilder.group({
      email: ["", Validators.required],
      pwd: ["", Validators.required]
    });
  }

  ngOnInit(): void {
    if (!this.loginSAML()) {
      this._internaUserService.logout();
      this.store.dispatch({ type: "CLEAR" });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private internalLogin(email, pwd): void {
    this.subscription.add(
      this._internaUserService.login(email, btoa(pwd)).subscribe(
        (result) => {
          if (!result) {
            this.loginError(result);
            return;
          }
          if (result["profilId"].includes("/")) {
            result["profilId"] = result["profilId"].split("/")[0];
          }
          localStorage.setItem("profilId", result["profilId"]);
          this.logAppVersion();
          this._router.navigate(["/dashboard"]);
        },
        (err) => {
          this.loginError(err);
        }
      )
    );
  }

  private externalLogin(): void {
    try {
      this.subscription.add(
        this._externalUserService.login().subscribe((result) => {
          console.log(result);
          window.open(result.name, "_self");
        })
      );
    } catch (e) {
      console.log(e.text);
    }
  }

  public async onSubmit(): Promise<void> {
    let emailValue = this.loginForm.controls["email"].value;
    const pwdValue = this.loginForm.controls["pwd"].value;

    if (emailValue) {
      if (!this.emailReg.test(emailValue)) {
        this.loginForm.controls["email"].setValue(`${emailValue}@mail.mail`);
        emailValue = `${emailValue}@mail.mail`;
      }

      if (this.ssoReg.test(emailValue)) {
        this.sso = true;
        this.externalLogin();
      } else {
        if (this.sso) {
          this.sso = false;
        } else if (pwdValue) {
          this.internalLogin(emailValue, pwdValue);
        }
      }
    }
  }

  loginSAML(): boolean {
    this._route.queryParams.subscribe((params) => {
      this.id = params["id"];
      this.jwt = params["jwt"];
    });
    if (this.id && this.jwt) {
      this.subscription.add(
        this._internaUserService.login(this.id, this.jwt).subscribe(
          (result) => {
            if (!result) {
              this.loginError(result);
              return true;
            }
            if (result["profilId"].includes("/")) {
              result["profilId"] = result["profilId"].split("/")[0];
            }
            localStorage.setItem("profilId", result["profilId"]);
            this.logAppVersion();
            this._router.navigate(["/dashboard"]);
          },
          (err) => {
            this.loginError(err);
          }
        )
      );
    } else {
      return false;
    }
  }

  private loginError(err): void {
    const title: string = "Unable to login";
    let message: string;

    if (err && !err.error.error) {
      message = "Please try again later";
    } else {
      if (err.error.error.statusCode === 401) {
        message = err.error.error.message;
      } else {
        message = "Please try again later";
      }
    }
    this.notifier.notify("error", `${title}: ${message}`);
  }

  private logAppVersion(): void {
    try {
      console.log(this.getUsernameOrPosition(), "front", packageInfo.version);
      this.httpClientVersionLog
        .postUnique(`${this.getEnvUrl()}${this.logUrl}`, {
          username: this.getUsernameOrPosition(),
          app: "front",
          version: packageInfo.version
        })
        .subscribe((hasLoggedVersion) => {
          if (hasLoggedVersion) {
            console.log("log version success");
          } else {
            console.log("log version failed");
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  private getUsernameOrPosition(): string {
    let username = "";
    if (window.localStorage.getItem("username") == null) {
      return "No username (force refresh working ?)";
    } else {
      username = window.localStorage.getItem("username");
    }
    return username;
  }

  private getEnvUrl(): string {
    return environment.apiBaseUrl;
  }
}
