<div class="body-main" *ngIf="!isAddProfile">
  <!-- Header Bar Labels -->
  <div class="bar-header">
    <div class="bar-title">
      {{ "manager.Groups.title" | translate }}
    </div>
    <div class="bar-description">
      {{ "manager.Groups.subtitle" | translate }}
    </div>
  </div>
  <!-- Page Content Main -->
  <div class="page-content">
    <app-manager-list
      *ngIf="!isAddProfile"
      (add)="addProfile($event)"
      (selectElement)="selectProfile($event)"
      (changeFilter)="changeFilterProfile($event)"
      (changePage)="changePageProfile($event)"
      [displayObjects]="profiles"
      [maxItems]="numberOfAllProfiles"
      [properties]="profileProperties"
    ></app-manager-list>
  </div>
</div>

<div class="body-main" *ngIf="isAddProfile">
  <!-- Header Bar Buttons -->
  <div class="title-sample-header">
    <div class="page-title-left" (click)="backToList()">
      <div class="path">{{ "manager.Groups.title" | translate }}</div>
      <div class="separator">/</div>
    </div>

    <div class="page-title-right">
      <div class="delete-image" (click)="deleteProfile()">
        <svg-icon
          name="bin"
          [svgStyle]="{ fill: '#E40101', 'height.px': 16, 'width.px': 16 }"
        ></svg-icon>
      </div>
      <div class="action-buttons">
        <div class="cancel-button" (click)="backToList()">
          <div class="text">{{ "misc.cancel" | translate }}</div>
        </div>
        <div
          [class]="
            newProfileIsValid === true ? 'save-button' : 'save-button-disabled'
          "
          (click)="saveChanges()"
        >
          <div class="text">
            {{ "manager.form.validFormLabel." + updateModeLabel | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Page Content Detail -->
  <div class="page-content">
    <!-- Side Nav -->
    <div class="nav-section-list">
      <div
        class="navigator"
        (click)="onNavChange(nav)"
        *ngFor="let nav of navObject; let i = index"
      >
        <div [ngClass]="nav.selectorStyle">
          {{ "manager.navName." + nav.navName | translate }}
        </div>
      </div>
    </div>
    <!-- Nav Content -->
    <div class="main-section">
      <!-- Tab 1 -->
      <div [class]="selectedNav !== 'Groups' ? 'hidden' : 'form-holder'">
        <div class="title-main-section">
          <div class="text-box">
            {{ "manager.Groups.title" | translate }}
          </div>
          <div class="bottom-border"></div>
        </div>
        <app-manager-form
          (newObject)="changeSelectedProfile($event)"
          (formIsValid)="isFormValid($event)"
          [formConfig]="formConfig"
          [update]="updateMode"
        ></app-manager-form>
      </div>
      <!-- Tab 2 -->
      <div [class]="selectedNav !== 'authorizedCt' ? 'hidden' : 'list-holder'">
        <app-manager-list
          (selectElement)="selectAuthorizedCT($event)"
          (changeFilter)="changeFilterAuthorizedCT($event)"
          (changePage)="changePageAuthorizedCT($event)"
          [displayObjects]="authorizedCTs"
          [maxItems]="numberOfAllAuthorizedCTs"
          [properties]="authCtProperties"
        ></app-manager-list>
      </div>
    </div>
  </div>
</div>
