<div class="uploader-holder">
  <div>
    <div class="header">
      <div class="title-object">{{ "manager.uploader.title" | translate }}</div>
    </div>
  </div>

  <div class="summary-holder">
    <div
      [class]="
        uploaderType === 'reference'
          ? 'status-holder-reference'
          : 'status-holder-device'
      "
    >
      <div class="subtitle">
        {{ "misc.statusLabel" | translate | uppercase }}
      </div>
      <div class="info" *ngIf="loading">
        {{ "manager.uploader.loading" | translate }}
      </div>
      <div class="loader" *ngIf="loading">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="loader-title">
          {{ "manager.uploader.loaderTitle" | translate }}
        </div>
        <div class="loader-title" *ngIf="loadingPercentage">
          {{ loadingPercentage }} %
        </div>
      </div>
      <div class="lines-holder" *ngIf="!loading">
        <div
          class="info"
          *ngFor="let info of referenceSummary.info; let i = index"
        >
          {{ info }}
        </div>
      </div>
    </div>
    <div
      [class]="
        uploaderType === 'reference'
          ? 'status-holder-reference'
          : 'status-holder-device'
      "
    >
      <div class="subtitle">{{ "misc.errors" | translate | uppercase }}</div>
      <div *ngIf="!loading">
        <div
          class="info warning"
          *ngFor="let warning of referenceSummary.warnings; let i = index"
        >
          {{ warning }}
        </div>
      </div>
      <div *ngIf="!loading && referenceSummary.details" class="download-report">
        <app-button
          (clicked)="download()"
          labelPath="manager.uploader.dlDetails"
          iconRight="download"
          type="filled"
        ></app-button>
        <app-button
          (clicked)="back()"
          labelPath="manager.uploader.goBack"
        ></app-button>
      </div>
    </div>
  </div>
</div>
