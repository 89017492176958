<div
  [class]="
    displayObject.disabled ? 'data-table-row-disabled' : 'data-table-row'
  "
  (click)="onSelect($event)"
>
  <div
    [class]="displayObject.disabled ? 'property-disabled' : 'property'"
    *ngFor="let prop of properties"
  >
    <div
      class="cell"
      *ngIf="
        displayObject[prop.property] !== true &&
        displayObject[prop.property] !== false
      "
    >
      <div class="cell-content">
        <div class="cell-text">
          {{
            prop.property === "rightId"
              ? (displayObject[prop.property] | slashToLineBreak)
              : prop.property === "creationDate" ||
                  prop.property === "openingDate" ||
                  prop.property === "closingDate"
                ? (displayObject[prop.property] | dateNullable)
                : prop.property === "profileContainerTypeId"
                  ? (displayObject[prop.property] | customRight)
                  : displayObject[prop.property]
          }}
        </div>
      </div>
    </div>
    <!-- change image by a checkbox -->
    <mat-checkbox
      class="cell-content"
      *ngIf="
        displayObject[prop.property] === true ||
        displayObject[prop.property] === false
      "
      (click)="(displayObject['checked'])"
      [checked]="displayObject[prop.property] ? true : false"
    ></mat-checkbox>
  </div>
</div>
