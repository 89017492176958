import { IContainerTypeDTO } from "psa-tnt-models";
import { ContainerTypeAuthorizedSiteDTO } from "./container-type-authorized-site";

export class ContainerTypeDTO implements IContainerTypeDTO {
  code: string;
  category: "specific" | "polyvalent";
  label: string;
  authorizedSites?: ContainerTypeAuthorizedSiteDTO[];
  disabled?: boolean;
  checked: boolean;

  constructor(jsonObj: any) {
    this.code = jsonObj.code;
    this.category = jsonObj.category;
    this.label = jsonObj.label;
    this.authorizedSites = jsonObj.authorizedSites;
    this.checked = false;
    this.disabled = jsonObj.disabled;
  }
}
