import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { ContainerTypeDTO } from "../models/container-type";
import { DeviceContainerDTO } from "../models/device-container";
import { CountDTO } from "../models/historical-state";
import { ReferenceSummary } from "../models/reference-summary";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * ContainerTypeController service call api rest for ContainerType - return ContainerTypeDTO
 */

@Injectable()
export class ContainerTypeControllerService {
  private numberPreferredContainerTypes = new Subject<number>();

  constructor(
    public httpClientWrapper: HttpClientWrapper<ContainerTypeDTO>,
    public httpClientCount: HttpClientWrapper<CountDTO>,
    public httpClientRef: HttpClientWrapper<ReferenceSummary>
  ) {}

  getContainerTypes(): Observable<ContainerTypeDTO[]> {
    return this.httpClientWrapper.get(
      environment.apiBaseUrl + "container-types"
    );
  }

  getContainerTypeByDeviceId(
    deviceContainer: DeviceContainerDTO
  ): Observable<ContainerTypeDTO[]> {
    return this.httpClientWrapper.get(
      environment.apiBaseUrl +
        "device-containers/" +
        deviceContainer.deviceId +
        "/container-type"
    );
  }

  getPreferredContainerTypes(): Observable<ContainerTypeDTO[]> {
    return this.httpClientWrapper.get(
      environment.apiBaseUrl + "preferred-container-types"
    );
  }

  setPreferredContainerTypes(
    containerTypesIds: string[]
  ): Observable<ContainerTypeDTO[]> {
    return this.httpClientWrapper.post(
      environment.apiBaseUrl + "preferred-container-types",
      JSON.stringify(containerTypesIds)
    );
  }

  public getNumberPreferredContainerTypes(): Subject<number> {
    return this.numberPreferredContainerTypes;
  }

  public setNumberPreferredContainerTypes(
    containerTypes: Array<ContainerTypeDTO>
  ) {
    this.numberPreferredContainerTypes.next(containerTypes.length);
  }

  get(
    skip: number,
    limit?: number,
    filterInput?: any
  ): Observable<ContainerTypeDTO[]> {
    let filter: any = {};
    if (filterInput) {
      filter.where = filterInput;
    }
    let filterFormated: string = "?filter=";
    filter.skip = skip;
    filter.limit = limit;
    filter.order = "code DESC";
    filterFormated = filterFormated + JSON.stringify(filter);
    console.log(filterFormated);

    return this.httpClientWrapper.get(
      environment.apiBaseUrl + "container-types" + filterFormated
    );
  }

  getCount(filterInput?: any): Observable<CountDTO> {
    let filterFormated: string = "?where=";
    let filter: any = {};
    if (filterInput) {
      filter = filterInput;
    }
    filterFormated = filterFormated + JSON.stringify(filter);
    return this.httpClientCount.getUnique(
      environment.apiBaseUrl + "container-types/count" + filterFormated
    );
  }

  create(containerTypeCreation: ContainerTypeDTO) {
    return this.httpClientWrapper.post(
      environment.apiBaseUrl + "container-types",
      containerTypeCreation
    );
  }

  delete(containerType: ContainerTypeDTO) {
    return this.httpClientWrapper.delete(
      environment.apiBaseUrl + "container-types/" + containerType.code
    );
  }

  patch(containerTypeCreation: ContainerTypeDTO) {
    return this.httpClientWrapper.patch(
      environment.apiBaseUrl + "container-types/" + containerTypeCreation.code,
      containerTypeCreation
    );
  }

  doProvisioning(containerTypes: ContainerTypeDTO[]) {
    return this.httpClientRef.postUnique(
      environment.apiBaseUrl + "container-types/reference",
      containerTypes
    );
  }

  getProvisioningProperties(): any[] {
    return [
      { property: "code" },
      { property: "category" },
      { property: "label" }
    ];
  }

  disableElements(containerTypes: ContainerTypeDTO[]) {
    const elementTorn: ContainerTypeDTO = containerTypes.find((ct) => {
      return ct.code === "TORN";
    });
    const elementUnknown: ContainerTypeDTO = containerTypes.find((ct) => {
      return ct.code === "UNKNOWN";
    });
    if (elementTorn) elementTorn.disabled = true;
    if (elementUnknown) elementUnknown.disabled = true;
  }
}
