import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { ContainerTypeDTO } from "../models/container-type";
import { ContainerTypeAuthorizedSiteDTO } from "../models/container-type-authorized-site";
import { CountDTO } from "../models/historical-state";
import { ReferenceSummary } from "../models/reference-summary";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * ContainerTypeAuthSiteController service call api rest for ContainerTypeAuthorizedSite - return ContainerTypeAuthorizedSiteDTO
 */

@Injectable()
export class ContainerTypeAuthSiteControllerService {
  constructor(
    public httpClientWrapper: HttpClientWrapper<ContainerTypeAuthorizedSiteDTO>,
    public httpClientCount: HttpClientWrapper<CountDTO>,
    public httpClientRef: HttpClientWrapper<ReferenceSummary>
  ) {}

  getAuthorizedSiteByContainerType(
    containerType: ContainerTypeDTO
  ): Observable<ContainerTypeAuthorizedSiteDTO[]> {
    return this.httpClientWrapper.get(
      environment.apiBaseUrl +
        "container-types/" +
        containerType.code +
        "/authorized-sites"
    );
  }

  get(
    skip: number,
    limit?: number,
    filterInput?: any
  ): Observable<ContainerTypeAuthorizedSiteDTO[]> {
    let filter: any = {};
    if (filterInput) {
      filter.where = filterInput;
    }
    let filterFormated: string = "?filter=";
    filter.skip = skip;
    filter.limit = limit;
    filter.order = "containerTypeId DESC";
    filterFormated = filterFormated + JSON.stringify(filter);
    console.log(filterFormated);

    return this.httpClientWrapper.get(
      environment.apiBaseUrl +
        "container-types/authorized-sites" +
        filterFormated
    );
  }

  getCount(filterInput?: any): Observable<CountDTO> {
    let filterFormated: string = "?where=";
    let filter: any = {};
    if (filterInput) {
      filter = filterInput;
    }
    filterFormated = filterFormated + JSON.stringify(filter);
    return this.httpClientCount.getUnique(
      environment.apiBaseUrl +
        "container-types/authorized-sites/count" +
        filterFormated
    );
  }

  create(containerTypeAuthorizedSite: ContainerTypeAuthorizedSiteDTO) {
    return this.httpClientWrapper.post(
      environment.apiBaseUrl +
        `container-types/${containerTypeAuthorizedSite.containerTypeId}/authorized-sites`,
      containerTypeAuthorizedSite
    );
  }

  delete(containerTypeAuthorizedSite: ContainerTypeAuthorizedSiteDTO) {
    return this.httpClientWrapper.delete(
      environment.apiBaseUrl +
        `container-types/${containerTypeAuthorizedSite.containerTypeId}/authorized-sites/${containerTypeAuthorizedSite.siteId}`
    );
  }

  patch(containerTypeAuthorizedSite: ContainerTypeAuthorizedSiteDTO) {
    return this.httpClientWrapper.patch(
      environment.apiBaseUrl +
        `container-types/${containerTypeAuthorizedSite.containerTypeId}/authorized-sites`,
      containerTypeAuthorizedSite
    );
  }

  doProvisioning(
    containerTypeAuthorizedSites: ContainerTypeAuthorizedSiteDTO[]
  ) {
    return this.httpClientRef.postUnique(
      environment.apiBaseUrl + "container-types/authorized-sites/reference",
      containerTypeAuthorizedSites
    );
  }

  getProvisioningProperties(): any[] {
    return [
      { property: "client" },
      { property: "containerTypeId" },
      { property: "fournisseur" },
      { property: "temps1" }
    ];
  }

  disableElements(_routes: ContainerTypeAuthorizedSiteDTO[]) {}
}
