<div class="table">
  <div [class]="limitState ? 'table-toolbar-warning' : 'table-toolbar'">
    <div class="search-block">
      <!-- Toolbar icon -->
      <div class="toolbar-icon">
        <div class="icon-toolbar-icon">
          <svg-icon name="pen" [svgStyle]="{ fill: '#161616' }"></svg-icon>
        </div>
      </div>
      <!-- Add Comment hbar -->
      <div class="searchbar">
        <div class="search-input">
          <input
            class="text-search-input"
            type="text"
            [(ngModel)]="commentFromForm"
            id="comment"
            name="comment"
            (input)="commentLength(commentFromForm)"
            [placeholder]="'incident.comment.input.placeholder' | translate"
            (keyup.enter)="limitState ? undefined : createComment()"
            [maxlength]="limitComment"
          />
        </div>
        <p *ngIf="limitState" class="warning">
          {{ "incident.comment.tooLong" | translate }}
        </p>
      </div>
    </div>
    <!-- Buttons -->
    <div class="toolbar-button">
      <!-- Action button 1 -->
      <button
        class="button-primary-1"
        type="submit"
        (click)="createComment()"
        [disabled]="limitState"
      >
        <div class="button-text-1">
          <div class="button-label-1">
            {{ "incident.comment.button" | translate }}
          </div>
        </div>
        <div class="button-icon-1">
          <div class="icon-button-1">
            <svg-icon
              name="plus"
              [svgStyle]="{ fill: '#383838', 'height.px': 16, 'width.px': 16 }"
            ></svg-icon>
          </div>
        </div>
      </button>
    </div>
  </div>
  <!-- Comment List -->
  <div class="list-comment">
    <div class="data-table-rows" *ngFor="let comment of commentsList">
      <div class="data-table-row">
        <div class="top-border"></div>
        <div class="title-row">
          <div class="name-row">
            {{ comment.commentedBy }}
          </div>
          <div class="date-row">
            {{ comment.creationDate | customDateComment }}
          </div>
        </div>
        <div class="text-row">
          <p>{{ comment.comment }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- Paginator -->
  <mat-paginator
    class="list-tfoot-paginator"
    (page)="this.getPaginatorData($event)"
    [pageSizeOptions]="[10, 25, 50, 100]"
    [pageSize]="25"
    showFirstLastButtons="true"
    [length]="numberOfAllComment"
  >
  </mat-paginator>
</div>
