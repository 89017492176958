import { Injectable } from "@angular/core";

export const GREY = "rgba(129, 129, 129, o)",
  GREEN = "rgba(102, 177, 108, o)",
  ORANGE = "rgba(253, 148, 83, o)",
  BLUE = "rgba(86, 141, 196, o)",
  RED = "rgba(208, 36, 63, o)",
  YELLOW = "rgba(247, 204, 70, o)",
  LIGHTGREEN = "rgba(166,206,101, o)";

@Injectable()
export class GeoUtils {
  constructor() {}

  public createCircle(_lng, _lat, radiusInMeters) {
    // eslint-disable-next-line max-len
    // https://social.msdn.microsoft.com/Forums/onedrive/en-US/33878b72-08ca-4c3b-8291-75bd4f43b776/draw-a-circle-given-a-latlong-and-radius-in-miles?forum=vemapcontroldev
    const lonin = _lng;
    const latin = _lat;
    const locs = [];
    const lat1 = (latin * Math.PI) / 180.0;
    const lon1 = (lonin * Math.PI) / 180.0;
    const d = radiusInMeters / (3956000 * 1.6); // 3956000 -> miles * 1,6 to km
    let x, tc, lat;
    for (x = 0; x <= 360; x += 9) {
      tc = ((x / 90) * Math.PI) / 2;
      lat = Math.asin(
        Math.sin(lat1) * Math.cos(d) +
          Math.cos(lat1) * Math.sin(d) * Math.cos(tc)
      );
      lat = (180.0 * lat) / Math.PI;
      let lon;
      if (Math.cos(lat1) === 0) {
        lon = lonin; // endpoint a pole
      } else {
        lon =
          ((lon1 -
            Math.asin((Math.sin(tc) * Math.sin(d)) / Math.cos(lat1)) +
            Math.PI) %
            (2 * Math.PI)) -
          Math.PI;
      }
      lon = (180.0 * lon) / Math.PI;
      const loc = [lon, lat];
      locs.push(loc);
    }
    return locs;
  }

  public distance(lng1, lat1, lng2, lat2) {
    const p = 0.017453292519943295; // Math.PI / 180
    const c = Math.cos;
    const a =
      0.5 -
      c((lat1 - lat2) * p) / 2 +
      (c(lat2 * p) * c(lat1 * p) * (1 - c((lng1 - lng2) * p))) / 2;
    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }

  /** return a marker icon
   *  @param markerValue - color of the marker
   *  @param isQuiet - boolean quiet
   *  @param isWifi - boolean wifi / radio
   *
   * */
  public getMarkerIcon(
    markerValue: string,
    isQuiet: boolean,
    isWifi?: boolean
  ): string {
    if (isWifi) {
      if (isQuiet) return "/assets/img/pin-grey.png";
      return (
        "/assets/img/pin-" +
        (isWifi ? "wifi" : "radio") +
        "-" +
        markerValue.toLowerCase() +
        ".png"
      );
    } else {
      if (isQuiet) return "/assets/img/pin/grey.svg";
      return "/assets/img/pin/" + markerValue.toLowerCase() + ".svg";
    }
  }

  public getMarkerIconSmall(
    markerValue: string,
    isQuiet: boolean,
    isWifi: boolean
  ): string {
    if (isQuiet) {
      return "/assets/img/pin-grey-small.png";
    }
    return (
      "/assets/img/pin-" +
      (isQuiet ? "quiet" : isWifi ? "wifi" : "radio") +
      "-" +
      markerValue.toLowerCase() +
      "-small.png"
    );
  }

  public getMarkerColor(markerValue: string): any {
    return this.getMarkerColorRGBA(markerValue, 1);
  }

  public getMarkerColorRGBA(markerValue: string, opacity: number): string {
    switch (markerValue.toLowerCase()) {
      case "green":
        return GREEN.replace(/o/, "" + opacity);
      case "blue":
        return BLUE.replace(/o/, "" + opacity);
      case "red":
        return RED.replace(/o/, "" + opacity);
      case "grey":
        return GREY.replace(/o/, "" + opacity);
      case "orange":
        return ORANGE.replace(/o/, "" + opacity);
    }
  }
}
