<div class="label-content">
  <div class="label-main">
    <div class="label-main-logo">
      <img
        class="label-main-logo-img"
        src="/assets/img/battery/{{ image.toLowerCase() }}.svg"
      />
    </div>
    <div class="label-main-text">
      <div class="label-main-text-number">
        <p [ngStyle]="{ color: colorBackground }">
          {{ numberOfContainers }}
        </p>
      </div>
      <div class="label-main-text-label">
        {{ description }}
      </div>
    </div>
  </div>
</div>
