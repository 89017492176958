import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ReferenceNameDTO } from "../../../../models/reference-name";
import { ReferenceSummary } from "../../../../models/reference-summary";
import { DeviceOffContractControllerService } from "../../../../services/device-off-contract-controller.service";
import { ReferenceNameControllerService } from "../../../../services/reference-name-controller.service";
@Component({
  selector: "app-device-off-contract-manager",
  templateUrl: "./device-off-contract-manager.component.html",
  styleUrls: ["./device-off-contract-manager.component.scss"]
})
export class DeviceOffContractManagerComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  selectedObject: any = {};
  selectedRightContainerTypes: any = {};

  objects: any[];
  numberOfAllObjects: number = 0;
  requestObjectId: number = 0;
  filterObjectChanged: boolean = false;
  filterObject: any = undefined;
  objectRequestParameter: any = {
    skip: 0,
    limit: 25,
    filter: {}
  };

  uploadMode: boolean = false;
  uploaderType: string = "offContract";
  uploadDatas: string[][];
  columnNames: string[];
  fileName: string;
  rawFile: string;

  referenceSummary: ReferenceSummary;

  provisioningProperties: any[];

  deviceProperties: any[] = [
    {
      label: "token",
      property: "oldDeviceId",
      canBeFiltered: true,
      width: 10
    },
    {
      label: "transferBy",
      property: "user",
      canBeFiltered: true,
      width: 10
    },
    {
      label: "date",
      property: "date",
      canBeFiltered: false,
      width: 10
    },
    {
      label: "to",
      property: "newDeviceId",
      canBeFiltered: true,
      width: 10
    }
  ];

  constructor(
    public dialog: MatDialog,
    private deviceOffContractControllerService: DeviceOffContractControllerService,
    private referenceNameControllerService: ReferenceNameControllerService
  ) {}

  ngOnInit() {
    this.sendObjectRequest();
    this.sendObjectCount();
    this.provisioningProperties =
      this.deviceOffContractControllerService.getProvisioningProperties();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  changeFilterObject(event) {
    if (event === undefined) {
      this.filterObject = undefined;
    } else {
      if (event.search !== undefined) {
        const filter: any = { or: [] };
        for (const prop of this.deviceProperties) {
          if (prop.canBeFiltered) {
            const object: any = {};
            object[prop.property] = event.search;
            filter.or.push(object);
          }
        }
        this.filterObject = filter;
      } else {
        this.filterObject = { and: [event] };
      }
    }
    this.objectRequestParameter.skip = 0;
    this.updateObjectList(true);
  }

  changePageObject(event) {
    this.objectRequestParameter.limit = event.limit;
    this.objectRequestParameter.skip = (event.page - 1) * event.limit;
    this.updateObjectList();
  }

  changeSelectedObject(event: any) {
    if (
      this.selectedObject.profileContainerTypeId !==
      event.profileContainerTypeId
    ) {
      this.selectedObject = { ...event };
      this.selectedObject.profileContainerTypeId =
        this.selectedObject.profileContainerTypeId === "Custom"
          ? undefined
          : this.selectedObject.profileContainerTypeId;
    } else {
      this.selectedObject = { ...event };
    }
  }

  updateObjectList(isFilterChanged?: boolean) {
    this.filterObjectChanged = isFilterChanged
      ? true
      : this.filterObjectChanged;
    this.requestObjectId++;
    const id: number = this.requestObjectId;
    setTimeout(() => {
      if (this.requestObjectId === id) {
        this.sendObjectRequest();
        if (this.filterObjectChanged) {
          this.sendObjectCount();
        }
      }
    }, 1000);
  }

  sendObjectRequest() {
    this.subscription.add(
      this.deviceOffContractControllerService
        .getDevices(
          this.objectRequestParameter.skip,
          this.objectRequestParameter.limit,
          this.filterObject
        )
        .subscribe((result) => {
          this.objects = result;
          for (let i = 0; i < this.objects.length; i++) {
            const date: Date = new Date(this.objects[i].date * 1000);
            const stringDate: string = `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`;
            this.objects[i].date = stringDate;
          }
        })
    );
  }

  sendObjectCount() {
    this.subscription.add(
      this.deviceOffContractControllerService
        .getCount(this.filterObject)
        .subscribe((result) => {
          this.numberOfAllObjects = result.count;
        })
    );
  }

  doVerifying(objects: any) {
    this.subscription.add(
      this.deviceOffContractControllerService
        .doVerifying(objects.datas)
        .subscribe(
          (result) => {
            const referenceSummary: ReferenceSummary = result;
            this.referenceSummary = referenceSummary;
          },
          (err) => {
            if (err.status === 500) {
              this.referenceSummary = {
                info: [],
                warnings: [
                  "Une erreur s'est produite lors du chargement du fichier, veuiller vérifier les données uploadées"
                ]
              };
            } else if (err.status === 499) {
              this.referenceSummary = {
                info: [],
                warnings: [
                  "Time out : le chargement du csv prend du temps. Le référentiel sera bien mis à jour automatiquement dans quelques minutes."
                ]
              };
            } else {
              this.referenceSummary = {
                info: [],
                warnings: [`Une erreur est survenue : erreur ${err.status}`]
              };
            }
          }
        )
    );
  }

  doProvisioning(objects: any) {
    this.subscription.add(
      this.deviceOffContractControllerService
        .doProvisioning(objects.datas)
        .subscribe(
          (result) => {
            const referenceSummary: ReferenceSummary = result;
            this.referenceSummary = referenceSummary;
            this.formatFiles();
          },
          (err) => {
            if (err.status === 500) {
              this.referenceSummary = {
                info: [],
                warnings: [
                  "Une erreur s'est produite lors du chargement du fichier, veuiller vérifier les données uploadées"
                ]
              };
            } else if (err.status === 499) {
              this.referenceSummary = {
                info: [],
                warnings: [
                  "Time out : le chargement du csv prend du temps. Le référentiel sera bien mis à jour automatiquement dans quelques minutes."
                ]
              };
            } else {
              this.referenceSummary = {
                info: [],
                warnings: [`Une erreur est survenue : erreur ${err.status}`]
              };
            }
          }
        )
    );
  }

  saveFile(referenceName: ReferenceNameDTO, text: string) {
    this.subscription.add(
      this.referenceNameControllerService
        .createReferenceFile(referenceName, text, "Replace")
        .subscribe((_result) => {})
    );
  }

  loadFile(files: any) {
    if (files && files.length > 0) {
      let file: File = files.item(0);
      let reader: FileReader = new FileReader();
      this.uploadDatas = [];
      this.uploadMode = true;
      reader.readAsText(file);
      reader.onload = (_e) => {
        let csv: string = reader.result as string;
        this.rawFile = csv;
        this.fileName = file.name;
        this.parseCSVData(csv);
      };
    }
  }

  parseCSVData(csv: string) {
    let separator: string;
    const dataLines: string[] = csv.split("\r\n");
    const columnNamesColon: string[] = dataLines[0].split(",");
    const columnNamesSemiColon: string[] = dataLines[0].split(";");
    separator =
      columnNamesSemiColon.length >= columnNamesColon.length ? ";" : ",";
    this.columnNames = dataLines[0].split(separator);
    dataLines.shift();
    const uploadDatas: string[][] = [];
    for (const dataLine of dataLines) {
      if (dataLine.length > this.deviceProperties.length * 2) {
        const props: string[] = dataLine.split(separator);
        uploadDatas.push(props);
      }
    }
    this.uploadDatas = uploadDatas;
  }

  formatFiles() {
    let summary: string = "Infos: \n";
    for (const info of this.referenceSummary.info) {
      summary += `${info} \n`;
    }
    summary += `\nWarnings: \n`;
    for (const warning of this.referenceSummary.warnings) {
      summary += `${warning} \n`;
    }
    if (this.referenceSummary.details) {
      summary += `\Details: \n`;
      for (const warning of this.referenceSummary.details) {
        summary += `${warning} \n`;
      }
    }
    const userName: string = localStorage.getItem("username");
    const type: string = "DeviceOffContract";
    const date: number = Date.now();
    this.saveFile(
      {
        referenceNameId: `reference:${userName}:${type}:${date}`,
        uploadDate: date
      },
      this.rawFile
    );
    this.saveFile(
      {
        referenceNameId: `reference:${userName}:${type}:summary:${date}`,
        uploadDate: date
      },
      summary
    );
  }

  backOnList() {
    this.objects = undefined;
    this.updateObjectList(true);
    this.uploadMode = false;
  }
}
