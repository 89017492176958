// definitions of the actions that alter the state
export const SET_DEVICES = "Set Devices";
export const SET_CONTAINERS = "set Containers";

export function FiltersReducer(state = {}, action) {
  switch (action.type) {
    case "CLEAR":
      state = undefined;
      return;
    case SET_DEVICES:
      return { ...state, devices: action.payload };
    case SET_CONTAINERS:
      return { ...state, containers: action.payload };
    default:
      // if the action.type is unknown, return the state
      return state;
  }
}
