import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { UserDTO } from "../models/user";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * ContainerTypeController service call api rest for ContainerType - return ContainerTypeDTO
 */

@Injectable()
export class InternalUserControllerService {
  public isLogin: boolean = false;
  constructor(public httpClientWrapper: HttpClientWrapper<UserDTO>) {}

  login(username: string, password: string) {
    //TODO: change response by InternalUserDTO with --> Roles & Privileges
    return this.httpClientWrapper
      .postUnique(
        environment.apiBaseUrl + "users/login",
        { username: username, password: password },
        false
      )
      .pipe(
        map((user: any) => {
          if (!user) {
            return user as boolean;
          }
          user.password = password;

          localStorage.setItem("username", user.name);
          localStorage.setItem("password", user.jwt);
          localStorage.setItem("initials", user.name.substring(0, 2));

          this.setIsLogin(true);
          return user as UserDTO;
        })
      );
  }

  logout() {
    this.setIsLogin(false);
    // remove user from local storage to log user out
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    localStorage.removeItem("initials");
  }

  getIsLogin(): boolean {
    return this.isLogin;
  }

  setIsLogin(state: boolean) {
    this.isLogin = state;
  }
}
