import { IMacAddressDTO } from "psa-tnt-models";
import { SiteDTO } from "./site";

export class MacAddressDTO implements IMacAddressDTO {
  id: number;

  address: string;

  longitude: number;

  latitude: number;

  siteId: string | SiteDTO;

  precision: string;

  constructor(jsonObj: any) {
    this.id = jsonObj.id;
    this.address = jsonObj.address;
    this.longitude = jsonObj.longitude;
    this.latitude = jsonObj.latitude;
    this.siteId = jsonObj.siteId;
    this.precision = jsonObj.precision;
  }
}
