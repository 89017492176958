import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-label",
  templateUrl: "./label.component.html",
  styleUrls: ["./label.component.scss"]
})
export class LabelComponent implements OnInit {
  @Input() numberOfContainers: number;

  @Input() numberDetour: number;
  @Input() numberStatic: number;
  @Input() numberLate: number;

  @Input() label: string;
  @Input() status: string;
  @Input() colorBackground: string;

  constructor() {}

  ngOnInit() {}
}
