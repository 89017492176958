import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from "@angular/core";

@Component({
  selector: "app-manager-form",
  templateUrl: "./manager-form.component.html",
  styleUrls: ["./manager-form.component.scss"]
})
export class ManagerFormComponent implements OnInit, OnChanges {
  @Input() formConfig: any;
  @Input() update: boolean;
  @Output() newObject: EventEmitter<any> = new EventEmitter();
  @Output() formIsValid: EventEmitter<boolean> = new EventEmitter();

  objectCreated: any = {};

  isFormValid: boolean = false;

  hide: boolean = true;
  pipeData: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges() {
    this.mapInputs();
  }

  mapInputs() {
    for (const input of this.formConfig.inputs) {
      this.objectCreated[input.property] = input.value;
      if (input.search_selector == true) {
        input.filter = this.objectCreated[input.property];
      }
    }
    this.formIsValid.emit(this.checkWholeForm());
  }

  initForm(): void {
    for (const input of this.formConfig.inputs) {
      if (input.property === "rightId" && input.value === "Admin") {
        this.objectCreated["rightMobileApp"] = "Admin";
        this.objectCreated["rightId"] = "Admin";
        this.formConfig.inputs[4].value = "Admin";
      } else if (
        input.property === "rightMobileApp" &&
        input.value === "Admin"
      ) {
        this.objectCreated["rightId"] = "Admin";
        this.objectCreated["rightMobileApp"] = "Admin";
        this.formConfig.inputs[3].value = "Admin";
      }
    }
  }

  onValidateForm(event, input?) {
    // removal of unnecessary spaces (beginning / end of string and duplicates between words)
    if (input.property !== "currentSite") {
      this.objectCreated[this.formConfig.inputs[event].property] =
        this.objectCreated[this.formConfig.inputs[event].property].trim();
    }

    this.formConfig.inputs[event].value =
      this.objectCreated[this.formConfig.inputs[event].property];
    this.formConfig.inputs[event].valid = this.verifyInput(
      this.formConfig.inputs[event]
    );
    this.formIsValid.emit(this.checkWholeForm());
    this.newObject.emit(this.objectCreated);
  }

  checkWholeForm() {
    let isValid: boolean = true;

    for (const input of this.formConfig.inputs) {
      if (input.condition) {
        const value = this.objectCreated[input.condition.property];

        if (input.condition.property === "email") {
          input.visible =
            this.verifyEmail(value) && !input.condition.regex.test(value);
        } else {
          input.visible = !input.condition.regex.test(value);
        }

        if (!input.visible) {
          input.valid = undefined;
          input.value = undefined;
          this.objectCreated[input.property] = undefined;
        }
      }

      if (input.property === "rightId" && input.valid === false) {
        input.valid =
          this.objectCreated["rightMobileApp"] &&
          this.objectCreated["rightMobileApp"] !== "None";
      }

      if (input.mandatory && input.visible) {
        isValid = input.valid ? isValid : false;
      }
    }

    return isValid;
  }

  onFilterChange(input) {
    if (input.property === "currentSite") {
      input.filteredOptions = input.choices.filter((option) => {
        const label = option.label.toLowerCase();
        const code = option.code.toLowerCase();
        const filter = input.filter.toLowerCase();

        return label.includes(filter) || code.includes(filter);
      });
    } else {
      input.filteredOptions = input.choices.filter((option) =>
        option.toLowerCase().includes(input.filter.toLowerCase())
      );
    }
    if (input.filter === "" && input.property !== "assignedTo") {
      input.choices.unshift("");
      let i = 0;
      this.formConfig.inputs.forEach((inputTest) => {
        if (inputTest.property !== input.property) {
          i += 1;
        } else {
          this.selectProp(input.filter, input.property, i, input);
          input.choices.splice(0, 1);
        }
      });
    }
  }

  selectProp(choice, prop, index, input?) {
    if (prop === "rightId" || prop === "rightMobileApp") {
      if (prop === "rightId" && choice === "Admin") {
        this.objectCreated[prop] = choice;
        this.objectCreated["rightMobileApp"] = choice;
        this.formConfig.inputs[4].value = choice;
      } else if (prop === "rightMobileApp" && choice === "Admin") {
        this.objectCreated[prop] = choice;
        this.objectCreated["rightId"] = choice;
        this.formConfig.inputs[3].value = choice;
      } else if (
        prop === "rightId" &&
        choice !== "Admin" &&
        this.objectCreated["rightMobileApp"] === "Admin"
      ) {
        this.objectCreated[prop] = choice;
        this.objectCreated["rightMobileApp"] = "None";
        this.formConfig.inputs[4].value = "None";
      } else if (
        prop === "rightMobileApp" &&
        choice !== "Admin" &&
        this.objectCreated["rightId"] === "Admin"
      ) {
        this.objectCreated[prop] = choice;
        this.objectCreated["rightId"] = "None";
        this.formConfig.inputs[3].value = "None";
      } else {
        this.objectCreated[prop] = choice;
      }
    } else {
      this.objectCreated[prop] = choice;
    }
    if (input?.search_selector) {
      if (choice === "") {
        this.objectCreated[prop] = input.choices[0];
      } else {
        if (input.property === "currentSite") {
          this.pipeData = true;
        }
        input.filter = choice;
        this.objectCreated[prop] = choice;
      }
    }
    this.onValidateForm(index, input);
  }

  verifyInput(input: any): boolean {
    input.errorMsg = undefined;

    if (input.mandatory && this.objectCreated[input.property]) {
      if (input.type === "mail") {
        return this.verifyEmail(this.objectCreated[input.property]);
      }
      if (input.validityConditions) {
        return this.verify(input);
      }
      return true;
    }
    if (!input.mandatory) {
      return true;
    }
    return false;
  }

  changeArrow(input: any) {
    input.isOpen = input.isOpen ? false : true;
  }

  verifyEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  verify(input: any): boolean {
    let valid: boolean = true;
    let value = input.value;
    this.objectCreated[input.property] = value;
    for (const condition of input.validityConditions) {
      if (condition.type === "greater") {
        valid = Number(value) >= condition.value ? valid : false;
      }
      if (condition.type === "lower") {
        valid = Number(value) <= condition.value ? valid : false;
      }
      if (condition.type === "like") {
        valid = value.match(condition.value) ? valid : false;
      }
    }
    if (input.transform === "number") {
      this.objectCreated[input.property] = Number(value);
    }
    return valid;
  }
}
