import { Injectable } from "@angular/core";
import { TextDecoder } from "text-encoding-polyfill";
const pako = require("pako");

@Injectable()
export class DataDecompressorService {
  constructor() {}

  public async unpack(str: string) {
    let packed: any = Buffer.from(str, "base64");
    try {
      packed = new TextDecoder().decode(packed);
      packed = new Uint8Array(Buffer.from(packed, "base64"));
      packed = pako.ungzip(packed);
      packed = JSON.parse(Buffer.from(packed).toString());
      packed = this.decompressObject(packed);
    } catch (e) {
      console.log(e);
    }
    return packed;
  }

  private decompressObject(object: any[]): any {
    const props: string[] = object.splice(0, 1);
    let singleObject: any = {};
    const newObject: any[] = object.map((element) => {
      singleObject = {};
      for (let i = 0; i < element.length; i++) {
        singleObject[props[0][i]] = element[i];
      }
      return singleObject;
    });
    return newObject;
  }
}
