<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p style="white-space: pre">{{ data.text }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onYesClick()" color="warn" tabindex="2">
    {{ data.yes }}
  </button>
  <button mat-button (click)="onNoClick()" tabindex="-1">{{ data.no }}</button>
</div>
