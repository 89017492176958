<div class="content-kpi-battery">
  <div class="tile-kpi-battery">
    <div class="tile-kpi-battery-title">
      {{ "kpi.global" | translate }}
    </div>
    <div class="tile-kpi-battery-graph">
      <app-chart
        [green]="greenBattery"
        [kpiBattery]="true"
        [yellow]="yellowBattery"
        [orange]="0"
        [red]="redBattery"
        [grey]="0"
        [lightGreen]="lightGreenBattery"
      ></app-chart>
    </div>
  </div>
  <div class="tile-label-battery">
    <div class="tile-label-battery-title">
      {{ "kpi.detail" | translate }}
    </div>
    <div class="tile-label-battery-center">
      <div class="tile-label-battery-labels">
        <div class="tile-label-battery-labels-column">
          <app-battery-label
            [numberOfContainers]="greenBattery"
            [colorBackground]="greenColor"
            [description]="'misc.status.EXCELLENT' | translate"
            [image]="'green'"
          ></app-battery-label>
          <app-battery-label
            [numberOfContainers]="yellowBattery"
            [colorBackground]="yellowColor"
            [description]="'misc.status.OK' | translate"
            [image]="'yellow'"
          ></app-battery-label>
        </div>
        <div class="tile-label-battery-labels-column">
          <app-battery-label
            [numberOfContainers]="lightGreenBattery"
            [colorBackground]="lightGreenColor"
            [description]="'misc.status.GOOD' | translate"
            [image]="'light-green'"
          ></app-battery-label>
          <app-battery-label
            [numberOfContainers]="redBattery"
            [colorBackground]="redColor"
            [description]="'misc.status.ALMOSTEMPTY' | translate"
            [image]="'red'"
          ></app-battery-label>
        </div>
      </div>
    </div>
  </div>
</div>
