// definitions of the actions that alter the state
export const SET_STATUS = "Set STATUS";
export const ADD_STATUS = "Add a STATUS";
export const UPSERT_STATUS = "Upsert a STATUS";
export const REMOVE_STATUS = "Remove a STATUS";
export const UPDATE_STATUS = "Update a STATUS";
export const ACTIVE_STATUS = "Active all STATUS";
export const ACTIVE_STATUS_DETAIL = "Active all STATUS DETAIL";
export const ACTIVE_ONE_STATUS = "Active on STATUS";
export const UPDATE_STATUS_DETAIL = "Update a STATUS for DETAIL";

export function StatusReducer(state = [], action) {
  switch (action.type) {
    case "CLEAR":
      state = undefined;
      return;
    case SET_STATUS:
      let storeData = [];
      storeData.push(...action.payload);
      return storeData;
    case ADD_STATUS:
      return [...state, action.payload];
    case UPDATE_STATUS:
      return updateStatus(state, action);
    case UPDATE_STATUS_DETAIL:
      return updateStatusDetail(state, action);
    case ACTIVE_STATUS:
      return state.map((item) => {
        return { ...item, show: true };
      });
    case ACTIVE_STATUS_DETAIL:
      return state.map((item) => {
        return { ...item, showDetail: true };
      });
    case ACTIVE_ONE_STATUS:
      return state.map((item) => {
        return { ...item, show: item.marker.includes(action.payload) };
      });
    case UPSERT_STATUS:
      let exists = false;
      storeData = state.map((item) => {
        if (item.marker === action.payload.marker) {
          exists = true;
          return Object.assign({}, item, action.payload);
        } else {
          return item;
        }
      });
      if (!exists) {
        storeData.push(action.payload);
      }
      return storeData;
    case REMOVE_STATUS:
      return state.filter((status) => {
        return status.marker !== action.payload.marker;
      });
    default:
      return state;
  }
}

function updateStatus(state: any, action: any): any {
  let allStatusActive: boolean = true;
  let lastStatusActive: boolean = true;

  for (const status of state) {
    if (!status.show) {
      allStatusActive = false;
    }
    if (status.show && status.marker !== action.payload.marker) {
      lastStatusActive = false;
    }
  }
  if (allStatusActive) {
    return state.map((item) =>
      item.marker === action.payload.marker ? item : { ...item, show: false }
    );
  } else if (lastStatusActive) {
    return state.map((item) => {
      return { ...item, show: true };
    });
  } else {
    action.payload.show = !action.payload.show;
    return state.map((item) =>
      item.marker === action.payload.marker
        ? Object.assign({}, item, action.payload)
        : item
    );
  }
}

function updateStatusDetail(state: any, action: any): any {
  let allStatusActive: boolean = true;
  let lastStatusActive: boolean = true;

  for (const status of state) {
    if (!status.showDetail) {
      allStatusActive = false;
    }
    if (status.showDetail && status.marker !== action.payload.marker) {
      lastStatusActive = false;
    }
  }
  if (allStatusActive) {
    return state.map((item) =>
      item.marker === action.payload.marker
        ? item
        : { ...item, showDetail: false }
    );
  } else if (lastStatusActive) {
    return state.map((item) => {
      return { ...item, showDetail: true };
    });
  } else {
    action.payload.showDetail = !action.payload.showDetail;
    return state.map((item) =>
      item.marker === action.payload.marker
        ? Object.assign({}, item, action.payload)
        : item
    );
  }
}
