// definitions of the actions that alter the state
export const SET_TYPES = "Set TYPES";
export const ADD_TYPE = "Add a TYPE";
export const UPSERT_TYPE = "Upsert a TYPE";
export const REMOVE_TYPE = "Remove a TYPE";
export const UPDATE_TYPE = "Update a TYPE";

export function ContainerTypesReducer(state = [], action) {
  switch (action.type) {
    case "CLEAR":
      state = undefined;
      return;
    case SET_TYPES:
      let storeData = [];
      storeData.push(...action.payload);
      return storeData;
    case ADD_TYPE:
      return [...state, action.payload];
    case UPDATE_TYPE:
      return state.map((item) => {
        return item.code === action.payload.code
          ? Object.assign({}, item, action.payload)
          : item;
      });
    case UPSERT_TYPE:
      let exists = false;
      storeData = state.map((item) => {
        if (item.code === action.payload.code) {
          exists = true;
          return Object.assign({}, item, action.payload);
        } else {
          return item;
        }
      });
      if (!exists) {
        storeData.push(action.payload);
      }
      return storeData;
    case REMOVE_TYPE:
      return state.filter((TYPE) => {
        return TYPE.type !== action.payload.type;
      });
    default:
      // if the action.type is unknown, return the state
      return state;
  }
}
