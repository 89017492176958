<app-container-type-sidenav
  *ngIf="!showDetailMap && !currentState"
  (navigate)="reInit($event)"
  [hidden]="isSelectionOn"
></app-container-type-sidenav>

<app-site-sidenav
  [isVisible]="siteLock && !showDetailMap && !loading"
  [datas]="zoomSiteDatas"
  [site]="site"
></app-site-sidenav>

<app-incident-sidenav
  *ngIf="showCreateIncident"
  (closed)="closeIncidentCreation()"
  (incidentCreated)="addIncidentList($event)"
  [devices]="listIncidentDevices"
></app-incident-sidenav>

<div class="content">
  <app-map-detail
    *ngIf="showDetailMap && currentState && mapReady"
    [(map)]="map"
    [(currentState)]="currentState"
    [(display)]="showDetailMap"
    [hidden]="!showDetailMap"
    (navigate)="reInit($event)"
    (toList)="toList($event)"
    (refreshMap)="onMapChanged()"
  ></app-map-detail>

  <app-header-map
    class="map-header"
    *ngIf="!showDetailMap && !currentState"
    (containers)="changeContainers($event)"
    (devices)="changeDevices($event)"
    (lastSites)="changeLastSites($event)"
    (filter)="updateFilter($event)"
    (site)="siteRequest($event)"
    (incident)="filterIncidentChange($event)"
    (incidents)="changeIncidents($event)"
    (addIncidentDevices)="changeIncidentDevices($event)"
    (statusDays)="changeStatusDays($event)"
    (confidenceRadius)="changeConfidenceRadius($event)"
    (torn)="changeTorn($event)"
    [filterContainers]="filterContainers"
    [filterDevices]="filterDevices"
    [filterLastSites]="filterLastSites"
    [filterIncidents]="filterIncidents"
    [filterSite]="filterSiteCode"
    [filterIncident]="filterIncident"
    [filterStatusDays]="filterStatusDays"
    [filterConfidenceRadius]="filterConfidenceRadius"
    [listSites]="listSites"
    [listIncidents]="listIncidents"
    [listAddIncident]="listAddIncident"
    [listIncidentDevices]="listIncidentDevices"
    [isEmptyIncident]="isEmptyIncident"
    [isEmptyIncidentDevice]="isEmptyIncidentDevice"
    [loading]="loading"
  >
  </app-header-map>

  <div
    class="map"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
  ></div>
</div>
