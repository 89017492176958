import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatRadioChange } from "@angular/material/radio";

@Component({
  selector: "app-radio-button",
  templateUrl: "./radio-button.component.html",
  styleUrls: ["./radio-button.component.scss"]
})
export class RadioButtonComponent implements OnInit {
  @Output() changeElement: EventEmitter<MatRadioChange> = new EventEmitter();

  @Input() labelPosition: "before" | "after" = "before";
  @Input() titlePath: string;
  @Input() subtitlePath: string;
  @Input() value: string;
  @Input() selected: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
