<div class="body-main" *ngIf="!uploadMode">
  <!-- Header Bar Labels -->
  <div class="bar-header">
    <div class="bar-title">
      {{ "manager.Token.title" | translate }}
    </div>
    <div class="bar-description">
      {{ "manager.Token.subtitle" | translate }}
    </div>
  </div>
  <!-- Page Content Main -->
  <div class="page-content">
    <app-manager-list
      (changeFilter)="changeFilterObject($event)"
      (changePage)="changePageObject($event)"
      (loadFile)="loadFile($event)"
      [displayObjects]="objects"
      [maxItems]="numberOfAllObjects"
      [properties]="deviceProperties"
    ></app-manager-list>
  </div>
  <div class="uploader-holder" *ngIf="uploadMode">
    <app-file-uploader
      [datas]="uploadDatas"
      [properties]="provisioningProperties"
      [fileName]="fileName"
      [referenceSummary]="referenceSummary"
      [columnNames]="columnNames"
      [uploaderType]="uploaderType"
      (goBack)="backOnList()"
      (doVerifying)="doVerifying($event)"
      (doProvisioning)="doProvisioning($event)"
    >
    </app-file-uploader>
  </div>
</div>
