import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { ContainerTypeDTO } from "../../../models/container-type";
import { ContainerTypeControllerService } from "../../../services/container-type-controller.service";
import { UPDATE_TYPE } from "../../../stores/data_store/containers-type-store";

@Component({
  selector: "app-container-type-sidenav",
  templateUrl: "./container-type-sidenav.component.html",
  styleUrls: ["./container-type-sidenav.component.scss"]
})
export class ContainerTypeSidenavComponent implements OnInit, OnDestroy {
  @ViewChild("sidenav", { static: true }) sidenav: MatSidenav;

  private subscription: Subscription = new Subscription();

  categoryContainerTypes: ContainerTypeDTO[];
  containerTypes: ContainerTypeDTO[];
  filteredContainerTypeList: ContainerTypeDTO[];

  allContainerTypeAreChecked: boolean;
  allContainerTypeAreUnChecked: boolean;
  allContainertypeIsIndeterminate: boolean;
  private index: number = 20;

  private readonly STATE_BATCH: number = 12;
  public containerType: string;

  @Input() selectedIndex: any;
  @Output() navigate = new EventEmitter<boolean>();

  filterCategory: any = {
    all: true,
    specific: false,
    polyvalent: false,
    current: "all"
  };

  displayedColumns: string[] = ["checked", "type"];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<ContainerTypeDTO>(true, []);

  constructor(
    private containerTypeControllerService: ContainerTypeControllerService,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.filterCategory.all = true;
    this.subscription.add(
      this.store
        .select("ctTypes")
        .subscribe((ctTypesData: ContainerTypeDTO[]) => {
          this.containerTypes = ctTypesData;
          this.filterCategoryUpdate(this.filterCategory.current);
          if (this.filteredContainerTypeList) {
            this.updataDataSource(this.filteredContainerTypeList);
          } else {
            this.updataDataSource(this.categoryContainerTypes);
          }
        })
    );

    this.subscription.add(
      this.containerTypeControllerService
        .getNumberPreferredContainerTypes()
        .subscribe((nb) => {
          if (!nb) this.sidenav.open();
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseSidenav(sidenav: MatSidenav, listeSidebar: HTMLDivElement) {
    sidenav.close();
    listeSidebar.scrollTop = 0;
    this.index = 20;
    this.filterCategoryUpdate(this.filterCategory.current);
    if (this.filteredContainerTypeList) {
      this.updataDataSource(this.filteredContainerTypeList);
    } else {
      this.updataDataSource(this.categoryContainerTypes);
    }
  }

  updataDataSource(containerTypes) {
    this.dataSource.data = containerTypes
      .sort((a) => {
        if (a.code === "TORN" || a.code === "UNKNOWN") return -1;
        else return 0;
      })
      .slice(0, this.index);
  }

  manageAllContainerTypesCheckbox() {
    this.allContainerTypeAreChecked = this.checkAllContainerTypesSelected();
    this.allContainerTypeAreUnChecked =
      this.checkAllContainerTypesNonSelected();
    if (
      !this.allContainerTypeAreChecked &&
      !this.allContainerTypeAreUnChecked
    ) {
      this.allContainertypeIsIndeterminate = true;
    } else {
      this.allContainertypeIsIndeterminate = false;
    }
  }

  checkAllContainer() {
    for (const cType of this.filteredContainerTypeList) {
      cType.checked = true;
    }
    this.refreshFilters();
  }

  uncheckAllContainer() {
    for (const cType of this.filteredContainerTypeList) {
      cType.checked = false;
    }
    this.refreshFilters();
  }

  refreshFilters() {
    const checkedCtTypesCodes: string[] = this.categoryContainerTypes
      .filter((ct) => ct.checked)
      .map((ct) => ct.code);
    if (checkedCtTypesCodes.length !== 0) {
      this.store.dispatch({
        type: UPDATE_TYPE,
        payload: this.filteredContainerTypeList
      });
      this.subscription.add(
        this.containerTypeControllerService
          .setPreferredContainerTypes(checkedCtTypesCodes)
          .subscribe((_res) => {})
      );
    }
    this.manageAllContainerTypesCheckbox();
  }

  manageAllContainerTypes(event) {
    if (this.checkAllContainerTypesNonSelected()) {
      for (const cType of this.filteredContainerTypeList) {
        cType.checked = true;
      }
    } else if (this.checkAllContainerTypesSelected()) {
      for (const cType of this.filteredContainerTypeList) {
        cType.checked = false;
      }
    } else {
      for (const cType of this.filteredContainerTypeList) {
        cType.checked = event.checked;
      }
    }
    if (this.allContainertypeIsIndeterminate) {
      this.uncheckAllContainer();
    } else {
      this.refreshFilters();
    }
  }

  checkAllContainerTypesSelected() {
    for (const cType of this.filteredContainerTypeList) {
      if (!cType.checked) return false;
    }
    return true;
  }

  checkAllContainerTypesNonSelected() {
    for (const cType of this.filteredContainerTypeList) {
      if (cType.checked) return false;
    }
    return true;
  }

  filterCategoryUpdate(value: string) {
    this.filterCategory.polyvalent = false;
    this.filterCategory.specific = false;
    this.filterCategory.all = false;
    this.filterCategory.current = value;
    if (value === "all") {
      this.filterCategory.all = true;
      this.categoryContainerTypes = this.containerTypes;
    } else {
      this.filterCategory[value] = true;
      this.categoryContainerTypes = this.containerTypes.filter((container) => {
        return !!this.containerTypes.find((_ct) => {
          return container.category === value;
        });
      });
    }
    this.filteredContainerTypeList = this.categoryContainerTypes.filter(
      (container) => {
        return !!this.categoryContainerTypes.find((_ct) => {
          return (
            !this.containerType || container.code.includes(this.containerType)
          );
        });
      }
    );
    this.updataDataSource(this.filteredContainerTypeList);

    this.manageAllContainerTypesCheckbox();
  }

  cTypeFilterUpdated(event, cType: ContainerTypeDTO) {
    cType.checked = event.checked;
    const checkedCtTypesCodes: string[] = this.containerTypes
      .filter((ct) => ct.checked)
      .map((ct) => ct.code);
    if (checkedCtTypesCodes.length !== 0) {
      this.store.dispatch({ type: UPDATE_TYPE, payload: cType });
      this.subscription.add(
        this.containerTypeControllerService
          .setPreferredContainerTypes(checkedCtTypesCodes)
          .subscribe((_res) => {})
      );
    }
    this.manageAllContainerTypesCheckbox();
  }

  containerTypeChanged() {
    this.filterCategoryUpdate(this.filterCategory.current);
  }

  loadMore = () => {
    let newIndex: number = this.index;
    if (newIndex !== this.containerTypes.length) {
      newIndex += this.STATE_BATCH;
      if (newIndex > this.containerTypes.length) {
        newIndex = this.containerTypes.length;
      }
      this.index = newIndex;
      this.filterCategoryUpdate(this.filterCategory.current);
      if (this.filteredContainerTypeList) {
        this.updataDataSource(this.filteredContainerTypeList);
      } else {
        this.updataDataSource(this.categoryContainerTypes);
      }
    }
  };
}
