import { IProfileContainerTypeDTO } from "psa-tnt-models";
import { RightContainerTypesDTO } from "./right-container-types";

export class ProfileCreationDTO {
  profile: ProfileContainerTypeDTO;
  rightContainerTypes: RightContainerTypesDTO[];

  constructor(jsonObj: any) {
    this.profile = jsonObj.profile;
    this.rightContainerTypes = jsonObj.rightContainerTypes;
  }
}

export class ProfileContainerTypeDTO implements IProfileContainerTypeDTO {
  profileContainerTypeId: string;
  userId: string;
  disabled?: boolean;

  constructor(jsonObj: any) {
    this.profileContainerTypeId = jsonObj.profileContainerTypeId;
    this.userId = jsonObj.userId;
    this.disabled = jsonObj.disabled;
  }
}
