import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { CountDTO } from "../models/historical-state";
import { ReferenceSummary } from "../models/reference-summary";
import { SiteDTO } from "../models/site";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * SiteController service call api rest for Site - return SiteDTO
 */

@Injectable()
export class SiteControllerService {
  constructor(
    public httpClientWrapper: HttpClientWrapper<SiteDTO>,
    public httpClientCount: HttpClientWrapper<CountDTO>,
    public httpClientRef: HttpClientWrapper<ReferenceSummary>
  ) {}

  getSites(): Observable<SiteDTO[]> {
    return this.httpClientWrapper.get(environment.apiBaseUrl + "sites");
  }

  get(skip: number, limit?: number, filterInput?: any): Observable<SiteDTO[]> {
    let filter: any = {};
    if (filterInput) {
      filter.where = filterInput;
    }
    let filterFormated: string = "?filter=";
    filter.skip = skip;
    filter.limit = limit;
    filter.order = "code DESC";
    filterFormated = filterFormated + JSON.stringify(filter);
    return this.httpClientWrapper.get(
      environment.apiBaseUrl + "sites" + filterFormated
    );
  }

  getCount(filterInput?: any): Observable<CountDTO> {
    let filterFormated: string = "?where=";
    let filter: any = {};
    if (filterInput) {
      filter = filterInput;
    }
    filterFormated = filterFormated + JSON.stringify(filter);
    return this.httpClientCount.getUnique(
      environment.apiBaseUrl + "sites/count" + filterFormated
    );
  }

  create(site: SiteDTO) {
    site.latitude = Math.round(site.latitude * 1000000) / 1000000;
    site.longitude = Math.round(site.longitude * 1000000) / 1000000;
    return this.httpClientWrapper.post(environment.apiBaseUrl + `sites`, site);
  }

  delete(site: SiteDTO) {
    return this.httpClientWrapper.delete(
      environment.apiBaseUrl + `sites/${site.code}`
    );
  }

  patch(site: SiteDTO) {
    site.latitude = Math.round(site.latitude * 1000000) / 1000000;
    site.longitude = Math.round(site.longitude * 1000000) / 1000000;
    return this.httpClientWrapper.patch(
      environment.apiBaseUrl + `sites/${site.code}`,
      site
    );
  }

  doProvisioning(sites: SiteDTO[]) {
    return this.httpClientRef.postUnique(
      environment.apiBaseUrl + "sites/reference",
      sites
    );
  }

  getProvisioningProperties(): any[] {
    return [
      { property: "code" },
      { property: "label" },
      { property: "country" },
      { property: "city" },
      { property: "postalCode" },
      { property: "address1" }
    ];
  }

  disableElements(_sites: SiteDTO[]) {}
}
