import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { IncidentControllerService } from "../../../../services/incident-controller.service";
import { MatDialog } from "@angular/material/dialog";
import { SimpleDialogComponent } from "../../../../components/dialog/simple-dialog.component";
import {
  IncidentAssociatedDeviceDTO,
  IncidentDTO
} from "../../../../models/incident";

@Component({
  selector: "app-device",
  templateUrl: "./device.component.html",
  styleUrls: ["./device.component.scss"]
})
export class DeviceComponent implements OnInit {
  constructor(
    public incidentControllerService: IncidentControllerService,
    public dialog: MatDialog
  ) {}

  @Input() incident: IncidentDTO;

  @Output() selectedDevices: Array<IncidentAssociatedDeviceDTO> = [];
  @Output() deletedAllDevices: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private subscription: Subscription = new Subscription();
  filterDevice: any = undefined;
  deviceList: Array<IncidentAssociatedDeviceDTO>;
  numberOfAllDevice: number = 0;
  selectAll: boolean = false;

  ngOnInit(): void {
    this.getIncidentDevices(this.incident.id);
  }

  deviceProperties: any[] = [
    {
      property: "checked",
      canBeFiltered: false,
      width: 5
    },
    {
      label: "deviceId",
      property: "deviceId",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "containerType",
      property: "containerType",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "lastAuthorizedSite",
      property: "lastAuthorizedSite",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "openingDate",
      property: "openingDate",
      canBeFiltered: true,
      width: 5
    },
    {
      label: "closingDate",
      property: "closingDate",
      canBeFiltered: true,
      width: 5
    }
  ];

  deviceRequestParameter: any = {
    skip: 0,
    limit: 25,
    filter: {}
  };

  getIncidentDevices(
    incidentId?: typeof IncidentAssociatedDeviceDTO.prototype.incidentId
  ) {
    this.subscription.add(
      this.incidentControllerService
        .getDevices(
          incidentId,
          this.deviceRequestParameter.skip,
          this.deviceRequestParameter.limit,
          this.filterDevice
        )
        .subscribe((devices) => {
          this.deviceList = devices.map((device) => {
            return {
              ...device,
              checked: false
            };
          });
          this.countDevices();
        })
    );
  }

  changePage(value: boolean) {
    this.deletedAllDevices.emit(value);
    console.log(value);
  }

  deleteDevices() {
    if (!this.selectedDevices?.length) return;

    delete this.incident.checked;
    const incidentId = this.selectedDevices[0].incidentId;
    this.subscription.add(
      this.incidentControllerService
        .getCountDevices(this.incident.id)
        .subscribe((res) => {
          if (res.count === this.selectedDevices.length) {
            const dialogRef = this.dialog.open(SimpleDialogComponent, {
              data: {
                title: "Delete all the devices",
                text: "Delete all the devices will deletes the all incident",
                yes: "Yes",
                no: "No"
              }
            });

            this.subscription.add(
              dialogRef.afterClosed().subscribe((isConfirmed) => {
                if (isConfirmed) {
                  this.subscription.add(
                    this.incidentControllerService
                      .deleteDevices(this.selectedDevices)
                      .subscribe(() => {
                        this.subscription.add(
                          this.incidentControllerService
                            .deleteIncident(this.incident.id)
                            .subscribe((_res) => {
                              this.changePage(isConfirmed);
                            })
                        );
                        this.getIncidentDevices(this.incident.id);
                      })
                  );
                } else {
                  this.deviceList.forEach((device) => {
                    device.checked = false;
                  });
                  this.selectedDevices = [];
                }
              })
            );
          } else {
            this.subscription.add(
              this.incidentControllerService
                .deleteDevices(this.selectedDevices)
                .subscribe((_res) => {
                  this.getIncidentDevices(incidentId);
                  this.incidentControllerService
                    .getCountDevices(this.incident.id)
                    .subscribe((res) => {
                      this.incident.tantQuantity = res.count;
                      this.subscription.add(
                        this.incidentControllerService
                          .patchIncident(this.incident)
                          .subscribe()
                      );
                    });
                })
            );
          }
        })
    );
    this.selectAll = false;
  }

  countDevices() {
    this.subscription.add(
      this.incidentControllerService
        .getCountDevices(this.incident.id)
        .subscribe((res) => {
          this.numberOfAllDevice = res.count;
        })
    );
  }

  isOpen(event: IncidentAssociatedDeviceDTO) {
    event.checked = !event.checked;
    if (event.checked === true) {
      this.selectedDevices.push(event);
    } else {
      let i: number = 0;
      this.selectedDevices.forEach((device) => {
        if (device.id === event.id) {
          this.selectedDevices.splice(i, 1);
        }
        i++;
        if (this.selectAll == true) {
          this.selectAll = !this.selectAll;
        }
      });
    }
    if (this.selectedDevices.length === this.deviceList.length) {
      this.selectAll = true;
    }
  }

  selectAllDevices() {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.deviceList.forEach((device) => {
        device.checked = true;
        this.selectedDevices.push(device);
      });
    } else {
      this.deviceList.forEach((device) => {
        device.checked = false;
      });
      this.selectedDevices = [];
    }
  }

  changePageDevice(event) {
    this.deviceRequestParameter.limit = event.limit;
    this.deviceRequestParameter.skip = (event.page - 1) * event.limit;
    this.getIncidentDevices(this.incident.id);
  }

  changeFilterDevice(event) {
    if (event === undefined) {
      this.filterDevice = undefined;
    } else {
      if (event.search !== undefined) {
        const filter: any = {
          and: [{ incidentId: this.incident.id }, { or: [] }]
        };
        for (const prop of this.deviceProperties) {
          if (prop.canBeFiltered) {
            const object: any = {};
            object[prop.property] = event.search;
            filter.and[1].or.push(object);
          }
        }
        this.filterDevice = filter;
      } else {
        this.filterDevice = { and: [event] };
      }
    }
    this.deviceRequestParameter.skip = 0;
    this.getIncidentDevices(this.incident.id);
  }
}
