<div class="headerContainer">
  <div class="headerFilter">
    <app-button-list
      [label]="'containerType'"
      [type]="'checkbox'"
      [icon]="'container'"
      [data]="containersList"
      [checkedData]="filterContainers"
      (manageList)="manageContainers($event)"
      [isEmpty]="!containersList.length"
    ></app-button-list>
  </div>
  <div class="battery-holder" *ngIf="batteryVisible">
    <app-toggle-checkbox (toggle)="tglBattery()"></app-toggle-checkbox>
    <img class="battery" src="/assets/img/battery/green.svg" />
  </div>
</div>
