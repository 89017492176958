import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable()
export class XlsxExporter {
  constructor() {}

  public exportAsExcelFile(sheets: any, name: string): void {
    const workbook: XLSX.WorkBook = { Sheets: {}, SheetNames: [] };
    Object.keys(sheets).forEach((sheetName: string) => {
      const jsonSheet = sheets[sheetName];
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonSheet);
      workbook.Sheets[sheetName] = worksheet;
      workbook.SheetNames.push(sheetName);
    });
    //don't touch parameter 'type'
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array"
    });
    this.saveAsExcelFile(excelBuffer, name);
  }

  private saveAsExcelFile(buffer: any, baseName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });

    FileSaver.saveAs(
      data,
      baseName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  public setHyperlink(cell: any, link: string, tooltip: string) {
    if (!tooltip) {
      tooltip = link;
    }
    XLSX.utils.cell_set_hyperlink(cell, link, tooltip);
  }
}
