<div class="lang-manager">
  <button class="lang-manager-button" [matMenuTriggerFor]="langMenu">
    <mat-icon class="lang-icon">language</mat-icon>
    <p class="active-lang-label">{{ activeLang | uppercase }}</p>
    <mat-icon class="lang-icon">arrow_drop_down</mat-icon>
  </button>
  <mat-menu class="lang-list" #langMenu="matMenu">
    <button
      mat-menu-item
      *ngFor="let lang of langs"
      (click)="setLang(lang.code)"
    >
      <span class="lang-item" [ngClass]="{ active: activeLang === lang.code }">
        {{ lang.name }}
      </span>
    </button>
  </mat-menu>
</div>
