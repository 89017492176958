import { IContainerTypeAuthorizedSiteDTO } from "psa-tnt-models";
import { ContainerTypeDTO } from "./container-type";
import { SiteDTO } from "./site";

export class ContainerTypeAuthorizedSiteDTO
  implements IContainerTypeAuthorizedSiteDTO
{
  id?: number;
  containerTypeId: ContainerTypeDTO;
  siteId: SiteDTO;
  warningTransitTime: number;

  constructor(jsonObj: any) {
    this.id = jsonObj.id;
    this.containerTypeId = jsonObj.containerType;
    this.siteId = jsonObj.site;
  }
}
