import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-list-element",
  templateUrl: "./list-element.component.html",
  styleUrls: ["./list-element.component.scss"]
})
export class ListElementComponent {
  @Output() selectElement: EventEmitter<any> = new EventEmitter();
  @Output() selectDevice: EventEmitter<any> = new EventEmitter();

  @Input() displayObject: any;
  @Input() properties: any[];

  readonly BOOLEAN_TRUE: string =
    "../../../../../../assets/img/manager/boolean-true.svg";
  readonly BOOLEAN_FALSE: string =
    "../../../../../../assets/img/manager/boolean-false.svg";

  constructor() {}

  onSelect(event) {
    event.preventDefault();
    if (!this.displayObject.disabled) {
      this.selectElement.emit(this.displayObject);
    }
  }
}
