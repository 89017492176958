import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-button-list-single-select",
  templateUrl: "./button-list-single-select.component.html",
  styleUrls: ["./button-list-single-select.component.scss"]
})
export class ButtonListSingleSelectComponent implements OnInit {
  @Input() icon: string;
  @Input() placeholderPath?: string;
  @Input() selectedElementPath?: string;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  public onClicked(): void {
    this.clicked.emit();
  }

  constructor() {}

  ngOnInit(): void {}
}
