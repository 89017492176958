<div class="manager-manager">
  <div class="manager-sidenav">
    <div class="nav-holder">
      <div
        [ngClass]="navStyle"
        (click)="onNavChange(nav)"
        *ngFor="let nav of navObject; let i = index"
      >
        <div class="nav-selector" [ngClass]="nav.selectorStyle"></div>
        <img class="nav-image" [src]="nav.navLogo" />
        <div class="nav-name">
          {{ "manager." + nav.navName + ".label" | translate }}
        </div>
      </div>
      <div
        [ngClass]="navStyle"
        (click)="onNavChange(tokenNavObject)"
        *ngIf="isIBMAdmin()"
      >
        <div
          class="nav-selector"
          [ngClass]="tokenNavObject.selectorStyle"
        ></div>
        <img class="nav-image" [src]="tokenNavObject.navLogo" />
        <div class="nav-name">
          {{ "manager." + tokenNavObject.navName + ".label" | translate }}
        </div>
      </div>
    </div>
  </div>
  <app-user-manager *ngIf="selectedNav === 'Users'"></app-user-manager>
  <app-profile-manager *ngIf="selectedNav === 'Groups'"></app-profile-manager>
  <app-reference-manager
    *ngIf="selectedNav === 'Reference'"
    [referenceLoading]="referenceLoading"
  ></app-reference-manager>
  <app-device-off-contract-manager
    *ngIf="selectedNav === 'Token' && isIBMAdmin()"
  ></app-device-off-contract-manager>
</div>
