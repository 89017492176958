import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-position-controller",
  templateUrl: "./position-controller.component.html",
  styleUrls: ["./position-controller.component.scss"]
})
export class PositionControllerComponent implements OnInit {
  @Input() isAtHistoryEnd: boolean;
  @Input() isAtHistoryBeginning: boolean;

  @Output() previousPos: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextPos: EventEmitter<void> = new EventEmitter<void>();
  @Output() firstPos: EventEmitter<void> = new EventEmitter<void>();
  @Output() lastPos: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
