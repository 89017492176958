import { Injectable } from "@angular/core";
import { Observable, Subscriber } from "rxjs";
import { environment } from "../../environments/environment";
import { CurrentStateDTO } from "../models/current-state";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * MacAddressController service call api rest for MacAddress - return MacAddressDTO
 */

@Injectable()
export class IncidentHistoricalControllerService {
  public observer: Subscriber<number>;
  public loadingObservable: Observable<number | undefined> = new Observable(
    (observer) => {
      this.observer = observer;
      observer.next(undefined);
    }
  );
  private readonly INCIDENT_CATEGORY: string[] = [
    "Delivery error",
    "Carrier stop",
    "Project request",
    "Temporary flow",
    "Under repair",
    "T&T reference issue",
    "T&T wrong tagging",
    "Other"
  ];

  private selectedCategory: string;

  constructor(public httpClientWrapper: HttpClientWrapper<CurrentStateDTO>) {}

  public updateAlertEvaluater(
    currentState: CurrentStateDTO
  ): Observable<CurrentStateDTO> {
    return this.httpClientWrapper.postUnique(
      environment.apiBaseUrl + "incident",
      JSON.stringify(currentState)
    );
  }

  public getCategory(): string[] {
    return this.INCIDENT_CATEGORY;
  }

  public setSelectedCategory(category: string) {
    this.selectedCategory = category;
  }

  public getSelectedCategory(): string {
    return this.selectedCategory;
  }

  public getIsLoading(): Observable<number | undefined> {
    return this.loadingObservable;
  }

  public setIsLoading(loading: number | undefined) {
    this.observer.next(loading);
  }
}
