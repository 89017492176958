import { DeviceContainerStateControllerService } from "../../../../services/device-container-state-controller.service";
import { UserRightsService } from "../../../../services/user-rights.service";
import { GeoUtils } from "../../../../services/geo-utils";
import { userAccess } from "../../../../services/user-rights.service";

import { UPDATE_STATUS_DETAIL } from "../../../../stores/data_store/status-store";

import { CurrentStateFilterDTO } from "../../../../models/current-state-filter";
import { HistoricalStateDTO } from "../../../../models/historical-state";
import { CurrentStateDTO } from "../../../../models/current-state";

import { DETAIL } from "../../../utils/export-excel/export-excel.component";

import { animate, style, transition, trigger } from "@angular/animations";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import * as moment from "moment/moment";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild
} from "@angular/core";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("100ms", style({ opacity: 1 }))
      ])
    ])
  ]
})
export class DetailComponent implements OnInit, OnDestroy {
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() navigate = new EventEmitter<any>();

  @Input() currentState: CurrentStateDTO;
  @Input() display: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public listHistoSorted: Array<HistoricalStateDTO>;
  public totalFiteredHistoricalStates: number;
  public exportData: CurrentStateFilterDTO;
  public statusList: Array<any> = [];
  public exportType: string = DETAIL;
  public loading: boolean = true;
  public pageSize: number = 30;
  public inputData;

  private subscription: Subscription = new Subscription();
  private listHisto: Array<HistoricalStateDTO>;
  private initialisation = false;
  private lock = 0;

  private index: number = 30;
  private isLoadMore: boolean = false;
  private readonly STATE_BATCH: number = 60;

  constructor(
    private _deviceContainerStateService: DeviceContainerStateControllerService,
    public _geoUtils: GeoUtils,
    private store: Store<any>,
    private _userRightsService: UserRightsService
  ) {}

  ngOnInit() {
    this.initialisation = false;
    this.inputData = {
      containerType: this.currentState.containerType,
      deviceId: this.currentState.deviceId,
      isIncident: this.currentState.isIncident
    };
    this.exportData = {
      requestId: 0,
      containerTypes: [],
      filterDeviceId: this.currentState.deviceId,
      filtersColor: this.statusList
    };

    this.subscribeTostatusStore();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.loading = true;
    this.pageSize = event.pageSize;
    this.subscription.add(
      this._deviceContainerStateService
        .getHistoricalStateByDeviceContainer({
          requestId: 0,
          containerTypes: [],
          filterDeviceId: this.currentState.deviceId,
          filtersColor: this.statusList,
          limit: this.pageSize,
          skip: event.pageIndex * this.pageSize
        })
        .subscribe((res) => {
          this.listHisto = res;
          this.listHistoSorted = this.listHisto;
          this.rebuildSortedContainerList();
          this.loading = false;
        })
    );
    return event;
  }

  public subscribeTostatusStore() {
    this.subscription.add(
      this.store.select("statusStore").subscribe((statusData: Array<any>) => {
        this.statusList = statusData;
        this.exportData = {
          ...this.exportData,
          filtersColor: statusData
        };
        this.initialisation = false;
        this.getHistoricalStateByDeviceContainer();
      })
    );
  }

  public getHistoricalStateByDeviceContainer() {
    if (!this.initialisation) {
      this.loading = true;
      this.lock++;
      const marker: number = this.lock;
      setTimeout(() => {
        if (marker === this.lock) {
          this.subscription.add(
            this._deviceContainerStateService
              .getHistoricalStateByDeviceContainer({
                requestId: 0,
                containerTypes: [],
                filterDeviceId: this.currentState.deviceId,
                filtersColor: this.statusList,
                limit: this.pageSize,
                skip: 0
              })
              .subscribe((res) => {
                this.listHisto = res;
                this.rebuildSortedContainerList();
                if (this.paginator) {
                  this.paginator.pageIndex = 0;
                }
                this.loading = false;
              })
          );

          this.subscription.add(
            this._deviceContainerStateService
              .getHistoricalCount({
                requestId: 0,
                containerTypes: [],
                filterDeviceId: this.currentState.deviceId,
                filtersColor: this.statusList
              })
              .subscribe((res) => {
                this.totalFiteredHistoricalStates = res.count;
              })
          );
        }
      }, 1000);
    }
  }

  updateFilter(filter: any) {
    this.store.dispatch({ type: UPDATE_STATUS_DETAIL, payload: filter });
  }

  public changeTimeStampFormat(timestamp: number): string {
    const truc = moment.unix(timestamp);
    return moment(truc).format("DD/MM/YYYY HH:mm");
  }

  close() {
    this.display = false;
    this.displayChange.emit(this.display);
  }

  public backToMap(): void {
    this.navigate.emit({
      tab: userAccess.MAP,
      showDetailMap: true,
      currentState: this.currentState
    });
  }

  private rebuildSortedContainerList(): void {
    this.listHistoSorted = this.listHisto;
    this.listHistoSorted.sort((a, b) => {
      return b.receivedMessageTime - a.receivedMessageTime;
    });
  }

  protected isAdmin(event: string): boolean {
    return this._userRightsService.getUserRightLevel(event);
  }

  public loadMore = () => {
    if (!this.isLoadMore && this.index < this.totalFiteredHistoricalStates) {
      this.isLoadMore = true;
      this.subscription.add(
        this._deviceContainerStateService
          .getHistoricalStateByDeviceContainer({
            requestId: 0,
            containerTypes: [],
            filterDeviceId: this.currentState.deviceId,
            filtersColor: this.statusList,
            limit: this.STATE_BATCH,
            skip: this.index
          })
          .subscribe((res) => {
            this.listHisto.push(...res);
            this.rebuildSortedContainerList();
            if (this.paginator) {
              this.paginator.pageIndex = 0;
            }
            this.index += this.STATE_BATCH;
            this.isLoadMore = false;
          })
      );
    }
  };
}
