import { Component, Input, OnInit } from "@angular/core";

type CalloutType = "warn" | "info";
type CalloutStyle = "outlined" | "filled";

@Component({
  selector: "app-callout",
  templateUrl: "./callout.component.html",
  styleUrls: ["./callout.component.scss"]
})
export class CalloutComponent implements OnInit {
  @Input() type: CalloutType = "warn";
  @Input() style: CalloutStyle = "filled";

  public calloutClass: string = "callout";

  constructor() {}

  ngOnInit(): void {
    this.calloutClass += ` ${this.type}-${this.style}`;
  }
}
