import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-state-displayer-label",
  templateUrl: "./state-displayer-label.component.html",
  styleUrls: ["./state-displayer-label.component.scss"]
})
export class StateDisplayerLabelComponent implements OnInit {
  @Input() labelType: "Incident" | "Torn";

  constructor() {}

  ngOnInit(): void {}
}
