import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-copiable-device-info",
  templateUrl: "./copiable-device-info.component.html",
  styleUrls: ["./copiable-device-info.component.scss"]
})
export class CopiableDeviceInfoComponent implements OnInit {
  @Input() labelPath: string;
  @Input() value?: string;
  @Input() icon?: string;
  @Input() iconColor?: string;

  public displayCheck: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  public onClipboardCopy(_successful: boolean): void {
    this.displayCheck = true;
    setTimeout(() => {
      this.displayCheck = false;
    }, 1800);
  }
}
