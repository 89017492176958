<mat-radio-button
  (change)="changeElement.emit()"
  class="radio-button"
  [class.selected]="selected"
  [labelPosition]="labelPosition"
  [value]="value"
>
  <p class="modal-title">{{ titlePath | translate }}</p>
  <p>{{ subtitlePath | translate }}</p>
</mat-radio-button>
