import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * CheckVersioningController service call api rest for Site - return string[]
 */

export interface AppVersion {
  versionKey: string;
}

@Injectable({
  providedIn: "root"
})
export class CheckVersioningControllerService {
  constructor(public httpClientWrapper: HttpClientWrapper<AppVersion>) {}

  getCheckVersioning(): Observable<AppVersion> {
    return this.httpClientWrapper.postUnique(
      environment.apiBaseUrl + "/check-versioning",
      {}
    );
  }
}
