import { Component, Input, OnInit } from "@angular/core";
import { IncidentCommentDTO, IncidentDTO } from "../../../../models/incident";
import { IncidentControllerService } from "../../../../services/incident-controller.service";
import { Subscription } from "rxjs";
import { PageEvent } from "@angular/material/paginator";

@Component({
  selector: "app-comment",
  templateUrl: "./comment.component.html",
  styleUrls: ["./comment.component.scss"]
})
export class CommentComponent implements OnInit {
  constructor(public incidentControllerService: IncidentControllerService) {}

  @Input() incident: IncidentDTO;

  commentsList: Array<IncidentCommentDTO>;
  incidentComment: Partial<IncidentCommentDTO> = {};
  private subscription: Subscription = new Subscription();
  commentFromForm: string;
  initials: string;
  numberOfAllComment: number = 0;
  filterComment: any = undefined;
  limitComment: number = 512;

  ngOnInit(): void {
    this.getIncidentComments(this.incident.id);
    this.countComments();
  }

  public pageSize: number = 25;
  public pageIndex: number = 0;
  public commentSize: number = 0;
  public limitState: boolean = false;

  commentRequestParameter: any = {
    skip: 0,
    limit: 25,
    filter: {}
  };

  commentLength(commentFromForm) {
    let i: number = 0;
    let j: number = 0;
    const specialCharactersRegex = /[!#$%^&*(),.?":{}|<>À-ÖØ-öø-ÿ]/;
    const spaceRegex = /\s/;
    [...commentFromForm].forEach((letter) => {
      if (specialCharactersRegex.test(letter)) {
        i += 6;
      } else if (spaceRegex.test(letter)) {
        j += 3;
      }
    });
    this.commentSize = commentFromForm.length + i + j;
    if (this.commentSize > this.limitComment) {
      this.limitState = true;
    } else {
      this.limitState = false;
    }
  }

  public createComment(): IncidentCommentDTO {
    const comment: IncidentCommentDTO = {
      id: this.incidentComment.id,
      comment: this.incidentComment.comment,
      incidentId: this.incidentComment.incidentId,
      commentedBy: this.incidentComment.commentedBy,
      creationDate: this.incidentComment.creationDate
    };
    comment.comment = encodeURI(this.commentFromForm);
    comment.incidentId = this.incident.id;
    if (comment.comment !== "") {
      this.subscription.add(
        this.incidentControllerService
          .createIncidentComment(comment)
          .subscribe((rescomment) => {
            rescomment.comment = decodeURI(rescomment.comment);
            this.commentsList.unshift(rescomment);
            this.numberOfAllComment += 1;
            this.limitState = false;
          })
      );
    }
    this.commentFromForm = "";
    return comment;
  }

  refreshCommentList() {
    this.subscription.add(
      this.incidentControllerService
        .getIncidentComment(
          this.incident.id,
          this.commentRequestParameter.skip,
          this.commentRequestParameter.limit,
          this.filterComment
        )
        .subscribe((comments) => {
          this.commentsList = comments;
        })
    );
  }

  getIncidentComments(
    incidentId: typeof IncidentCommentDTO.prototype.incidentId
  ) {
    this.subscription.add(
      this.incidentControllerService
        .getIncidentComment(
          incidentId,
          this.pageIndex,
          this.pageSize,
          this.filterComment
        )
        .subscribe((comments) => {
          comments.forEach((comment) => {
            comment.comment = decodeURI(comment.comment);
          });
          this.commentsList = comments;
        })
    );
  }

  countComments() {
    this.subscription.add(
      this.incidentControllerService
        .getCountComment(this.incident.id)
        .subscribe((res) => {
          this.numberOfAllComment = res.count;
        })
    );
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.pageSize = event.pageSize;
    this.commentRequestParameter.limit = this.pageSize;
    this.pageIndex = event.pageIndex;
    this.commentRequestParameter.skip = this.pageIndex * this.pageSize;
    this.refreshCommentList();
    return event;
  }
}
