<div
  class="main-section-item"
  *ngFor="let input of formConfig.inputs; let i = index"
>
  <div class="section-item" *ngIf="input.visible">
    <div class="label-item">
      <div class="label-text">
        {{ "manager.inputs." + input.title + ".title" | translate }}
      </div>
      <div class="label-input-mandatory" *ngIf="input.mandatory">*</div>
      <div class="property-error" *ngIf="input.valid === false">
        -
        {{
          input.errorMsg
            ? input.errorMsg
            : ("manager.inputs." + input.title + ".errorMsg" | translate)
        }}
      </div>
    </div>

    <div class="property-input-text" *ngIf="input.input">
      <div [class]="input.type === 'password' ? 'password-item' : 'field-item'">
        <input
          *ngIf="input.property !== 'creationDate'"
          [class]="input.valid === false ? 'input-error' : 'input'"
          [type]="
            input.type === 'password'
              ? hide
                ? 'password'
                : 'text'
              : input.type
          "
          [placeholder]="
            'manager.inputs.' + input.title + '.placeholder' | translate
          "
          [disabled]="input.id && update"
          [(ngModel)]="objectCreated[input.property]"
          (change)="onValidateForm(i, input)"
        />
        <p
          *ngIf="input.property === 'creationDate'"
          class="creationDate"
          [disabled]="true"
        >
          {{ input.value | customDateComment }}
        </p>
        <mat-icon
          class="visibility-icon"
          *ngIf="input.type === 'password'"
          matSuffix
          (click)="hide = !hide"
        >
          {{ hide ? "visibility_off" : "visibility" }}
        </mat-icon>
      </div>
      <div class="bottom-border"></div>
    </div>

    <div
      [class]="input.valid === false ? 'selector-error' : 'selector-type'"
      *ngIf="input.selector"
      [matMenuTriggerFor]="ItemMenu"
      (menuOpened)="changeArrow(input)"
      (menuClosed)="changeArrow(input)"
    >
      <div class="selector">
        <div class="list-box">
          <div class="list-box-label">
            {{
              objectCreated[input.property]
                ? objectCreated[input.property]
                : ("manager.inputs." + input.title + ".placeholder" | translate)
            }}
          </div>
          <div class="menu-icon">
            <mat-icon>
              {{ input.isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
            </mat-icon>
          </div>
        </div>
      </div>
      <div class="bottom-border"></div>
      <mat-menu class="form-menu" #ItemMenu="matMenu">
        <div class="menu-holder">
          <div
            *ngFor="let choice of input.choices; first as isFirst"
            class="choice"
          >
            <div
              [class]="isFirst ? 'first-choice' : 'choice-button'"
              (click)="selectProp(choice, input.property, i, input)"
            >
              {{ choice }}
            </div>
          </div>
        </div>
      </mat-menu>
    </div>
    <div
      [class]="input.valid === false ? 'selector-error' : 'selector-type'"
      *ngIf="input.search_selector"
      [matMenuTriggerFor]="ItemMenu"
      (menuOpened)="changeArrow(input)"
      (menuClosed)="changeArrow(input)"
    >
      <div class="selector">
        <div class="list-box">
          <div class="list-box-label">
            <input
              class="search_selector_input"
              *ngIf="input.search_selector"
              [type]="text"
              [placeholder]="'Filter ' + (input.title | translate)"
              [ngModel]="
                !pipeData ? input.filter : (input.filter | objectTransform)
              "
              (ngModelChange)="input.filter = $event"
              (input)="onFilterChange(input)"
            />
          </div>
          <div class="menu-icon">
            <mat-icon>
              {{ input.isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
            </mat-icon>
          </div>
        </div>
      </div>
      <div class="bottom-border"></div>
      <mat-menu class="form-menu" #ItemMenu="matMenu">
        <div class="menu-holder">
          <div
            *ngFor="let choice of input.filteredOptions; first as isFirst"
            class="choice"
          >
            <div
              *ngIf="input.search_selector"
              [class]="isFirst ? 'first-choice' : 'choice-button'"
              (click)="selectProp(choice, input.property, i, input)"
            >
              <span *ngIf="input.property === 'currentSite'">{{
                choice | objectTransform
              }}</span>
              <span *ngIf="input.property !== 'currentSite'">{{ choice }}</span>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>
</div>
<div [ngStyle]="{ color: '#aaaaaa' }" class="requiredFields">
  <span>*</span>
  {{ "manager.form.requiredFields" | translate }}
</div>
