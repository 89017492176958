import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { CountDTO } from "../models/historical-state";
import {
  ProfileContainerTypeDTO,
  ProfileCreationDTO
} from "../models/profile-container-type";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * SitePostalController service call api rest for PostalAddress - return PostalAddressDTO
 */

@Injectable()
export class ProfileContainerTypeControllerService {
  constructor(
    public httpClientWrapper: HttpClientWrapper<ProfileContainerTypeDTO>,
    public httpClientCount: HttpClientWrapper<CountDTO>
  ) {}

  getProfiles(
    skip?: number,
    limit?: number,
    filterInput?: any
  ): Observable<ProfileContainerTypeDTO[]> {
    let filter: any = {};

    if (skip) filter.skip = skip;
    if (limit) filter.limit = limit;
    if (filterInput) filter.where = filterInput;
    filter.order = "profileContainerTypeId DESC";

    return this.httpClientWrapper.get(
      `${environment.apiBaseUrl}container-type/profile?filter=${JSON.stringify(
        filter
      )}`
    );
  }

  getCount(filterInput?: any): Observable<CountDTO> {
    let _filterFormated: string = "?where=";
    let filter: any = {};
    if (filterInput) {
      filter = filterInput;
    }
    _filterFormated = _filterFormated + JSON.stringify(filter);

    return this.httpClientCount.getUnique(
      environment.apiBaseUrl + "container-type/profile/count"
    );
  }

  createProfile(profileCreation: ProfileCreationDTO) {
    return this.httpClientWrapper.post(
      environment.apiBaseUrl + "container-type/profile/",
      profileCreation
    );
  }

  deleteProfile(profileContainerTypeId: string) {
    return this.httpClientWrapper.delete(
      environment.apiBaseUrl +
        "container-type/profile/" +
        profileContainerTypeId
    );
  }

  patchProfile(profileCreation: ProfileCreationDTO) {
    return this.httpClientWrapper.patch(
      environment.apiBaseUrl +
        "container-type/profile/" +
        profileCreation.profile.profileContainerTypeId,
      profileCreation
    );
  }
}
