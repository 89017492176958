<div class="position-controller">
  <app-button
    iconLeft="chevron-end-left"
    (clicked)="firstPos.emit()"
    [disabled]="isAtHistoryBeginning"
    [rounded]="true"
    type="filled"
  ></app-button>
  <div class="central-button-container">
    <div class="central-button">
      <app-button
        labelPath="map-detail.positionController.before"
        iconLeft="chevron-left"
        (clicked)="previousPos.emit()"
        [disabled]="isAtHistoryBeginning"
        [rounded]="true"
        type="filled"
      ></app-button>
    </div>
    <div class="central-button">
      <app-button
        labelPath="map-detail.positionController.after"
        iconRight="chevron-right"
        (clicked)="nextPos.emit()"
        [disabled]="isAtHistoryEnd"
        [rounded]="true"
        type="filled"
      ></app-button>
    </div>
  </div>
  <app-button
    iconRight="chevron-end-right"
    (clicked)="lastPos.emit()"
    [disabled]="isAtHistoryEnd"
    [rounded]="true"
    type="filled"
  ></app-button>
</div>
