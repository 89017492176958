import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "../components/pages/dashboard/dashboard.component";
import { LoginComponent } from "../components/pages/login/login.component";
import { NotFoundComponent } from "../components/pages/not-found/not-found.component";
import { AuthGuard } from "../services/auth-guard.service";

const routingModule: Routes = [
  {
    path: "",
    component: LoginComponent
  },
  {
    canActivate: [AuthGuard],
    path: "dashboard",
    component: DashboardComponent
  },
  {
    path: "**",
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routingModule, {
      enableTracing: false,
      useHash: true,
      relativeLinkResolution: "legacy"
    }),
    BrowserAnimationsModule
  ],
  exports: [RouterModule]
})
export class RoutingModule {}
