import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "objectTransform" })
export class ObjectTransform implements PipeTransform {
  transform(object: any): string {
    let displayProperty;
    if (object.label !== undefined) {
      displayProperty = object.label + " " + object.code;
    } else {
      displayProperty = object;
    }
    return displayProperty;
  }
}
