import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { CountDTO } from "../models/historical-state";
import { UserCreationDTO, UserDTO } from "../models/user";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * SitePostalController service call api rest for PostalAddress - return PostalAddressDTO
 */

@Injectable()
export class UserControllerService {
  constructor(
    public httpClientWrapper: HttpClientWrapper<UserDTO>,
    public httpClientCount: HttpClientWrapper<CountDTO>
  ) {}

  getUsers(
    skip?: number,
    limit?: number,
    filterInput?: any
  ): Observable<UserDTO[]> {
    let filter: any = {};

    if (skip) filter.skip = skip;
    if (limit) filter.limit = limit;
    if (filterInput) filter.where = filterInput;
    filter.order = "name DESC";

    return this.httpClientWrapper.get(
      `${environment.apiBaseUrl}users?filter=${JSON.stringify(filter)}`
    );
  }

  getUserNames(filterUser?): Observable<Partial<UserDTO[]>> {
    let filter: any = { fields: { name: true } };

    if (filterUser) {
      filter = { ...filter, where: { ...filterUser } };
    }

    return this.httpClientWrapper.get(
      `${environment.apiBaseUrl}userNames?filter=${JSON.stringify(filter)}`
    );
  }

  getCount(filterInput?: any): Observable<CountDTO> {
    let filterFormated: string = "?where=";
    let filter: any = {};
    if (filterInput) {
      filter = filterInput;
    }
    filterFormated = filterFormated + JSON.stringify(filter);
    return this.httpClientCount.getUnique(
      environment.apiBaseUrl + "users/count" + filterFormated
    );
  }

  createUser(userCreation: UserCreationDTO) {
    return this.httpClientWrapper.post(
      environment.apiBaseUrl + "user/",
      userCreation
    );
  }

  deleteUser(userId: string) {
    return this.httpClientWrapper.delete(
      environment.apiBaseUrl + "users/" + userId
    );
  }

  patchUser(userCreation: UserCreationDTO) {
    return this.httpClientWrapper.patch(
      environment.apiBaseUrl + "users/" + userCreation.user.name,
      userCreation
    );
  }
}
