import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { SimpleDialogComponent } from "../components/dialog/simple-dialog.component";
import { InternalUserControllerService } from "./internal-user-controller.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private dialogRefOpen: boolean = false;

  constructor(
    private router: Router,
    private _userService: InternalUserControllerService,
    public dialog: MatDialog
  ) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      //navigate /delete cookies or whatever
      if (this._userService.getIsLogin() && !this.dialogRefOpen) {
        this.dialogRefOpen = true;
        this.redirection();
      }
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      throw err; // or EMPTY may be appropriate here
    }
    throw err;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem("token");

    if (token) {
      request = request.clone({
        headers: request.headers.set(
          "Authorization",
          "Bearer " +
            btoa(
              localStorage.getItem("username") +
                ":" +
                localStorage.getItem("password")
            )
        )
      });
    }

    if (!request.headers.has("Content-Type")) {
      request = request.clone({
        headers: request.headers.set("Content-Type", "application/json")
      });
    }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json")
    });

    return next
      .handle(request)
      .pipe(catchError((err) => this.handleAuthError(err)));
  }

  public redirection() {
    const dialogRef = this.dialog.open(SimpleDialogComponent, {
      data: {
        title: "Log out",
        text: "Your session has expired",
        yes: "Ok"
      }
    });

    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this._userService.logout();
        this.router.navigate(["/"], {});
        this.dialogRefOpen = false;
      }
    });
  }
}
