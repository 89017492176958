<div class="header-filter-analytics">
  <app-button-list
    [label]="'graphic'"
    [type]="'radio'"
    [icon]="'graph'"
    [data]="graphNames"
    (manageList)="manageGraphics($event)"
    [isEmpty]="!graphNames.length"
    [pathTranslate]="'analytics.navigator.{{item}}.sliderLabel'"
  ></app-button-list>

  <app-button-list
    *ngIf="graphConfig?.frontConfig?.containers"
    [label]="'containerType'"
    [type]="'checkbox'"
    [icon]="'container'"
    [data]="containersList"
    [maxItems]="graphConfig?.frontConfig?.maxContainers || undefined"
    [checkedData]="filterContainers"
    (manageList)="manageContainers($event)"
    [isEmpty]="!containersList.length"
  ></app-button-list>

  <app-button-list
    *ngIf="graphConfig?.frontConfig?.dateRange"
    [label]="'dateRange'"
    [type]="'dateRange'"
    [icon]="'slider'"
    [data]="filterDates"
    (manageList)="manageDates($event)"
    [isEmpty]="!filterDates.length"
  ></app-button-list>

  <app-button-list
    *ngIf="graphConfig?.frontConfig?.sites"
    [label]="'sites'"
    [type]="'checkbox'"
    [icon]="'site'"
    [data]="sitesList"
    [checkedData]="checkedSites"
    (manageList)="manageSites($event)"
    [isEmpty]="!sitesList.length"
  ></app-button-list>

  <app-button-list
    *ngIf="graphConfig?.frontConfig?.departureSites"
    [label]="'departureSites'"
    [type]="'checkbox'"
    [icon]="'last-site'"
    [data]="departureSitesList"
    [checkedData]="checkedDepartureSites"
    (manageList)="manageDepartureSites($event)"
    [isEmpty]="!departureSitesList.length"
  ></app-button-list>

  <app-button-list
    *ngIf="graphConfig?.frontConfig?.arrivalSites"
    [label]="'arrivalSites'"
    [type]="'checkbox'"
    [icon]="'next-site'"
    [data]="arrivalSitesList"
    [checkedData]="checkedArrivalSites"
    (manageList)="manageArrivalSites($event)"
    [isEmpty]="!arrivalSitesList.length"
  ></app-button-list>

  <app-searchbar-number
    *ngIf="graphConfig?.frontConfig?.days"
    [label]="'days'"
    [icon]="'calendar'"
    [inputData]="filterDays"
    [inputActive]="!!filterDays"
    (number)="manageDays($event)"
  ></app-searchbar-number>
</div>
