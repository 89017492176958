export const getBatteryIconName = (
  batteryLevel: string
): string | undefined => {
  switch (batteryLevel) {
    case "Empty":
      return "battery-1";
    case "Almost Empty":
      return "battery-2";
    case "Good":
      return "battery-3";
    case "Excellent":
      return "battery-4";
    default:
      return undefined;
  }
};
