<button
  class="btn-list-single-select"
  [class.selected]="selectedElementPath"
  [class.not-selected]="!selectedElementPath"
  (click)="onClicked()"
>
  <div class="icon-label">
    <svg-icon
      [svgStyle]="{ 'height.px': 16, 'width.px': 16 }"
      [name]="icon"
    ></svg-icon>
    <p *ngIf="!selectedElementPath && placeholderPath">
      {{ placeholderPath | translate }}
    </p>
    <p *ngIf="selectedElementPath">{{ selectedElementPath | translate }}</p>
  </div>
  <svg-icon
    [svgStyle]="{ 'height.px': 16, 'width.px': 16 }"
    name="chevron-down"
  ></svg-icon>
</button>
