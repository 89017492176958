import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class HttpClientWrapper<T> {
  options: { headers: HttpHeaders };

  constructor(private http: HttpClient) {}

  createAuthorizationHeader() {
    this.options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          btoa(
            localStorage.getItem("username") +
              ":" +
              localStorage.getItem("password")
          ),
        Accept: "application/json"
      })
    };
  }

  get(url, header = true): Observable<T[]> {
    if (header) this.createAuthorizationHeader();

    return this.http.get<T[]>(url, this.options);
  }

  post(url, data, header = true): Observable<T[]> {
    if (header) this.createAuthorizationHeader();

    return this.http.post<T[]>(url, data, this.options);
  }

  getUnique(url, header = true): Observable<T> {
    if (header) this.createAuthorizationHeader();

    return this.http.get<T>(url, this.options);
  }

  postUnique(url, data, header = true): Observable<T> {
    if (header) this.createAuthorizationHeader();

    return this.http.post<T>(url, data, this.options);
  }

  delete(url, header = true): Observable<T[]> {
    if (header) this.createAuthorizationHeader();

    return this.http.delete<T[]>(url, this.options);
  }

  patch(url, data, header = true): Observable<T> {
    if (header) this.createAuthorizationHeader();

    return this.http.patch<T>(url, data, this.options);
  }

  put(url, data, header = true): Observable<T> {
    if (header) this.createAuthorizationHeader();

    return this.http.put<T>(url, data, this.options);
  }

  deleteAll(url, ids: T[], header = true): Observable<void> {
    if (header) this.createAuthorizationHeader();

    const options = {
      ...this.options,
      body: ids
    };

    return this.http.delete<void>(url, options);
  }
}
