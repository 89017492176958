<div class="device-information">
  <app-copiable-device-info
    labelPath="map-detail.labels.device"
    [value]="currentState.deviceId"
  ></app-copiable-device-info>
  <app-copiable-device-info
    labelPath="map-detail.labels.container"
    [value]="currentState.containerType"
  ></app-copiable-device-info>
  <app-copiable-device-info
    labelPath="map-detail.labels.state"
    [value]="currentState.statusDescription.toUpperCase()"
    icon="device-state-pin"
    [iconColor]="colorService.getColorByStatusColor(currentState.statusColor)"
  ></app-copiable-device-info>
  <app-copiable-device-info
    labelPath="map-detail.labels.date"
    [value]="getDate()"
  ></app-copiable-device-info>
  <app-copiable-device-info
    labelPath="map-detail.labels.position"
    [value]="getPosition()"
  ></app-copiable-device-info>
  <app-copiable-device-info
    labelPath="map-detail.labels.radius"
    [value]="getRadius() + ' (' + (getLocationSourceKey() | translate) + ')'"
    [icon]="getLocationSourceIcon()"
  ></app-copiable-device-info>
  <div class="information-divider"></div>
  <app-copiable-device-info
    labelPath="map-detail.labels.lastAuthorizedSite"
    [value]="getLastAuthorizedSite()"
  ></app-copiable-device-info>
  <app-copiable-device-info
    labelPath="map-detail.labels.timeLastAuthorizedSite"
    [value]="getTimeLastAuthorizedSite()"
  ></app-copiable-device-info>
  <div class="information-divider"></div>
  <app-copiable-device-info
    labelPath="map-detail.labels.battery"
    [value]="getBatteryLevel() | translate"
    [icon]="getBatteryIcon(currentState.batteryLevel)"
  >
  </app-copiable-device-info>
  <app-copiable-device-info
    *ngIf="currentState.seqNumber && isAdmin"
    labelPath="map-detail.labels.seqNumber"
    [value]="currentState.seqNumber"
  ></app-copiable-device-info>
  <app-copiable-device-info
    *ngIf="currentState.source && isAdmin"
    labelPath="map-detail.labels.source"
    [value]="currentState.source"
  ></app-copiable-device-info>
  <app-position-controller
    (nextPos)="nextPos.emit()"
    (previousPos)="previousPos.emit()"
    (firstPos)="firstPos.emit()"
    (lastPos)="lastPos.emit()"
    [isAtHistoryEnd]="isAtHistoryEnd"
    [isAtHistoryBeginning]="isAtHistoryBeginning"
  ></app-position-controller>
</div>
