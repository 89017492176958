import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-state-displayer-status",
  templateUrl: "./state-displayer-status.component.html",
  styleUrls: ["./state-displayer-status.component.scss"]
})
export class StateDisplayerStatusComponent implements OnInit {
  @Input() statusDescription: string;
  @Input() statusDays: number;
  @Input() statusColor: string;

  public isStatusDescriptionKey: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (
      [
        "ok",
        "transit",
        "alert",
        "tear-off",
        "static",
        "late",
        "detour",
        "quiet"
      ].includes(this.statusDescription)
    ) {
      this.isStatusDescriptionKey = true;
    }
  }

  public getStatusDescriptionKey(): string {
    if (
      this.statusDescription === "OK" ||
      this.statusDescription === "tear-off"
    ) {
      return this.statusDescription.toLowerCase();
    } else if (this.statusDescription === "IN TRANSIT") {
      return "transit";
    } else if (this.statusDescription.includes("ALERT")) {
      return "alert";
    } else if (this.statusDescription.includes("detour")) {
      return "detour";
    } else if (this.statusDescription.includes("static")) {
      return "static";
    } else if (this.statusDescription.includes("late")) {
      return "late";
    } else if (this.statusDescription.includes("Quiet")) {
      return "quiet";
    }
    return "partialData";
  }

  public isDayDisplayable(): boolean {
    return (
      this.statusDays &&
      this.statusDescription !== "transit" &&
      this.statusDescription !== "IN TRANSIT" &&
      this.statusDescription !== "ok" &&
      this.statusDescription !== "OK"
    );
  }
}
