<mat-divider></mat-divider>
<div class="footer" (click)="$event.stopPropagation()">
  <button
    mat-stroked-button
    *ngIf="footerType[label] !== 'radio'"
    [class]="{
      'button-list-footer-btn': true,
      grey: !footerType[label] || footerType[label] === 'reset',
      empty: footerType[label] === 'incident'
    }"
    (click)="onClearItems()"
  >
    {{
      (footerType[label] === "reset" ? "misc.reset" : "misc.clear") | translate
    }}
  </button>
  <button
    *ngIf="footerType[label] === 'incident'"
    mat-stroked-button
    class="button-list-footer-btn white"
    (click)="onDisplayMore()"
  >
    {{ ("misc.more" | translate) + "..." }}
  </button>

  <button
    *ngIf="!isLoading"
    mat-stroked-button
    class="button-list-footer-btn blue"
    (click)="onApplyItems()"
  >
    {{ (!isNewIncident ? "misc.apply" : "misc.new") | translate }}
  </button>
  <div
    *ngIf="isLoading && footerType[label] === 'incident'"
    class="button-list-spinner-container"
  >
    <mat-spinner class="button-list-spinner" [diameter]="16"></mat-spinner>
  </div>
</div>
