<notifier-container></notifier-container>
<div class="login-logo background-primary"></div>
<div class="login-form background-primary">
  <form class="white-input" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div [ngClass]="{ 'form-inputs': true, 'pwd-active': !sso }">
      <mat-form-field>
        <input
          matInput
          placeholder="{{ 'login.mailPHolder' | translate }}"
          type="email"
          formControlName="email"
          required
        />
        <mat-error
          *ngIf="loginForm.controls.email.hasError('required')"
          [innerHTML]="'login.usernameError' | translate"
        >
        </mat-error>
      </mat-form-field>
      <mat-form-field [ngClass]="{ 'pwd-input': true, active: !sso }">
        <input
          matInput
          placeholder="{{ 'login.pwdPHolder' | translate }}"
          type="password"
          formControlName="pwd"
          required
        />
        <mat-error
          *ngIf="loginForm.controls.pwd.hasError('required')"
          [innerHTML]="'login.pwdError' | translate"
        >
        </mat-error>
      </mat-form-field>
    </div>
    <button mat-button>{{ "login.login" | translate }}</button>
  </form>
</div>
<div class="login-footer background-primary"></div>
