import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from "@angular/core";

@Component({
  selector: "app-header-kpi",
  templateUrl: "./header-kpi.component.html",
  styleUrls: ["./header-kpi.component.scss"]
})
export class HeaderKpiComponent implements OnInit, OnChanges, OnDestroy {
  @Output() toggleBattery: EventEmitter<any> = new EventEmitter();
  @Output() containers: EventEmitter<any> = new EventEmitter();

  @Input() batteryVisible: boolean = false;
  @Input() filterContainers: any[] = [];

  private subscription: Subscription = new Subscription();

  public containersList: any[] = [];
  public debounce;

  constructor(private store: Store<any>) {}

  public ngOnInit() {
    this.initStore();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterContainers) {
      this.filterContainers = this.filterContainers.map((container) => {
        return {
          ...container,
          key: container.code,
          keyDisplayed: `${container.code} - ${container.label}`,
          checked: !!this.filterContainers.find(
            ({ code }) => code === container.code
          )
        };
      });
    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initStore() {
    this.subscription.add(
      this.store.select("filters").subscribe((filters) => {
        if (filters?.containers) {
          this.containersList = filters.containers.map((container) => {
            return {
              ...container,
              key: container.code,
              keyDisplayed: `${container.code} - ${container.label}`,
              checked: !!this.filterContainers.find(
                ({ code }) => code === container.code
              )
            };
          });
        }
      })
    );
  }

  public manageContainers(containers): void {
    this.containers.emit(containers);
  }

  public tglBattery() {
    this.toggleBattery.emit();
  }
}
