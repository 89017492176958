<div class="body-main">
  <!-- Navbar -->
  <div class="navbar">
    <div
      class="navigator"
      (click)="onNavChange(nav)"
      *ngFor="let nav of getNavObject(); let i = index"
    >
      <div [ngClass]="nav.selectorStyle" *ngIf="nav.navName">
        {{ "manager.refName." + nav.navName + ".title" | translate }}
      </div>
    </div>
  </div>
  <!-- Site Tab -->
  <div class="type-holder" *ngIf="selectedNav === 'Sites'">
    <app-reference-type
      [service]="siteControllerService"
      [formConfig]="formConfigSite"
      [properties]="siteProperties"
    ></app-reference-type>
  </div>
  <!-- ContainerTypes Tab -->
  <div class="type-holder" *ngIf="selectedNav === 'containerTypes'">
    <app-reference-type
      [service]="containerTypeControllerService"
      [formConfig]="formConfigCT"
      [properties]="ctProperties"
    ></app-reference-type>
  </div>
  <!-- Routes Tab -->
  <div class="type-holder" *ngIf="selectedNav === 'Routes'">
    <app-reference-type
      [service]="containerTypeAuthSiteControllerService"
      [formConfig]="formConfigCTAuth"
      [properties]="ctAuthProperties"
    ></app-reference-type>
  </div>
  <!-- Mac Addresses Tab -->
  <div class="type-holder" *ngIf="selectedNav === 'macAddresses'">
    <app-reference-type
      [service]="macAddressControllerService"
      [formConfig]="formConfigMacAddresses"
      [properties]="macAddressesProperties"
    ></app-reference-type>
  </div>
  <!-- Matching Tab -->
  <div class="type-holder" *ngIf="selectedNav === 'Matching'">
    <app-matching></app-matching>
  </div>
</div>
