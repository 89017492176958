import { Injectable } from "@angular/core";

export class UserAccess {
  MAP: string;
  LIST: string;
  KPI: string;
  PROVISIONING: string;
  ANALYTICS: string;
  MANAGER: string;
  INCIDENT: string;
}

export const userAccess: UserAccess = {
  KPI: "KPI",
  MAP: "MAP",
  LIST: "LIST",
  ANALYTICS: "ANALYTICS",
  MANAGER: "MANAGER",
  INCIDENT: "INCIDENT",
  PROVISIONING: "PROVISIONING"
};

@Injectable({
  providedIn: "root"
})
export class UserRightsService {
  private readonly RIGHT_LIST = ["User", "SuperUser", "Admin", "IBMAdmin"];

  constructor() {}

  getUserRights(): UserAccess {
    return userAccess;
  }

  public getUserRightLevel(rightLevel: string): boolean {
    const username: string = window.localStorage.getItem("username");
    const profilId: string = window.localStorage.getItem("profilId");
    if (username && profilId) {
      const actualRightLevel: number = this.RIGHT_LIST.indexOf(profilId);
      const rightLevelNeeded: number = this.RIGHT_LIST.indexOf(rightLevel);
      if (actualRightLevel >= rightLevelNeeded) {
        return true;
      }
    }
    return false;
  }
}
