import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"]
})
export class DatePickerComponent {
  @Input() placeholderPath: string;
  @Input() selectedDate: Date;

  @Output() date: EventEmitter<Date> = new EventEmitter();

  constructor() {}

  public onDateChange(datePickerEvent): void {
    this.date.emit(datePickerEvent.value);
  }

  dateFilter = (d: Date | null): boolean => {
    const currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0);
    return d === null || d < currentDate;
  };
}
