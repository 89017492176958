import { Component, OnInit, Input, HostListener } from "@angular/core";
import {
  AnalyticsForGraphics,
  AnalyticsControllerService
} from "../../../../services/analytics-controller.service";
import { ContainerTypeDTO } from "../../../../models/container-type";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-graphs-displayer",
  templateUrl: "./graphs-displayer.component.html",
  styleUrls: ["./graphs-displayer.component.scss"]
})
export class GraphsDisplayerComponent implements OnInit {
  @Input() datas: AnalyticsForGraphics[];
  @Input() containerTypes: ContainerTypeDTO[];
  @Input() config: any;

  public graphs: any[] = [];

  constructor(
    public analyticsService: AnalyticsControllerService,
    private translate: TranslateService
  ) {
    translate.onLangChange.subscribe(() => {
      if (this.graphs?.length) {
        this.applyOnAllGraphs(this.updateGraphTitle.bind(this));
      }
    });
  }

  ngOnInit(): void {
    this.prepareGraphs();
  }

  @HostListener("window:resize", ["$event"])
  public onResize() {
    this.applyOnAllGraphs(this.resizeConfigLayout.bind(this));
  }

  /**
   * Update the title of the param graph
   * @param {any} graph - The selected graph
   * @param {number} index - The graph index in this.graphs
   */
  private updateGraphTitle(graph: any, index: number): void {
    this.translate.get("analytics").subscribe((t) => {
      const graphName: string = this.config.requests[index];
      const containerLabel: string =
        this.containerTypes?.length > 1
          ? t.graphTitle.containers
          : `${t.graphTitle.container} ${this.containerTypes[0].code}`;

      graph.layout.title = `${t.navigator[graphName].label} ${containerLabel}`;
    });
  }

  /**
   * Update the size of the param graph
   * @param {any} graph - The selected graph
   */
  private resizeConfigLayout(graph: any): void {
    graph.layout.width = 0.97 * window.innerWidth;
    graph.layout.height =
      this.config.requests?.length >= 2
        ? 0.4 * window.innerHeight
        : 0.8 * window.innerHeight;
  }

  /**
   * Apply the param function on all graph in this.graphs
   * @param {callbackFunc} cb - The callback function called for all graphs
   */
  private applyOnAllGraphs(cb: (graph: any, index: number) => void): void {
    for (let i = 0; i < this.graphs.length; i++) {
      cb(this.graphs[i], i);
    }
  }

  /**
   * Format all datas for plotly graphs
   */
  private prepareGraphs(): void {
    this.resizeConfigLayout(this.config);

    this.graphs = this.datas.map((data, index) => {
      let graph = JSON.parse(JSON.stringify(this.config)); // deep copy of this.config

      graph.data = [];
      graph = this.analyticsService.formatGraphData(
        graph.requests[index],
        data,
        graph
      );

      return graph;
    });

    this.applyOnAllGraphs(this.updateGraphTitle.bind(this));
  }
}
