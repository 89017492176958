import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { UserDTO } from "../models/user";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * ContainerTypeController service call api rest for ContainerType - return ContainerTypeDTO
 */

@Injectable()
export class ExternalUserControllerService {
  constructor(public httpClientWrapper: HttpClientWrapper<UserDTO>) {}

  login() {
    try {
      return this.httpClientWrapper.getUnique(
        environment.apiBaseUrl + "users/login/psa",
        false
      );
    } catch (e) {
      return e.text;
    }
  }
}
