<div class="label-content">
  <div class="label-main">
    <div class="label-main-logo">
      <img
        class="label-main-logo-img"
        src="/assets/img/dashboard-{{ status.toLowerCase() }}.png"
      />
    </div>
    <div class="label-main-text">
      <div class="label-main-text-number">
        <p [ngStyle]="{ color: colorBackground }">
          {{ numberOfContainers }}
        </p>
      </div>
      <div class="label-main-text-label">
        {{ label }}
      </div>
    </div>
  </div>

  <div
    class="warning-status"
    ng-deep
    *ngIf="status.toLowerCase() === 'warning'"
  >
    <div class="subcontent-label">
      <div class="div-logo">
        <img
          class="sub-logo"
          src="/assets/img/dashboard-{{ status.toLowerCase() }}-detour.png"
        />
      </div>

      <div class="sub-total">
        <div class="sub-number">
          <p [ngStyle]="{ color: colorBackground }">{{ numberDetour }}</p>
        </div>
        <div class="sub-label">
          {{ "label.detour" | translate }}
          <!-- <img src="/assets/img"/> -->
        </div>
      </div>
    </div>
    <div class="subcontent-label">
      <div class="div-logo">
        <img
          class="sub-logo"
          src="/assets/img/dashboard-{{ status.toLowerCase() }}-static.png"
        />
      </div>

      <div class="sub-total">
        <div class="sub-number">
          <p [ngStyle]="{ color: colorBackground }">{{ numberStatic }}</p>
        </div>
        <div class="sub-label">
          {{ "label.static" | translate }}
          <!-- <img src="/assets/img"/> -->
        </div>
      </div>
    </div>

    <div class="subcontent-label">
      <div class="div-logo">
        <img
          class="sub-logo"
          src="/assets/img/dashboard-{{ status.toLowerCase() }}-late.png"
        />
      </div>

      <div class="sub-total">
        <div class="sub-number">
          <p [ngStyle]="{ color: colorBackground }">{{ numberLate }}</p>
        </div>
        <div class="sub-label">
          {{ "label.late" | translate }}
          <!-- <img src="/assets/img"/> -->
        </div>
      </div>
    </div>
  </div>
</div>
