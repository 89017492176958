import { ReflectiveInjector } from "@angular/core";
import { GeoUtils } from "../services/geo-utils";

const STATUS_VALUES = [
  "green",
  "blue",
  "orangeStatic",
  "orangeLate",
  "orangeDetour",
  "red",
  "grey"
];
const STATUS_DESC_VALUES = {
  green: "OK",
  blue: "IN TRANSIT",
  orangeStatic: "STATIC",
  orangeLate: "LATE",
  orangeDetour: "DETOUR",
  red: "ALERT",
  grey: "QUIET"
};
const FLAGS = {
  orangeStatic: "flagStatic",
  orangeLate: "flagLate",
  orangeDetour: "flagDetour"
};

export class Status {
  id: string;
  acquiredDate: Date;
  value: string;
  deviceId: string;
  containerId: string;
  deviceStateId: string;
  marker: string;
  description: string;
  color: string;

  geoUtils: GeoUtils;

  constructor(obj: any, flags: any) {
    switch (obj.marker.toLowerCase()) {
      case "green":
        if (!flags || (flags && flags.length == 0)) {
          this.marker = STATUS_VALUES[0];
          this.description = STATUS_DESC_VALUES[this.marker];
        } else {
          this.marker = STATUS_VALUES[2];
          this.description = STATUS_DESC_VALUES[this.marker] + " ( static )";
        }
        break;
      case "blue":
        if (!flags || (flags && flags.length == 0)) {
          this.marker = STATUS_VALUES[1];
          this.description = STATUS_DESC_VALUES[this.marker];
        } else {
          this.marker = STATUS_VALUES[3];
          this.description =
            STATUS_DESC_VALUES[this.marker] + " ( " + flags.join(", ") + " )";
        }
        break;
      case "red":
        this.marker = STATUS_VALUES[2];
        this.description = STATUS_DESC_VALUES[this.marker];
        break;
      case "grey":
        this.marker = STATUS_VALUES[6];
        this.description = STATUS_DESC_VALUES[this.marker];
        break;
    }

    // Here's a cool way of injecting outside the constructor
    this.geoUtils = ReflectiveInjector.resolveAndCreate([GeoUtils]).get(
      GeoUtils
    );
    this.color = this.geoUtils.getMarkerColor(this.marker);
  }

  static GetStatusList(): any {
    let statusList = JSON.parse(localStorage.getItem("statusFilter"));
    if (!statusList) {
      statusList = [];
      // eslint-disable-next-line guard-for-in

      for (const status of STATUS_VALUES) {
        if (status.includes("orange")) {
          statusList.push({
            marker: status,
            show: true,
            showDetail: true,
            flag: FLAGS[status],
            activeIcon: `/assets/img/pin/${STATUS_DESC_VALUES[
              status
            ].toLowerCase()}.svg`,
            title: STATUS_DESC_VALUES[status]
          });
        } else {
          statusList.push({
            marker: status,
            show: true,
            showDetail: true,
            activeIcon: `/assets/img/pin/${status}.svg`,
            title: STATUS_DESC_VALUES[status]
          });
        }
      }
    }
    return statusList;
  }
}
