import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import {
  BLUE,
  GREEN,
  GREY,
  LIGHTGREEN,
  ORANGE,
  RED,
  YELLOW
} from "../../../services/geo-utils";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { userAccess } from "../../../services/user-rights.service";

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"]
})
export class ChartComponent implements OnInit, OnChanges, OnDestroy {
  private subscription: Subscription = new Subscription();

  @Input() green: number;
  @Input() blue: number;
  @Input() orange: number;
  @Input() red: number;
  @Input() grey: number;
  @Input() yellow: number;
  @Input() lightGreen: number;

  @Input() kpiBattery: boolean;

  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  colorsActive: string[] = [
    GREEN.replace(/o/, "1"),
    BLUE.replace(/o/, "1"),
    ORANGE.replace(/o/, "1"),
    RED.replace(/o/, "1"),
    GREY.replace(/o/, "1")
  ];

  labels = {
    labelsStatus: [
      "OK (active)",
      "In-Traffic (active)",
      "Warning (active)",
      "Alert (active)",
      "Quiet"
    ],
    labelsBattery: [
      "EXCELLENT (active)",
      "GOOD (active)",
      "OK (active)",
      "ALMOST EMPTY (active)",
      "Quiet"
    ],
    labelActive: "Total (active)"
  };
  labelsColor: string[] = ["green", "blue", "orange", "red", "grey"];

  datasetActive: any[] = [];
  optionsActive: any;

  currentLabelActive: string;
  currentValueActive: number;

  colorsQuiet: string[] = [
    GREEN.replace(/o/, "1"),
    BLUE.replace(/o/, "1"),
    ORANGE.replace(/o/, "1"),
    RED.replace(/o/, "1")
  ];

  labelsQuiet: string[] = [
    "OK (quiet)",
    "In-Traffic (quiet)",
    "Warning (quiet)",
    "Alert (quiet)"
  ];

  datasetQuiet: any[] = [];
  optionsQuiet: any;

  currentLabelQuiet: string;
  currentValueQuiet: number;

  constructor(private translate: TranslateService) {
    this.subscription.add(
      translate.stream("chart.labels").subscribe((labels) => {
        this.labels = labels;
        this.currentLabelActive = labels.labelActive;
      })
    );
  }

  ngOnInit() {
    if (this.kpiBattery) {
      this.blue = 0;
      this.orange = 0;
      this.grey = 0;

      this.colorsActive = [
        GREEN.replace(/o/, "1"),
        LIGHTGREEN.replace(/o/, "1"),
        YELLOW.replace(/o/, "1"),
        RED.replace(/o/, "1"),
        GREY.replace(/o/, "1")
      ];

      this.labelsColor = ["green", "lightgreen", "yellow", "red", "grey"];

      this.colorsQuiet = [
        GREEN.replace(/o/, "1"),
        LIGHTGREEN.replace(/o/, "1"),
        YELLOW.replace(/o/, "1"),
        RED.replace(/o/, "1"),
        GREY.replace(/o/, "1")
      ];

      this.labelsQuiet = [
        "EXCELLENT (quiet)",
        "GOOD (quiet), OK (quiet)",
        "ALMOST EMPTY (quiet)"
      ];
    } else {
      this.yellow = 0;
      this.lightGreen = 0;
    }
    this.optionsActive = {
      legend: { display: false },
      animation: {
        animateScale: true
      },
      hover: {
        onHover: (event, active) => {
          if (event.type === "mouseout") {
            this.resetActiveLabel();
            return;
          }
          if (!active[0]) {
            return;
          }
          const index = active[0]._index;
          this.updateActiveValues(index);
        }
      },
      onClick: (event, active) => {
        if (event.type === "click") {
          if (!active[0]) {
            return;
          }
          const index = active[0]._index;
          this.navigateToList(index);
        }
      },
      cutoutPercentage: 80,
      rotation: 2.5 * Math.PI,
      plugins: {
        datalabels: {
          display: false
        }
      }
    };

    this.optionsQuiet = {
      legend: { display: false },
      animation: {
        animateScale: true
      },
      cutoutPercentage: 80,
      hover: {
        onHover: (event, active) => {
          if (event.type === "mouseout") {
            this.resetQuietLabel();
            return;
          }
          if (!active[0]) {
            return;
          }
          const index = active[0]._index;
          this.updateQuietValues(index);
        }
      },
      onClick: (event, active) => {
        if (event.type === "click") {
          if (!active[0]) {
            return;
          }
          const index = active[0]._index;
          this.navigateToList(index);
        }
      },
      rotation: 2.5 * Math.PI
    };

    this.refreshDatasetActive();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  navigateToList(index: number) {
    this.changeTab.emit({
      tab: userAccess.LIST,
      status: this.labelsColor[index]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.green ||
      (!this.kpiBattery && changes.blue) ||
      changes.orange ||
      changes.red ||
      changes.grey ||
      changes.quiet ||
      (this.kpiBattery && changes.yellow)
    ) {
      this.refreshDatasetActive();
    }
  }

  public updateActiveValues(index: number) {
    this.currentLabelActive = this.kpiBattery
      ? this.labels.labelsBattery[index]
      : this.labels.labelsStatus[index];
    this.currentValueActive = this.datasetActive[0].data[index];
  }

  public updateQuietValues(index: number) {
    this.currentLabelQuiet = this.labelsQuiet[index];
    this.currentValueQuiet = this.datasetQuiet[0].data[index];
  }

  public resetActiveLabel() {
    this.currentLabelActive = this.labels.labelActive;
    let total = 0;
    // eslint-disable-next-line guard-for-in
    for (const i in this.datasetActive[0].data) {
      total += this.datasetActive[0].data[i];
    }
    this.currentValueActive = total;
  }

  public resetQuietLabel() {
    this.currentLabelQuiet = "Total (quiet)";
    let total = 0;
    // eslint-disable-next-line guard-for-in
    for (const i in this.datasetQuiet[0].data) {
      total += this.datasetQuiet[0].data[i];
    }
    this.currentValueQuiet = total;
  }

  refreshDatasetActive() {
    let data;
    if (this.kpiBattery) {
      data = [this.green, this.lightGreen, this.yellow, this.red, this.grey];
    } else {
      data = [this.green, this.blue, this.orange, this.red, this.grey];
    }
    this.datasetActive = [
      {
        data: data,
        backgroundColor: this.colorsActive,
        hoverBorderColor: this.colorsActive,
        hoverBackgroundColor: this.colorsActive,
        hoverBorderWidth: 20
      }
    ];
    this.resetActiveLabel();
  }
}
