<span
  [ngClass]="{
    'status-dot': true,
    green: true,
    'status-dot-active': statusActive.green
  }"
></span>
<span
  [ngClass]="{
    'status-dot': true,
    blue: true,
    'status-dot-active': statusActive.blue
  }"
></span>
<span
  [ngClass]="{
    'status-dot': true,
    orange: true,
    'status-dot-active': statusActive.orange
  }"
></span>
<span
  [ngClass]="{
    'status-dot': true,
    red: true,
    'status-dot-active': statusActive.red
  }"
></span>
<span
  [ngClass]="{
    'status-dot': true,
    grey: true,
    'status-dot-active': statusActive.grey
  }"
></span>
