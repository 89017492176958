import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import * as bcrypt from "../../../../../../node_modules/bcryptjs";
import { RightContainerTypesDTO } from "../../../../models/right-container-types";
import { UserCreationDTO, UserDTO } from "../../../../models/user";
import { ProfileContainerTypeControllerService } from "../../../../services/profile-container-type-controller.service";
import { RightContainerTypeControllerService } from "../../../../services/right-container-types-controller.service";
import { RightControllerService } from "../../../../services/right-controller.service";
import { UserControllerService } from "../../../../services/user-controller.service";
import { SimpleDialogComponent } from "../../../dialog/simple-dialog.component";

@Component({
  selector: "app-user-manager",
  templateUrl: "./user-manager.component.html",
  styleUrls: ["./user-manager.component.scss"]
})
export class UserManagerComponent implements OnInit, OnDestroy {
  private readonly SELECTOR_STYLE_OPEN = "nav-selector-open";
  private readonly SELECTOR_STYLE_CLOSE = "nav-selector-close";
  private readonly CREATE_USER = "user";
  private readonly UPDATE_USER = "update";
  private readonly DELETE_LOGO = "../../../../../assets/img/manager/delete.svg";
  private readonly PASSWORD_MOCKER = "/passwordMocker/";

  private subscription: Subscription = new Subscription();

  isAddUser: boolean = false;
  selectedNav: string = "User";
  newUserIsValid: boolean = false;
  mobileRegex: RegExp = /mobile/i;

  formConfig: any = {
    name: "User",
    title: "User",
    type: UserDTO,
    inputs: [
      {
        property: "name",
        title: "name",
        type: "text",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      },
      {
        property: "email",
        title: "email",
        type: "mail",
        input: true,
        mandatory: true,
        visible: true
      },
      {
        property: "password",
        title: "pwd",
        type: "password",
        input: true,
        mandatory: true,
        condition: {
          property: "email",
          regex:
            /mpsa|opel|citroen|dsautomobiles|eurorepar|pcmarus|psa|peugeot|shiftup|vauxhall|stellantis/
        },
        visible: false
      },
      {
        property: "rightId",
        title: "role",
        type: "string",
        selector: true,
        choices: [],
        mandatory: false,
        visible: true,
        validityConditions: [
          {
            type: "inputValue",
            property: "rightMobileApp",
            values: ["None"]
          }
        ]
      },
      {
        property: "rightMobileApp",
        title: "MobileApp",
        type: "string",
        selector: true,
        choices: [],
        mandatory: false,
        visible: true
      },
      {
        property: "profileContainerTypeId",
        title: "group",
        type: "string",
        selector: true,
        choices: [],
        mandatory: false,
        visible: true
      }
    ]
  };

  navObject: any[] = [
    {
      navName: "User",
      isOpen: true,
      selectorStyle: this.SELECTOR_STYLE_OPEN
    },
    {
      navName: "authorizedCt",
      isOpen: false,
      selectorStyle: this.SELECTOR_STYLE_CLOSE
    }
  ];

  userProperties: any[] = [
    {
      label: "name",
      property: "name",
      canBeFiltered: true,
      width: 10
    },
    {
      label: "email",
      property: "email",
      canBeFiltered: true,
      width: 20
    },
    {
      label: "roles",
      property: "rightId",
      canBeFiltered: true,
      width: 10
    },
    {
      label: "group",
      property: "profileContainerTypeId",
      canBeFiltered: true,
      width: 10
    }
  ];

  authCtProperties: any[] = [
    {
      label: "",
      property: "isAuthorized",
      type: "boolean",
      width: 4
    },
    {
      label: "code",
      property: "code",
      canBeFiltered: true,
      width: 8
    },
    {
      label: "name",
      property: "label",
      canBeFiltered: true,
      width: 20
    },
    {
      label: "type",
      property: "category",
      canBeFiltered: true,
      width: 10
    }
  ];

  mailExistsLabel: string;
  nameExistsLabel: string;

  selectedUser: Partial<UserDTO> = {};
  selectedRightContainerTypes: any = {};

  users: UserDTO[];
  numberOfAllUsers: number = 0;
  requestUserId: number = 0;
  filterUserChanged: boolean = false;
  filterUser: any = undefined;
  userRequestParameter: any = {
    skip: 0,
    limit: 25,
    filter: {}
  };

  numberOfAllAuthorizedCTs: number = 4;
  authorizedCTs: any = [];
  requestAuthCtId: number = 0;
  filterAuthCtChanged: boolean = false;
  filterAuthCt: any = undefined;
  authCtRequestParameter: any = {
    skip: 0,
    limit: 25,
    filter: {}
  };

  updateMode: boolean = false;
  updateModeLabel: string = this.UPDATE_USER;

  constructor(
    private userControllerService: UserControllerService,
    private rightContainerTypeControllerService: RightContainerTypeControllerService,
    private profileContainerTypeControllerService: ProfileContainerTypeControllerService,
    private rightControllerService: RightControllerService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.subscription.add(
      translate.stream("manager.errors").subscribe((labels) => {
        if (this.formConfig.inputs[0].errorMsg === this.nameExistsLabel)
          this.formConfig.inputs[0].errorMsg = labels.nameExists;
        if (this.formConfig.inputs[1].errorMsg === this.mailExistsLabel)
          this.formConfig.inputs[1].errorMsg = labels.mailExists;
        this.mailExistsLabel = labels.mailExists;
        this.nameExistsLabel = labels.nameExists;
      })
    );
  }

  ngOnInit() {
    this.sendUserRequest();
    this.sendUserCount();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addUser(_event) {
    this.selectedUser = {};
    for (const input of this.formConfig.inputs) {
      if (input.property === "rightId" || input.property === "rightMobileApp") {
        input.value = "None";
      } else {
        input.value = undefined;
      }
      input.valid = undefined;
    }
    this.updateModeLabel = this.CREATE_USER;
    this.updateMode = false;
    this.openModifier();
  }

  changeFilterUser(event) {
    if (event === undefined) {
      this.filterUser = undefined;
    } else {
      if (event.search !== undefined) {
        const filter: any = { or: [] };
        for (const prop of this.userProperties) {
          if (prop.canBeFiltered) {
            const object: any = {};
            object[prop.property] = event.search;
            filter.or.push(object);
          }
        }
        this.filterUser = filter;
      } else {
        this.filterUser = { and: [event] };
      }
    }
    this.userRequestParameter.skip = 0;
    this.updateUserList(true);
  }

  changePageUser(event) {
    this.userRequestParameter.limit = event.limit;
    this.userRequestParameter.skip = (event.page - 1) * event.limit;
    this.updateUserList();
  }

  selectUser(event: UserDTO) {
    if (event.profileContainerTypeId === "Custom")
      event.profileContainerTypeId = undefined;

    this.selectedUser = { ...event };
    for (const input of this.formConfig.inputs) {
      input.value = this.selectedUser[input.property];
      input.valid = true;
      if (input.property === "password") {
        if (!input.condition.regex.test(this.formConfig.inputs[1].value)) {
          input.value = this.PASSWORD_MOCKER;
          this.selectedUser[input.property] = this.PASSWORD_MOCKER;
        }
      } else if (input.property === "rightId") {
        const rightId: string = this.selectedUser.rightId.split("/")[0];

        input.value = this.mobileRegex.test(rightId) ? "None" : rightId;
      } else if (input.property === "rightMobileApp") {
        if (this.selectedUser.rightId.includes("/")) {
          input.value = this.selectedUser.rightId.split("/")[1];
        } else {
          const rightId: string = this.selectedUser.rightId;

          input.value = this.mobileRegex.test(rightId) ? rightId : "None";
        }
      }
    }
    this.updateModeLabel = this.UPDATE_USER;
    this.updateMode = true;
    this.openModifier();
  }

  isFormValid(event: boolean) {
    this.newUserIsValid = event;
  }

  saveChanges() {
    if (this.updateMode) {
      this.sendUpdateUser(this.generateUserCreation());
    } else {
      this.sendCreateUser(this.generateUserCreation());
    }
  }

  deleteUser() {
    const dialogRef = this.dialog.open(SimpleDialogComponent, {
      data: {
        title: `Delete ${this.selectedUser.name}`,
        text: `Do you want to delete this user permanently?`,
        yes: "Yes",
        no: "No"
      }
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.sendDeleteUser();
          this.backToList();
          this.updateUserList(true);
        }
      })
    );
  }

  changeSelectedUser(event: UserDTO) {
    if (
      this.selectedUser.profileContainerTypeId !== event.profileContainerTypeId
    ) {
      this.selectedUser = { ...event };
      this.selectedUser.profileContainerTypeId =
        this.selectedUser.profileContainerTypeId === "Custom"
          ? undefined
          : this.selectedUser.profileContainerTypeId;
      this.getAuthCt();
      this.updateAuthCtList(true);
    } else {
      this.selectedUser = { ...event };
    }
  }

  updateUserList(isFilterChanged?: boolean) {
    this.filterUserChanged = isFilterChanged ? true : this.filterUserChanged;
    this.requestUserId++;
    const id: number = this.requestUserId;
    setTimeout(() => {
      if (this.requestUserId === id) {
        this.sendUserRequest();
        if (this.filterUserChanged) {
          this.sendUserCount();
        }
      }
    }, 1000);
  }

  openModifier() {
    this.getAuthCt();
    this.sendRightRequest();
    this.sendProfileRequest();
    this.sendAuthCtRequest();
    this.sendAuthCtCount();
    this.isAddUser = true;
  }

  private rightIsDefined(right: string): boolean {
    return right !== undefined && right !== "None";
  }

  generateUserCreation(): UserCreationDTO {
    const salt = bcrypt.genSaltSync(10);
    const password: string | undefined = this.selectedUser.password
      ? this.selectedUser.password === this.PASSWORD_MOCKER
        ? this.selectedUser.password
        : bcrypt.hashSync(this.selectedUser.password, salt)
      : "string";

    let rightId: string = this.selectedUser.rightId;
    if (
      this.rightIsDefined(this.selectedUser.rightId) &&
      this.rightIsDefined(this.selectedUser.rightMobileApp)
    ) {
      if (
        this.selectedUser.rightId !== "Admin" &&
        this.selectedUser.rightMobileApp !== "Admin"
      ) {
        rightId = `${this.selectedUser.rightId}/${this.selectedUser.rightMobileApp}`;
      }
    } else if (this.rightIsDefined(this.selectedUser.rightMobileApp)) {
      rightId = this.selectedUser.rightMobileApp;
    }

    const user: UserDTO = {
      name: this.selectedUser.name,
      email: this.selectedUser.email,
      rightId: rightId,
      password: password,
      profileContainerTypeId: this.selectedUser.profileContainerTypeId
        ? this.selectedUser.profileContainerTypeId
        : undefined
    };
    const rightContainerTypes: RightContainerTypesDTO[] = [];
    for (const rightCt in this.selectedRightContainerTypes) {
      if (this.selectedRightContainerTypes[rightCt])
        rightContainerTypes.push(this.selectedRightContainerTypes[rightCt]);
    }
    const userCreation: UserCreationDTO = {
      user: user,
      rightContainerTypes: rightContainerTypes
    };
    return userCreation;
  }

  sendUserRequest() {
    this.subscription.add(
      this.userControllerService
        .getUsers(
          this.userRequestParameter.skip,
          this.userRequestParameter.limit,
          this.filterUser
        )
        .subscribe((result) => {
          this.users = result;
        })
    );
  }

  sendUserCount() {
    this.subscription.add(
      this.userControllerService
        .getCount(this.filterUser)
        .subscribe((result) => {
          this.numberOfAllUsers = result.count;
        })
    );
  }

  sendDeleteUser() {
    this.subscription.add(
      this.userControllerService
        .deleteUser(this.selectedUser.name)
        .subscribe((_result) => {})
    );
  }

  sendUpdateUser(userCreation: UserCreationDTO) {
    if (userCreation.user.rightId === "None") {
      this.formConfig.inputs[3].valid = false;
      return;
    }
    if (userCreation.rightContainerTypes.length === 0) {
      this.formConfig.inputs[5].valid = false;
      return;
    }

    this.subscription.add(
      this.userControllerService.patchUser(userCreation).subscribe(() => {
        this.backToList();
        this.updateUserList(true);
      })
    );
  }

  verifyUser(err: string) {
    if (err.includes("name")) {
      this.formConfig.inputs[0].valid = false;
      this.formConfig.inputs[0].errorMsg = this.nameExistsLabel;
    }
    if (err.includes("mail")) {
      this.formConfig.inputs[1].valid = false;
      this.formConfig.inputs[1].errorMsg = this.mailExistsLabel;
    }
  }

  sendCreateUser(userCreation: UserCreationDTO) {
    if (userCreation.user.rightId === "None") {
      this.formConfig.inputs[3].valid = false;
      return;
    }
    if (userCreation.rightContainerTypes.length === 0) {
      this.formConfig.inputs[5].valid = false;
      return;
    }
    this.subscription.add(
      this.userControllerService.createUser(userCreation).subscribe(
        () => {
          this.backToList();
          this.updateUserList(true);
        },
        (err) => {
          if (err.status === 409) {
            this.verifyUser(err.error.error.message);
            this.isFormValid(false);
          } else {
            this.backToList();
            this.updateUserList(true);
          }
        }
      )
    );
  }

  sendProfileRequest() {
    this.subscription.add(
      this.profileContainerTypeControllerService
        .getProfiles()
        .subscribe((result) => {
          this.formConfig.inputs[5].choices = result.map((profile) => {
            return profile.profileContainerTypeId;
          });
          this.formConfig.inputs[5].choices.unshift("None");
          if (
            this.selectedUser.id &&
            !this.selectedUser.profileContainerTypeId
          ) {
            this.formConfig.inputs[5].value = "Custom";
            this.formConfig = { ...this.formConfig };
          }
        })
    );
  }

  sendRightRequest() {
    this.subscription.add(
      this.rightControllerService.getRights().subscribe((result) => {
        this.formConfig.inputs[3].choices = ["None"];
        this.formConfig.inputs[4].choices = ["None"];

        result.forEach((right) => {
          this.formConfig.inputs[
            this.mobileRegex.test(right.rightId) ? 4 : 3
          ].choices.push(right.rightId);
        });
        if (this.formConfig.inputs[4]) {
          this.formConfig.inputs[4].choices.push("Admin");
        }
      })
    );
  }

  addAuthorizedCT(event) {
    console.log("addAuthorizedCT", event);
  }

  changeFilterAuthorizedCT(event) {
    if (event === undefined) {
      this.filterAuthCt = undefined;
    } else {
      if (event.search !== undefined) {
        const filter: any = { or: [] };
        for (const prop of this.authCtProperties) {
          if (prop.canBeFiltered) {
            const object: any = {};
            object[prop.property] = event.search;
            filter.or.push(object);
          }
        }
        this.filterAuthCt = filter;
      } else {
        this.filterAuthCt = { and: [event] };
      }
    }
    this.authCtRequestParameter.skip = 0;
    this.updateAuthCtList(true);
  }

  changePageAuthorizedCT(event) {
    this.authCtRequestParameter.limit = event.limit;
    this.authCtRequestParameter.skip = (event.page - 1) * event.limit;
    this.updateAuthCtList();
  }

  selectAuthorizedCT(event: any) {
    event.isAuthorized = !event.isAuthorized;
    if (event.isAuthorized) {
      this.selectedRightContainerTypes[event.code] = {
        userId: this.selectedUser.name,
        containerTypeId: event.code
      };
    } else {
      this.selectedRightContainerTypes[event.code] = undefined;
    }
    if (!this.selectedUser.id || this.selectedUser.profileContainerTypeId) {
      this.selectedUser.profileContainerTypeId = undefined;
      this.formConfig.inputs[5].value = "Custom";
      this.formConfig = { ...this.formConfig };
    }
    this.formConfig.inputs[5].valid = true;
  }

  createAuthorizedCT(event: any) {
    console.log("createAuthorizedCT", event);
  }

  updateAuthCtList(isFilterChanged?: boolean) {
    this.filterAuthCtChanged = isFilterChanged
      ? true
      : this.filterAuthCtChanged;
    this.requestAuthCtId++;
    const id: number = this.requestAuthCtId;
    setTimeout(() => {
      if (this.requestAuthCtId === id) {
        this.sendAuthCtRequest();
        if (this.filterAuthCtChanged) {
          this.sendAuthCtCount();
        }
      }
    }, 1000);
  }

  sendAuthCtRequest() {
    this.subscription.add(
      this.rightContainerTypeControllerService
        .getContainerTypesAuthorized(
          this.selectedUser.profileContainerTypeId
            ? this.selectedUser.profileContainerTypeId
            : this.selectedUser.name,
          this.authCtRequestParameter.skip,
          this.authCtRequestParameter.limit,
          this.filterAuthCt
        )
        .subscribe((result) => {
          this.authorizedCTs = result.map((authCt) => {
            authCt.isAuthorized = this.selectedRightContainerTypes[authCt.code]
              ? true
              : false;
            return authCt;
          });
        })
    );
  }

  sendAuthCtCount() {
    this.subscription.add(
      this.rightContainerTypeControllerService
        .getContainerTypesAuthorizedCount(this.filterAuthCt)
        .subscribe((result) => {
          this.numberOfAllAuthorizedCTs = result.count;
        })
    );
  }

  getAuthCt() {
    this.subscription.add(
      this.rightContainerTypeControllerService
        .getContainerTypesAuthorizedOnly(
          this.selectedUser.profileContainerTypeId
            ? this.selectedUser.profileContainerTypeId
            : this.selectedUser.name
        )
        .subscribe((result) => {
          this.selectedRightContainerTypes = {};
          for (const cT of result) {
            this.selectedRightContainerTypes[cT.code] = {
              containerTypeId: cT.code,
              userId: this.selectedUser.name
            };
          }
          this.authorizedCTs = this.authorizedCTs.map((authCt) => {
            authCt.isAuthorized = this.selectedRightContainerTypes[authCt.code]
              ? true
              : false;
            return authCt;
          });
        })
    );
  }

  onNavChange(event: any) {
    for (const nav of this.navObject) {
      nav.isOpen = false;
      nav.selectorStyle = this.SELECTOR_STYLE_CLOSE;
    }
    event.isOpen = true;
    event.selectorStyle = this.SELECTOR_STYLE_OPEN;
    this.selectedNav = event.navName;
  }

  backToList() {
    this.isAddUser = false;
    this.onNavChange(this.navObject[0]);
  }
}
