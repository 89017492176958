import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { WsData } from "../../../../../models";
import { ReferenceNameDTO } from "../../../../../models/reference-name";
import { ReferenceSummary } from "../../../../../models/reference-summary";
import { ReferenceNameControllerService } from "../../../../../services/reference-name-controller.service";
import { WebSocketService } from "../../../../../services/web-socket.service";
import { SimpleDialogComponent } from "../../../../dialog/simple-dialog.component";

@Component({
  selector: "app-reference-type",
  templateUrl: "./reference-type.component.html",
  styleUrls: ["./reference-type.component.scss"]
})
export class ReferenceTypeComponent implements OnInit, OnDestroy {
  @Input() service: any;
  @Input() formConfig: any;
  @Input() properties: any[];

  private readonly CREATE_OBJECT = "element";
  private readonly UPDATE_OBJECT = "update";
  private readonly DELETE_LOGO = "../../../../../assets/img/manager/delete.svg";

  private subscription: Subscription = new Subscription();
  private wsSubscription: Subscription = new Subscription();
  private readonly NAMESPACE: string = "reference";
  private readonly MATCHING_TYPE: any = {
    "Container Type": "Containertype",
    Site: "Site",
    Route: "Route",
    "Mac Address": "MacAddress"
  };

  isAddObject: boolean = false;
  selectedNav: string = "Object Information";
  newObjectIsValid: boolean = false;

  type: any;

  selectedObject: any = {};
  selectedRightContainerTypes: any = {};

  objects: any[];
  numberOfAllObjects: number = 0;
  requestObjectId: number = 0;
  filterObjectChanged: boolean = false;
  filterObject: any = undefined;
  objectRequestParameter: any = {
    skip: 0,
    limit: 25,
    filter: {}
  };

  updateMode: boolean = false;
  updateModeLabel: string = this.UPDATE_OBJECT;
  uploaderType: string = "reference";
  uploadDatas: string[][];
  columnNames: string[];
  fileName: string;
  rawFile: string;
  lastUpdateLabel: string = "Last update";
  lastUpload: string = this.lastUpdateLabel;

  referenceSummary: ReferenceSummary;
  separatorError: boolean = false;
  separatorErrorWarnings: string[] = [];

  provisioningProperties: any[];

  percentage: number | undefined;
  isUploaded: boolean = false;

  uploadMode: "Replace" | "Add" = "Replace";

  constructor(
    public dialog: MatDialog,
    private referenceNameControllerService: ReferenceNameControllerService,
    private webSocketService: WebSocketService,
    private translate: TranslateService
  ) {
    this.subscription.add(
      translate.stream("misc.lastUpdate").subscribe((label) => {
        if (this.lastUpload === this.lastUpdateLabel) this.lastUpload = label;
        else this.lastUpload.replace(this.lastUpdateLabel, label);
        this.lastUpdateLabel = label;
      })
    );
  }

  ngOnInit() {
    this.type = this.formConfig.type;
    this.sendObjectRequest();
    this.sendObjectCount();
    this.getLastFile(this.formConfig.name.replace(" ", ""));
    this.provisioningProperties = this.service.getProvisioningProperties();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.wsSubscription.unsubscribe();
    this.webSocketService.disconnect(this.NAMESPACE);
  }

  addObject(_event) {
    this.selectedObject = {};
    for (const input of this.formConfig.inputs) {
      input.value = undefined;
      input.valid = undefined;
    }
    this.updateModeLabel = this.CREATE_OBJECT;
    this.updateMode = false;
    this.openModifier();
  }

  changeFilterObject(event) {
    if (event === undefined) {
      this.filterObject = undefined;
    } else {
      if (event.search !== undefined) {
        const filter: any = { or: [] };
        for (const prop of this.properties) {
          if (prop.canBeFiltered) {
            const object: any = {};
            object[prop.property] = event.search;
            filter.or.push(object);
          }
        }
        this.filterObject = filter;
      } else {
        this.filterObject = { and: [event] };
      }
    }
    this.objectRequestParameter.skip = 0;
    this.updateObjectList(true);
  }

  changePageObject(event) {
    this.objectRequestParameter.limit = event.limit;
    this.objectRequestParameter.skip = (event.page - 1) * event.limit;
    this.updateObjectList();
  }

  selectObject(event: any) {
    this.selectedObject = { ...event };
    for (const input of this.formConfig.inputs) {
      input.value = this.selectedObject[input.property];
      input.valid = true;
    }
    this.updateModeLabel = this.UPDATE_OBJECT;
    this.updateMode = true;
    this.openModifier();
  }

  isFormValid(event: boolean) {
    this.newObjectIsValid = event;
  }

  saveChanges() {
    if (this.updateMode) {
      this.sendUpdateObject(this.generateObjectCreation());
    } else {
      this.sendCreateObject(this.generateObjectCreation());
    }
  }

  deleteObject() {
    const label: string = this.selectedObject?.code
      ? this.selectedObject.code
      : this.selectedObject.containerTypeId;
    const dialogRef = this.dialog.open(SimpleDialogComponent, {
      data: {
        title: `Delete ${label}`,
        text: `Do you want to delete this object permanently?`,
        yes: "Yes",
        no: "No"
      }
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.sendDeleteObject();
          this.backToList();
          this.updateObjectList(true);
        }
      })
    );
  }

  changeSelectedObject(event: any) {
    if (
      this.selectedObject.profileContainerTypeId !==
      event.profileContainerTypeId
    ) {
      this.selectedObject = { ...event };
      this.selectedObject.profileContainerTypeId =
        this.selectedObject.profileContainerTypeId === "Custom"
          ? undefined
          : this.selectedObject.profileContainerTypeId;
    } else {
      this.selectedObject = { ...event };
    }
  }

  updateObjectList(isFilterChanged?: boolean) {
    this.filterObjectChanged = isFilterChanged
      ? true
      : this.filterObjectChanged;
    this.requestObjectId++;
    const id: number = this.requestObjectId;
    setTimeout(() => {
      if (this.requestObjectId === id) {
        this.sendObjectRequest();
        if (this.filterObjectChanged) {
          this.sendObjectCount();
        }
      }
    }, 1000);
  }

  openModifier() {
    this.isAddObject = true;
  }

  generateObjectCreation(): any {
    const objectCreation = { ...this.selectedObject };
    return objectCreation;
  }

  sendObjectRequest() {
    this.subscription.add(
      this.service
        .get(
          this.objectRequestParameter.skip,
          this.objectRequestParameter.limit,
          this.filterObject
        )
        .subscribe((result) => {
          this.service.disableElements(result);
          this.objects = result;
        })
    );
  }

  sendObjectCount() {
    this.subscription.add(
      this.service.getCount(this.filterObject).subscribe((result) => {
        this.numberOfAllObjects = result.count;
      })
    );
  }

  sendDeleteObject() {
    this.subscription.add(
      this.service.delete(this.selectedObject).subscribe((_result) => {})
    );
  }

  sendUpdateObject(objectCreation: any) {
    this.subscription.add(
      this.service.patch(objectCreation).subscribe((_result) => {})
    );
    this.backToList();
    this.updateObjectList(true);
  }

  sendCreateObject(objectCreation: any) {
    this.subscription.add(
      this.service.create(objectCreation).subscribe(
        (_result) => {
          this.backToList();
          this.updateObjectList(true);
        },
        (err) => {
          if (err.status === 500) {
            this.formConfig.inputs[0].valid = false;
          }
        }
      )
    );
  }

  async doProvisioning(objects: any) {
    try {
      const wsReferenceData: WsData = {
        messageType: "Init",
        referenceType: this.MATCHING_TYPE[this.formConfig.name],
        datas: objects.datas,
        userName: "",
        uploadMode: this.uploadMode
      };
      await this.webSocketService.connect(this.NAMESPACE);
      await this.referenceNameControllerService.setIsLoading(true);
      this.wsSubscription = this.webSocketService
        .getNewMessage(this.NAMESPACE)
        .subscribe((result) => {
          if (result && result !== "") {
            const wsReferenceDataResponses = JSON.parse(result);
            this.percentage = wsReferenceDataResponses.loading;
            if (
              (wsReferenceDataResponses.messageType === "Finish" ||
                wsReferenceDataResponses.messageType === "Error") &&
              wsReferenceDataResponses.referenceSummary
            ) {
              this.isUploaded = true;
              this.referenceNameControllerService.setIsLoading(false);
              const referenceSummary: ReferenceSummary =
                wsReferenceDataResponses.referenceSummary;
              if (objects.warnings) {
                for (const warning of objects.warnings) {
                  referenceSummary.warnings.unshift(warning);
                }
              }
              this.referenceSummary = referenceSummary;
              this.webSocketService.disconnect(this.NAMESPACE);
              this.wsSubscription.unsubscribe();
              this.formatFiles();
            }
          }
        });
      await this.webSocketService.sendMessage(wsReferenceData, this.NAMESPACE);
    } catch (e) {
      console.log(e);
    }
  }

  saveFile(referenceName: ReferenceNameDTO, text: string) {
    this.subscription.add(
      this.referenceNameControllerService
        .createReferenceFile(referenceName, text, this.uploadMode)
        .subscribe((_result) => {})
    );
  }

  getLastFile(referenceType) {
    this.subscription.add(
      this.referenceNameControllerService
        .getLastRef(referenceType)
        .subscribe((result) => {
          if (result) {
            const id: string = result.referenceNameId;
            const datas: string[] = id.split(":");
            const date: Date = new Date(Number(datas[datas.length - 1]));
            this.lastUpload = `${this.lastUpdateLabel} ${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()} by ${datas[1]}`;
          }
        })
    );
  }

  backToList() {
    this.isAddObject = false;
  }

  loadFile(file: File) {
    if (file) {
      let reader: FileReader = new FileReader();
      this.uploadDatas = [];
      this.isUploaded = true;
      reader.readAsText(file);
      reader.onload = (_e) => {
        let csv: string = reader.result as string;
        this.rawFile = csv;
        this.fileName = file.name;
        this.parseCSVData(csv);
      };
    }
  }

  parseCSVData(csv: string) {
    let separator: string;
    let dataLines: string[] = csv.split("\r\n");
    this.separatorError = false;
    this.separatorErrorWarnings = [];
    let dataLinesError: Array<[number, string]> = [];
    dataLines = dataLines.length === 1 ? csv.split("\n") : dataLines;
    const columnNamesColon: string[] = dataLines[0].split(",");
    const columnNamesSemiColon: string[] = dataLines[0].split(";");
    separator =
      columnNamesSemiColon.length >= columnNamesColon.length ? ";" : ",";
    this.columnNames = dataLines[0].split(separator);
    dataLines.shift();
    let uploadDatas: string[][] = [];
    let props: string[];
    let count = 1; // row with column names deleted
    for (const dataLine of dataLines) {
      count += 1;
      if (dataLine.length > this.properties.length * 2) {
        props = dataLine.split(separator);
        if (props.length !== this.columnNames.length) {
          this.separatorError = true;
          dataLinesError.push([count, dataLine]);
        }
        uploadDatas.push(props);
      }
    }
    if (this.separatorError) {
      this.separatorErrorWarnings.push(
        `Separator isn't adequate for the file :`
      );
      this.separatorErrorWarnings.push(
        ` • The separator "${separator}" in the file isn't only used to separate columns. This could lead to some errors while uploading datas.`
      );
      separator === ","
        ? this.separatorErrorWarnings.push(
            ` • Please review your file and/or consider changing your separator to ";"`
          )
        : this.separatorErrorWarnings.push(
            ` • Please review your file and consider using ";" only to separate columns.`
          );
      this.separatorErrorWarnings.push(`Lines with a problem : `);
      for (const dataLineError of dataLinesError) {
        this.separatorErrorWarnings.push(
          ` • line ${dataLineError[0]} : ${dataLineError[1]}`
        );
      }
    }
    this.uploadDatas = uploadDatas;
  }

  formatFiles() {
    let summary: string = "Infos: \n";
    for (const info of this.referenceSummary.info) {
      summary += `${info} \n`;
    }
    summary += `\nWarnings: \n`;
    for (const warning of this.referenceSummary.warnings) {
      summary += `${warning} \n`;
    }
    if (this.referenceSummary.details) {
      summary += `\nDetails: \n`;
      for (const warning of this.referenceSummary.details) {
        summary += `${warning} \n`;
      }
    }
    const userName: string = localStorage.getItem("username");
    const type: string = this.formConfig.name.replace(" ", "");
    const date: number = Date.now();
    this.saveFile(
      {
        referenceNameId: `reference:${userName}:${type}:${date}`,
        uploadDate: date
      },
      this.rawFile
    );
    this.saveFile(
      {
        referenceNameId: `reference:${userName}:${type}:summary:${date}`,
        uploadDate: date
      },
      summary
    );
  }

  backOnList() {
    this.objects = undefined;
    this.referenceSummary = undefined;
    this.updateObjectList(true);
    this.getLastFile(this.formConfig.name.replace(" ", ""));
    this.isUploaded = false;
    this.referenceNameControllerService.setIsLoading(undefined);
  }

  public changeUploadMode(mode: "Replace" | "Add") {
    this.uploadMode = mode;
  }
}
