<div class="skeleton-row">
  <div class="incident-checkbox-column single-column">
    <div class="skeleton-background" *ngIf="type !== 'ListDetail'"></div>
  </div>
  <div class="timestamp-column single-column">
    <div class="skeleton-background"></div>
  </div>
  <div class="device-column single-column">
    <div class="skeleton-background"></div>
  </div>
  <div class="container-column single-column">
    <div class="skeleton-background"></div>
  </div>
  <div class="status-column single-column">
    <div class="skeleton-background"></div>
  </div>
  <div class="radius-column single-column">
    <div class="skeleton-background"></div>
  </div>
  <div
    class="position-column single-column"
    [class.large-state-column]="type === 'List' || type === 'ListDetail'"
  >
    <div class="skeleton-background"></div>
  </div>
  <div
    class="last-authorized-site-column single-column"
    [class.large-state-column]="type === 'List' || type === 'ListDetail'"
  >
    <div class="skeleton-background"></div>
  </div>
  <div
    *ngIf="type === 'List' || type === 'ListDetail'"
    class="time-last-site-column single-column"
  >
    <div class="skeleton-background"></div>
  </div>
  <div class="labels-column single-column">
    <div class="skeleton-background"></div>
  </div>
  <ng-container *ngIf="type === 'List' || type === 'ListDetail'">
    <div *ngIf="isAdmin('IBMAdmin')" class="seq-number-column single-column">
      <div class="skeleton-background"></div>
    </div>
    <div *ngIf="isAdmin('IBMAdmin')" class="source-column single-column">
      <div class="skeleton-background"></div>
    </div>
    <div class="battery-column single-column">
      <div class="skeleton-background"></div>
    </div>
    <div class="maps-column single-column">
      <div class="skeleton-background"></div>
    </div>
  </ng-container>
</div>
