<div class="state-popup">
  <app-state-displayer-column-names
    [type]="'Map'"
    (checkboxUpdate)="updateAllIncidentDevices($event)"
    [hasCheckbox]="hasHeaderCheckbox()"
  ></app-state-displayer-column-names>
  <ng-container *ngIf="isLoading()">
    <div *ngFor="let _ of [].constructor(6)" class="skeleton-placeholders">
      <app-state-displayer-skeleton-line
        type="Map"
      ></app-state-displayer-skeleton-line>
    </div>
  </ng-container>
  <div
    *ngIf="!isLoading()"
    class="data"
    [infiniteScroll]="loadMore"
    [scrollRatio]="0.4"
    [initScrollThreshold]="50"
  >
    <div
      *ngFor="let currentState of currentStates; let i = index"
      (click)="deviceIdClicked(currentState)"
    >
      <div>
        <div *ngIf="i" class="information-divider"></div>
        <app-state-displayer
          [isSelectionOn]="isSelectionOn"
          (handleDevice)="onHandleDevice($event)"
          [(currentState)]="currentStates[i]"
          [isChecked]="allChecked"
          [mapDisplay]="true"
        ></app-state-displayer>
      </div>
    </div>
  </div>
</div>
