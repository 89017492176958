import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-toggle-checkbox",
  templateUrl: "./toggle-checkbox.component.html",
  styleUrls: ["./toggle-checkbox.component.scss"]
})
export class ToggleCheckboxComponent implements OnInit {
  @Output() toggle: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
