import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import {
  ProfileContainerTypeDTO,
  ProfileCreationDTO
} from "../../../../models/profile-container-type";
import { RightContainerTypesDTO } from "../../../../models/right-container-types";
import { ProfileContainerTypeControllerService } from "../../../../services/profile-container-type-controller.service";
import { RightContainerTypeControllerService } from "../../../../services/right-container-types-controller.service";
import { SimpleDialogComponent } from "../../../dialog/simple-dialog.component";

@Component({
  selector: "app-profile-manager",
  templateUrl: "./profile-manager.component.html",
  styleUrls: ["./profile-manager.component.scss"]
})
export class ProfileManagerComponent implements OnInit, OnDestroy {
  private readonly SELECTOR_STYLE_OPEN = "nav-selector-open";
  private readonly SELECTOR_STYLE_CLOSE = "nav-selector-close";
  private readonly CREATE_PROFILE = "groups";
  private readonly UPDATE_PROFILE = "update";
  private readonly DELETE_LOGO = "../../../../../assets/img/manager/delete.svg";

  private subscription: Subscription = new Subscription();

  isAddProfile: boolean = false;
  selectedNav: string = "Groups";
  newProfileIsValid: boolean = false;

  formConfig: any = {
    name: "Groups",
    title: "Groups",
    type: ProfileContainerTypeDTO,
    inputs: [
      {
        property: "profileContainerTypeId",
        title: "name",
        type: "text",
        input: true,
        mandatory: true,
        visible: true,
        id: true
      }
    ]
  };

  navObject: any[] = [
    {
      navName: "Groups",
      isOpen: true,
      selectorStyle: this.SELECTOR_STYLE_OPEN
    },
    {
      navName: "authorizedCt",
      isOpen: false,
      selectorStyle: this.SELECTOR_STYLE_CLOSE
    }
  ];

  profileProperties: any[] = [
    {
      label: "groups",
      property: "profileContainerTypeId",
      canBeFiltered: true,
      width: 15
    },
    {
      label: "lastEdited",
      property: "userId",
      canBeFiltered: true,
      width: 15
    }
  ];

  authCtProperties: any[] = [
    {
      label: "code",
      property: "code",
      canBeFiltered: true,
      width: 8
    },
    {
      label: "name",
      property: "label",
      canBeFiltered: true,
      width: 20
    },
    {
      label: "type",
      property: "category",
      canBeFiltered: true,
      width: 10
    },
    {
      label: "",
      property: "isAuthorized",
      type: "boolean",
      width: 4
    }
  ];

  selectedProfile: Partial<ProfileContainerTypeDTO> = {};
  selectedRightContainerTypes: any = {};

  profiles: ProfileContainerTypeDTO[];
  numberOfAllProfiles: number = 0;
  requestProfileId: number = 0;
  filterProfileChanged: boolean = false;
  filterProfile: any = undefined;
  profileRequestParameter: any = {
    skip: 0,
    limit: 25,
    filter: {}
  };

  numberOfAllAuthorizedCTs: number = 4;
  authorizedCTs: any = [];
  requestAuthCtId: number = 0;
  filterAuthCtChanged: boolean = false;
  filterAuthCt: any = undefined;
  authCtRequestParameter: any = {
    skip: 0,
    limit: 25,
    filter: {}
  };

  updateMode: boolean = false;
  updateModeLabel: string = this.UPDATE_PROFILE;

  constructor(
    private rightContainerTypeControllerService: RightContainerTypeControllerService,
    private profileContainerTypeControllerService: ProfileContainerTypeControllerService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.sendProfileRequest();
    this.sendProfileCount();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addProfile(_event) {
    this.selectedProfile = {};
    for (const input of this.formConfig.inputs) {
      input.value = undefined;
      input.valid = undefined;
    }
    this.updateModeLabel = this.CREATE_PROFILE;
    this.updateMode = false;
    this.openModifier();
  }

  changeFilterProfile(event) {
    if (event === undefined) {
      this.filterProfile = undefined;
    } else {
      if (event.search !== undefined) {
        const filter: any = { or: [] };
        for (const prop of this.profileProperties) {
          if (prop.canBeFiltered) {
            const object: any = {};
            object[prop.property] = event.search;
            filter.or.push(object);
          }
        }
        this.filterProfile = filter;
      } else {
        this.filterProfile = { and: [event] };
      }
    }
    this.profileRequestParameter.skip = 0;
    this.updateProfileList(true);
  }

  changePageProfile(event) {
    this.profileRequestParameter.limit = event.limit;
    this.profileRequestParameter.skip = (event.page - 1) * event.limit;
    this.updateProfileList();
  }

  selectProfile(event: ProfileContainerTypeDTO) {
    this.selectedProfile = { ...event };
    for (const input of this.formConfig.inputs) {
      input.value = this.selectedProfile[input.property];
      input.valid = true;
    }
    this.updateModeLabel = this.UPDATE_PROFILE;
    this.updateMode = true;
    this.openModifier();
  }

  isFormValid(event: boolean) {
    this.newProfileIsValid = event;
  }

  saveChanges() {
    if (this.updateMode) {
      this.sendUpdateProfile(this.generateProfileCreation());
    } else {
      this.sendCreateProfile(this.generateProfileCreation());
    }
  }

  deleteProfile() {
    const dialogRef = this.dialog.open(SimpleDialogComponent, {
      data: {
        title: `Delete ${this.selectedProfile.profileContainerTypeId}`,
        text: `Do you want to delete this group permanently?`,
        yes: "Yes",
        no: "No"
      }
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.sendDeleteProfile();
          this.backToList();
          this.updateProfileList(true);
        }
      })
    );
  }

  changeSelectedProfile(event: ProfileContainerTypeDTO) {
    this.selectedProfile = { ...event };
  }

  updateProfileList(isFilterChanged?: boolean) {
    this.filterProfileChanged = isFilterChanged
      ? true
      : this.filterProfileChanged;
    this.requestProfileId++;
    const id: number = this.requestProfileId;
    setTimeout(() => {
      if (this.requestProfileId === id) {
        this.sendProfileRequest();
        if (this.filterProfileChanged) {
          this.sendProfileCount();
        }
      }
    }, 1000);
  }

  openModifier() {
    this.getAuthCt();
    this.sendAuthCtRequest();
    this.sendAuthCtCount();
    this.isAddProfile = true;
  }

  generateProfileCreation(): ProfileCreationDTO {
    const profile: ProfileContainerTypeDTO = {
      profileContainerTypeId: this.selectedProfile.profileContainerTypeId,
      userId: localStorage.getItem("username")
    };
    const rightContainerTypes: RightContainerTypesDTO[] = [];
    for (const rightCt in this.selectedRightContainerTypes) {
      rightContainerTypes.push(this.selectedRightContainerTypes[rightCt]);
    }
    const userCreation: ProfileCreationDTO = {
      profile: profile,
      rightContainerTypes: rightContainerTypes
    };
    return userCreation;
  }

  sendProfileRequest() {
    this.subscription.add(
      this.profileContainerTypeControllerService
        .getProfiles(
          this.profileRequestParameter.skip,
          this.profileRequestParameter.limit,
          this.filterProfile
        )
        .subscribe((result) => {
          this.profiles = result;
          const adminProfile: ProfileContainerTypeDTO = this.profiles.find(
            (profile) => profile.profileContainerTypeId === "Admin"
          );
          if (adminProfile) {
            adminProfile.disabled = true;
          }
        })
    );
  }

  sendProfileCount() {
    this.subscription.add(
      this.profileContainerTypeControllerService
        .getCount(this.filterProfile)
        .subscribe((result) => {
          this.numberOfAllProfiles = result.count;
        })
    );
  }

  sendDeleteProfile() {
    this.subscription.add(
      this.profileContainerTypeControllerService
        .deleteProfile(this.selectedProfile.profileContainerTypeId)
        .subscribe((_result) => {})
    );
  }

  sendUpdateProfile(profileCreation: ProfileCreationDTO) {
    this.subscription.add(
      this.profileContainerTypeControllerService
        .patchProfile(profileCreation)
        .subscribe((_result) => {})
    );
    this.backToList();
    this.updateProfileList(true);
  }

  sendCreateProfile(profileCreation: ProfileCreationDTO) {
    this.subscription.add(
      this.profileContainerTypeControllerService
        .createProfile(profileCreation)
        .subscribe(
          (_result) => {
            this.backToList();
            this.updateProfileList(true);
          },
          (err) => {
            if (err.status === 500) {
              this.formConfig.inputs[0].valid = false;
            }
          }
        )
    );
  }

  changeFilterAuthorizedCT(event) {
    if (event === undefined) {
      this.filterAuthCt = undefined;
    } else {
      if (event.search !== undefined) {
        const filter: any = { or: [] };
        for (const prop of this.authCtProperties) {
          if (prop.canBeFiltered) {
            const object: any = {};
            object[prop.property] = event.search;
            filter.or.push(object);
          }
        }
        this.filterAuthCt = filter;
      } else {
        this.filterAuthCt = { and: [event] };
      }
    }
    this.authCtRequestParameter.skip = 0;
    this.updateAuthCtList(true);
  }

  changePageAuthorizedCT(event) {
    this.authCtRequestParameter.limit = event.limit;
    this.authCtRequestParameter.skip = (event.page - 1) * event.limit;
    this.updateAuthCtList();
  }

  selectAuthorizedCT(event: any) {
    event.isAuthorized = !event.isAuthorized;
    if (event.isAuthorized) {
      this.selectedRightContainerTypes[event.code] = {
        userId: this.selectedProfile.profileContainerTypeId,
        containerTypeId: event.code
      };
    } else {
      this.selectedRightContainerTypes[event.code] = undefined;
    }
  }

  updateAuthCtList(isFilterChanged?: boolean) {
    this.filterAuthCtChanged = isFilterChanged
      ? true
      : this.filterAuthCtChanged;
    this.requestAuthCtId++;
    const id: number = this.requestAuthCtId;
    setTimeout(() => {
      if (this.requestAuthCtId === id) {
        this.sendAuthCtRequest();
        if (this.filterAuthCtChanged) {
          this.sendAuthCtCount();
        }
      }
    }, 1000);
  }

  sendAuthCtRequest() {
    this.subscription.add(
      this.rightContainerTypeControllerService
        .getContainerTypesAuthorized(
          this.selectedProfile.profileContainerTypeId
            ? this.selectedProfile.profileContainerTypeId
            : undefined,
          this.authCtRequestParameter.skip,
          this.authCtRequestParameter.limit,
          this.filterAuthCt
        )
        .subscribe((result) => {
          this.authorizedCTs = result.map((authCt) => {
            authCt.isAuthorized = this.selectedRightContainerTypes[authCt.code]
              ? true
              : false;
            return authCt;
          });
        })
    );
  }

  sendAuthCtCount() {
    this.subscription.add(
      this.rightContainerTypeControllerService
        .getContainerTypesAuthorizedCount(this.filterAuthCt)
        .subscribe((result) => {
          this.numberOfAllAuthorizedCTs = result.count;
        })
    );
  }

  getAuthCt() {
    this.subscription.add(
      this.rightContainerTypeControllerService
        .getContainerTypesAuthorizedOnly(
          this.selectedProfile.profileContainerTypeId
            ? this.selectedProfile.profileContainerTypeId
            : undefined
        )
        .subscribe((result) => {
          this.selectedRightContainerTypes = {};
          for (const cT of result) {
            this.selectedRightContainerTypes[cT.code] = {
              containerTypeId: cT.code,
              userId: this.selectedProfile.profileContainerTypeId
            };
          }
          this.authorizedCTs = this.authorizedCTs.map((authCt) => {
            authCt.isAuthorized = this.selectedRightContainerTypes[authCt.code]
              ? true
              : false;
            return authCt;
          });
        })
    );
  }

  onNavChange(event: any) {
    for (const nav of this.navObject) {
      nav.isOpen = false;
      nav.selectorStyle = this.SELECTOR_STYLE_CLOSE;
    }
    event.isOpen = true;
    event.selectorStyle = this.SELECTOR_STYLE_OPEN;
    this.selectedNav = event.navName;
    console.log(this.selectedNav);
  }

  backToList() {
    this.isAddProfile = false;
    this.onNavChange(this.navObject[0]);
  }
}
