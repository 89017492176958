import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from "@angular/core";
import { Subscription } from "rxjs";
import * as moment from "moment/moment";
import { animate, style, transition, trigger } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { CurrentStateDTO } from "../../../../models/current-state";
import { DeviceContainerStateControllerService } from "../../../../services/device-container-state-controller.service";
import { HistoricalStateDTO } from "../../../../models/historical-state";
import * as L from "leaflet";
import { GeoUtils } from "../../../../services/geo-utils";
import {
  UserRightsService,
  userAccess
} from "../../../../services/user-rights.service";
import { MAP } from "../../../utils/export-excel/export-excel.component";
import "leaflet-arrowheads";
import { ColorService } from "../../../../services/color.service";

@Component({
  selector: "app-map-detail",
  templateUrl: "./map.detail.component.html",
  styleUrls: ["./map.detail.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("100ms", style({ opacity: 1 }))
      ])
    ])
  ]
})
export class MapDetailComponent implements OnInit, OnDestroy {
  @Input() map: L.Map;
  @Input() currentState: CurrentStateDTO;
  @Input() display: boolean;

  @Output() navigate = new EventEmitter<any>();
  @Output() toList = new EventEmitter<any>();
  @Output() refreshMap = new EventEmitter<any>();

  private subscription: Subscription = new Subscription();

  loading: boolean;

  lastHoursIndex: number;
  lastHoursHistory: any[];

  customIcon: any;
  customMarker: any;

  clusterGroup: any;
  circleGroup: any;
  precisionCircleGroup: any;
  polylineGroup: any;

  hoverDevicePopup: any;
  hoverDatePopup: any;
  containerPopup: any;

  currentMarker: any;

  currentItem: any;
  selectedPos = -1;

  precisionCircle: boolean;
  infoPanelVisible: boolean;

  historicalState: Array<HistoricalStateDTO>;
  exportType: string = MAP;

  mapReady: boolean;

  res: Array<HistoricalStateDTO>;

  containerType: string;
  deviceId: string;
  private readonly ZOOM_METERS = new Map([
    [4, 500000],
    [5, 200000],
    [6, 100000],
    [7, 50000],
    [8, 30000],
    [9, 10000],
    [10, 5000],
    [11, 3000],
    [12, 2000],
    [13, 1000],
    [14, 500],
    [15, 200],
    [16, 100],
    [17, 50],
    [18, 30]
  ]);
  private authorizedEvolUsers = ["IBMAdmin", "Admin", "SuperUser"];

  ngOnInit() {
    this.refreshEventOnMap();
    this.currentItem = this.currentState;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.refreshMap.emit();
  }

  constructor(
    public _deviceContainerStateService: DeviceContainerStateControllerService,
    public _geoUtils: GeoUtils,
    private _userRightsService: UserRightsService,
    private translate: TranslateService,
    private colorService: ColorService
  ) {
    this.lastHoursHistory = [
      {
        durationHours: 168,
        icon: "chronometre-1W.png",
        text: "1 week"
      },
      {
        durationHours: 720,
        icon: "chronometre-1M.png",
        text: "1 month"
      },
      {
        durationHours: 4320,
        icon: "chronometre-6M.png",
        text: "6 months"
      },
      {
        durationHours: 8640,
        icon: "chronometre-1Y.png",
        text: "1 year"
      }
    ];

    this.subscription.add(
      translate.stream("map.historyLabels").subscribe((labels) => {
        this.lastHoursHistory = this.lastHoursHistory.map((history, i) => {
          return { ...history, text: labels[i] };
        });
      })
    );

    if (
      this.authorizedEvolUsers.indexOf(localStorage.getItem("profilId")) === -1
    )
      this.lastHoursHistory.pop(); // 1 year visibility is only for super admin for now
    this.lastHoursIndex = 0;

    this.customMarker = L.Marker.extend({
      options: { timestamp: "default", item: [] }
    });

    this.customIcon = L.DivIcon.extend({});

    this.precisionCircle = false;
  }

  refreshEventOnMap() {
    this.loading = true;
    this.subscription.add(
      this._deviceContainerStateService
        .getHistoricalStateByDeviceContainer({
          requestId: 0,
          containerTypes: [],
          filterDeviceId: this.currentState.deviceId,
          statusDays: this.lastHoursHistory[this.lastHoursIndex].durationHours
        })
        .subscribe((res) => {
          this.historicalState = res;
          this.res = res;

          if (this.circleGroup !== undefined) {
            this.map.removeLayer(this.circleGroup);
          }
          if (this.currentMarker !== undefined) {
            this.map.removeLayer(this.currentMarker);
          }
          if (this.polylineGroup !== undefined) {
            this.map.removeLayer(this.polylineGroup);
          }
          if (this.precisionCircleGroup !== undefined) {
            this.map.removeLayer(this.precisionCircleGroup);
          }
          this.clusterGroup = new L.FeatureGroup();
          this.circleGroup = new L.FeatureGroup();

          const coordinateLines = [];

          let childCount = this.res.length;

          this.res.forEach((item, i) => {
            if (!this.containerType) {
              this.containerType = item.containerType;
            }

            if (!this.deviceId) {
              this.deviceId = item.deviceId;
            }

            this.containerPopup = L.DomUtil.create("div");
            this.hoverDatePopup = L.DomUtil.create(
              "div",
              "hover-date",
              this.containerPopup
            );

            this.hoverDatePopup.innerHTML =
              '<div id="hoverDate">' +
              moment.unix(item.receivedMessageTime).format("DD/MM/YYYY HH:mm") +
              "</div>";

            coordinateLines.push([
              item.latitudeComputed,
              item.longitudeComputed
            ]);

            const popup = L.popup({ closeButton: false, offset: [0, -40] })
              .setLatLng([item.latitudeComputed, item.longitudeComputed])
              .setContent(this.containerPopup);

            this.createMarker(item, this.currentState, popup, --childCount);

            const popupCircle = L.popup({
              closeButton: false,
              offset: [0, -10]
            })
              .setLatLng([item.latitudeComputed, item.longitudeComputed])
              .setContent(this.containerPopup);

            var zoom = this.map.getZoom();
            var scale =
              this.ZOOM_METERS.get(zoom) === undefined
                ? 50
                : this.ZOOM_METERS.get(zoom) / 10;

            var marker = L.circle(
              [item.latitudeComputed, item.longitudeComputed],
              {
                color: this._geoUtils.getMarkerColor(
                  item.statusColor.toLowerCase()
                ),
                fillColor: this._geoUtils.getMarkerColor(
                  item.statusColor.toLowerCase()
                ),
                fillOpacity: 0.4,
                radius: scale,
                opacity: 1
              }
            ).bindPopup(popupCircle);
            marker.on("mouseover", function (_e) {
              this.openPopup();
              this.setRadius(scale * 1.1);
            });
            marker.on("mouseout", function (_e) {
              this.closePopup();
              this.setRadius(scale);
            });
            marker.on("click", (_e: any) => {
              this.selectedPos = i;
              this.changeMarkerPosition();
            });

            this.circleGroup.addLayer(marker);

            const _this = this;
            this.map.on("zoomend", function () {
              zoom = this.getZoom();
              scale =
                _this.ZOOM_METERS.get(zoom) === undefined
                  ? 50
                  : _this.ZOOM_METERS.get(zoom) / 10;
              marker.setRadius(scale);
            });
          });

          if (this.clusterGroup.getLayers().length > 0) {
            if (
              this.selectedPos < 0 ||
              this.selectedPos >= this.clusterGroup.getLayers().length
            ) {
              this.selectedPos = this.clusterGroup.getLayers().length - 1;
            }
            this.currentMarker =
              this.clusterGroup.getLayers()[this.selectedPos];
            this.map.addLayer(this.currentMarker);
          }

          if (coordinateLines.length > 0) {
            this.polylineGroup = L.polyline(coordinateLines, {
              weight: 1.5,
              opacity: 1,
              color: this.colorService.colors.greyMain
            }).arrowheads({
              frequency: "60000m",
              size: "5px",
              fill: true
            });
            this.map.addLayer(this.polylineGroup);
          }

          if (this.circleGroup.getLayers().length > 0) {
            this.map.addLayer(this.circleGroup);
          }

          this.loading = false;
        })
    );
  }

  private createMarker(
    item: HistoricalStateDTO,
    currentState: CurrentStateDTO,
    popup: L.Popup,
    index: number
  ) {
    this.currentItem = item;

    this.currentItem.computedTimeStamp = moment
      .unix(this.currentItem.receivedMessageTime)
      .format("DD/MM/YYYY HH:mm");

    const divCluster = L.DomUtil.create("div");

    if (index !== 0) {
      const divCount = L.DomUtil.create("span", "childCount", divCluster);
      divCount.innerHTML = "-" + index;
      divCount.style.position = "absolute";
      divCount.style.zIndex = "100";
      divCount.style.top = "80%";
      divCount.style.left = "50%";
      divCount.style.fontSize = "10px";
      divCount.style.transform = "translate(-50%, -50%)";
      divCount.style.color = "white";
    }

    const divIconCluster = L.DomUtil.create(
      "img",
      "iconCluster",
      divCluster
    ) as HTMLImageElement;
    divIconCluster.src = this._geoUtils.getMarkerIcon(
      item.statusColor.toLowerCase(),
      item.statusColor === "GREY",
      item.locationSource.toLowerCase().includes("wifi")
    );
    divIconCluster.style.width = "40px";
    divIconCluster.style.height = "50px";

    const marker = new this.customMarker(
      [item.latitudeComputed, item.longitudeComputed],
      {
        icon: new this.customIcon({
          html: divCluster.innerHTML,
          className: "div-cluster"
        })
      }
    )
      .bindPopup(popup)
      .closePopup();
    marker.timestamp = item.receivedMessageTime;
    marker.item = item;
    this.clusterGroup.addLayer(marker);
  }

  updatePrecisionCircle() {
    this.precisionCircle = !this.precisionCircle;
    this.addPrecisionCircle();
  }

  hideMap() {
    if (this.circleGroup !== undefined) {
      this.map.removeLayer(this.circleGroup);
    }
    if (this.currentMarker !== undefined) {
      this.map.removeLayer(this.currentMarker);
    }
    if (this.polylineGroup !== undefined) {
      this.map.removeLayer(this.polylineGroup);
    }
    if (this.precisionCircleGroup !== undefined) {
      this.map.removeLayer(this.precisionCircleGroup);
    }

    this.currentItem = null;
    const _this = this;
    this.display = false;
    this.navigate.emit({ showDetailMap: false, currentState: null });

    this.selectedPos = -1;
    this.infoPanelVisible = false;
    this.precisionCircle = false;
  }

  lastHoursSelected(i: number) {
    if (i === this.lastHoursIndex) {
      // Don't refresh if there's no need to
      return;
    }
    this.precisionCircle = false;
    this.lastHoursIndex = i;
    if (this.currentState) {
      this.selectedPos = -1;
      this.refreshEventOnMap();
    }
  }

  changeMarkerPosition() {
    const wasPopupOpen: boolean = this.currentMarker.getPopup().isOpen();
    if (this.currentMarker !== undefined) {
      this.map.removeLayer(this.currentMarker);
    }
    this.currentMarker = this.clusterGroup.getLayers()[this.selectedPos];
    this.currentItem = this.currentMarker.item;
    this.map.addLayer(this.currentMarker);
    if (wasPopupOpen) {
      this.currentMarker.openPopup();
    }
    this.map.panTo(
      [this.currentItem.latitudeComputed, this.currentItem.longitudeComputed],
      { animate: true, duration: 0.5 }
    );
    this.addPrecisionCircle();
  }

  lastPos() {
    if (this.selectedPos < this.clusterGroup.getLayers().length - 1) {
      this.selectedPos = this.clusterGroup.getLayers().length - 1;
      this.changeMarkerPosition();
    }
  }

  firstPos() {
    if (this.selectedPos > 0) {
      this.selectedPos = 0;
      this.changeMarkerPosition();
    }
  }

  previousPos() {
    if (this.selectedPos > 0) {
      this.selectedPos--;
      this.changeMarkerPosition();
    }
  }

  nextPos() {
    if (this.selectedPos < this.clusterGroup.getLayers().length - 1) {
      this.selectedPos++;
      this.changeMarkerPosition();
    }
  }

  isAtBeginningOfHistory(): boolean {
    return this.selectedPos === 0;
  }

  isAtEndOfHistory(): boolean {
    return (
      this.clusterGroup !== undefined &&
      this.selectedPos === this.clusterGroup.getLayers().length - 1
    );
  }

  backToList() {
    if (this.circleGroup !== undefined) {
      this.map.removeLayer(this.circleGroup);
    }
    if (this.currentMarker !== undefined) {
      this.map.removeLayer(this.currentMarker);
    }
    if (this.polylineGroup !== undefined) {
      this.map.removeLayer(this.polylineGroup);
    }

    this.currentItem = null;
    const _this = this;
    this.display = false;

    this.selectedPos = -1;
    this.infoPanelVisible = false;
    this.precisionCircle = false;

    this.toList.emit({
      tab: userAccess.LIST,
      display: true,
      currentState: this.currentState
    });
  }

  public redirectToGmap(lat, long): void {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${lat},${long}`,
      "_blank"
    );
  }

  addPrecisionCircle() {
    if (this.precisionCircle) {
      if (this.precisionCircleGroup !== undefined) {
        this.map.removeLayer(this.precisionCircleGroup);
      }
      this.precisionCircleGroup = new L.FeatureGroup();
      const currentState = this.currentItem;
      this.precisionCircleGroup.addLayer(
        L.circle(
          [currentState.latitudeComputed, currentState.longitudeComputed],
          {
            color: this.colorService.colors.siteMarker,
            fillColor: this.colorService.colors.siteMarker,
            fillOpacity: 0.3,
            radius: currentState.confidenceRadiusInMeter,
            interactive: false
          }
        )
      );
      this.map.addLayer(this.precisionCircleGroup);
    } else {
      if (this.precisionCircleGroup !== undefined) {
        this.map.removeLayer(this.precisionCircleGroup);
      }
    }
  }

  protected isAdmin(event: string): boolean {
    return this._userRightsService.getUserRightLevel(event);
  }
}
