import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { ContainerTypeDTO } from "../models/container-type";
import { CountDTO } from "../models/historical-state";
import { AuthorizedContainerTypeDTO } from "../models/right-container-types";
import { HttpClientWrapper } from "./http-client.wrapper";

/**
 * SitePostalController service call api rest for PostalAddress - return PostalAddressDTO
 */

@Injectable()
export class RightContainerTypeControllerService {
  constructor(
    public httpClientWrapper: HttpClientWrapper<ContainerTypeDTO>,
    public httpClientAuthorized: HttpClientWrapper<AuthorizedContainerTypeDTO>,
    public httpClientCount: HttpClientWrapper<CountDTO>
  ) {}

  getContainerTypesAuthorized(
    profileId: string,
    skip: string,
    limit: string,
    filterInput?: any
  ): Observable<AuthorizedContainerTypeDTO[]> {
    let filter: any = {};
    if (filterInput) {
      filter.where = filterInput;
    }
    let filterFormated: string = "?filter=";
    filter.skip = skip;
    filter.limit = limit;
    filter.order = "code ASC";
    filterFormated = filterFormated + JSON.stringify(filter);

    return this.httpClientAuthorized.get(
      environment.apiBaseUrl +
        "right-container-types/" +
        profileId +
        filterFormated
    );
  }

  getContainerTypesAuthorizedCount(filterInput?: any): Observable<CountDTO> {
    let filterFormated: string = "?where=";
    let filter: any = {};
    if (filterInput) {
      filter = filterInput;
    }
    filterFormated = filterFormated + JSON.stringify(filter);

    return this.httpClientCount.getUnique(
      environment.apiBaseUrl + "container-types/count" + filterFormated
    );
  }

  getContainerTypesAuthorizedOnly(
    profileId: string,
    skip?: string,
    limit?: string,
    _filterInput?: any
  ): Observable<ContainerTypeDTO[]> {
    let filterFormated: string = "?filter=";
    const filter: Object = {
      skip,
      limit,
      order: "code DESC"
    };

    filterFormated = filterFormated + JSON.stringify(filter);

    return this.httpClientWrapper.get(
      environment.apiBaseUrl +
        "right-container-types/container-types/" +
        profileId +
        filterFormated
    );
  }
}
