import { DeviceContainerStateControllerService } from "../../../../services/device-container-state-controller.service";
import {
  BLUE,
  GREEN,
  LIGHTGREEN,
  ORANGE,
  RED,
  YELLOW
} from "../../../../services/geo-utils";

import { Subscription } from "rxjs";
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from "@angular/core";

@Component({
  selector: "app-battery",
  templateUrl: "./battery.component.html",
  styleUrls: ["./battery.component.scss"]
})
export class BatteryComponent implements OnChanges, OnDestroy {
  @Input() allContainersType: any[] = [];
  @Input() filterContainers: any[] = [];

  public greenColor: string = GREEN.replace(/o/, "1");
  public blueColor: string = BLUE.replace(/o/, "1");
  public orangeColor: string = ORANGE.replace(/o/, "1");
  public redColor: string = RED.replace(/o/, "1");
  public yellowColor: string = YELLOW.replace(/o/, "1");
  public lightGreenColor: string = LIGHTGREEN.replace(/o/, "1");

  public lightGreenBattery: number = 0;
  public yellowBattery: number = 0;
  public greenBattery: number = 0;
  public redBattery: number = 0;

  private subscription: Subscription = new Subscription();

  constructor(
    private deviceContainerStateControllerService: DeviceContainerStateControllerService
  ) {}

  public ngOnChanges(_changes: SimpleChanges): void {
    if (this.allContainersType) this.getBatteriesLevel();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getBatteriesLevel() {
    this.subscription.add(
      this.deviceContainerStateControllerService
        .getBatteryColors({
          requestId: 0,
          containerTypes: !this.filterContainers?.length
            ? this.allContainersType
            : this.filterContainers
        })
        .subscribe((kpi: any) => {
          if (kpi !== false) {
            this.greenBattery = kpi.excellent;
            this.lightGreenBattery = kpi.good;
            this.yellowBattery = kpi.almostEmpty;
            this.redBattery = kpi.empty;
          }
        })
    );
  }
}
