import { IncidentControllerService } from "../../../services/incident-controller.service";
import { UserControllerService } from "../../../services/user-controller.service";
import { SiteControllerService } from "../../../services/site-controller.service";

import { CurrentStateDTO } from "../../../models/current-state";
import { SiteDTO } from "../../../models/site";
import { UserDTO } from "../../../models/user";
import {
  IncidentAssociatedDeviceDTO,
  IncidentCommentDTO,
  IncidentDTO
} from "../../../models/incident";

import { MatSidenav } from "@angular/material/sidenav";
import { NotifierService } from "angular-notifier";
import { Subscription } from "rxjs";
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from "@angular/core";

@Component({
  selector: "app-incident-sidenav",
  templateUrl: "./incident-sidenav.component.html",
  styleUrls: ["./incident-sidenav.component.scss"]
})
export class IncidentSidenavComponent implements OnInit, OnChanges {
  @ViewChild("incidentSidenav", { static: true }) public sidenav: MatSidenav;

  @Output() closed: EventEmitter<void> = new EventEmitter();
  @Output() incidentCreated: EventEmitter<IncidentDTO> = new EventEmitter();

  @Input() devices: CurrentStateDTO[] = [];

  commentFromSidenav: string;

  incident: Partial<IncidentDTO> = {};

  public commentResult: Partial<IncidentCommentDTO> = {};
  public sites: Array<SiteDTO>;
  public pipeData: boolean = false;
  public submitted: boolean = false;

  public users: UserDTO[] = [];
  public incidentCreation: any = {
    title: "",
    type: "Delivery error",
    status: "New",
    priority: "High",
    origin: "GLE_request",
    currentSite: "",
    lastAuthorizedSite: ""
  };
  public informationInputs = [
    {
      property: "title",
      title: "Title",
      type: "text",
      input: true,
      mandatory: true,
      valid: true
    },
    {
      property: "type",
      title: "Alert Type",
      type: "string",
      selector: true,
      choices: [
        "Delivery error",
        "Carrier stop",
        "Project request",
        "Temporary flow",
        "Under repair",
        "T&T reference issue",
        "T&T wrong tagging",
        "Other"
      ],
      mandatory: true,
      valid: true
    },
    {
      property: "currentSite",
      title: "Current Site",
      type: "string",
      search_selector: true,
      filteredOptions: [],
      choices: [],
      mandatory: false,
      valid: true
    },
    {
      property: "lastAuthorizedSite",
      title: "Last authorized site",
      type: "string",
      selector: true,
      choices: [],
      mandatory: false,
      valid: true
    },
    {
      property: "containerType",
      title: "Container type",
      type: "string",
      selector: true,
      choices: [],
      mandatory: false,
      valid: true
    },
    {
      property: "totalQuantity",
      title: "total Quantity",
      type: "string",
      input: true,
      mandatory: false,
      valid: true
    }
  ];

  public activityInputs = [
    {
      property: "status",
      title: "Status",
      type: "string",
      selector: true,
      choices: [
        "New",
        "In Progress",
        "Blocked",
        "Resolved",
        "Cancelled",
        "Rejected"
      ],
      mandatory: true,
      valid: true
    },
    {
      property: "assignedTo",
      title: "Assigned to",
      type: "string",
      search_selector: true,
      filteredOptions: [],
      filter: "",
      choices: [],
      mandatory: true,
      valid: true
    },
    {
      property: "priority",
      title: "Priority",
      type: "string",
      selector: true,
      choices: ["High", "Medium", "Low"],
      mandatory: true,
      valid: true
    },
    {
      property: "origin",
      title: "Origin",
      type: "string",
      selector: true,
      choices: ["T&T_Analysis", "Central _Request", "GLE_Request"],
      mandatory: true,
      valid: true
    }
  ];

  private subscription: Subscription = new Subscription();
  private readonly notifier: NotifierService;

  constructor(
    private userService: UserControllerService,
    private notifierService: NotifierService,
    private incidentControllerService: IncidentControllerService,
    private siteControllerService: SiteControllerService
  ) {
    this.notifier = notifierService;
  }

  public ngOnInit(): void {
    this.initDatas();
    setTimeout(() => {
      this.sidenav.open();
    }, 200);
  }

  private initDatas(): void {
    let filterUser = {
      or: [
        { rightId: "User" },
        { rightId: "SuperUser" },
        { rightId: "Admin" },
        { rightId: "User/MobileUser" },
        { rightId: "User/MobileSuperUser" },
        { rightId: "SuperUser/MobileUser" },
        { rightId: "SuperUser/SuperMobileUser" },
        { rightId: "IBMAdmin" }
      ]
    };

    this.subscription.add(
      this.userService.getUserNames(filterUser).subscribe((result) => {
        this.activityInputs[1].choices = result.map(({ name }) => name).sort();
        this.activityInputs[1].filteredOptions = this.activityInputs[1].choices;
        this.activityInputs[1].filter = localStorage.getItem("username");
        this.incidentCreation[this.activityInputs[1].property] =
          localStorage.getItem("username");
      })
    );
    this.subscription.add(
      this.siteControllerService.getSites().subscribe((res) => {
        this.informationInputs[2].choices = res
          .map(({ code, label }) => ({ label, code }))
          .sort();
        this.informationInputs[2].filteredOptions =
          this.informationInputs[2].choices;
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.devices && this.devices?.length) {
      this.informationInputs[3].choices = [
        ...new Set(this.devices.map((device) => device.lastAuthorizedSiteCofor))
      ];
      this.informationInputs[4].choices = [
        ...new Set(this.devices.map((device) => device.containerType))
      ];
    }
    this.incidentCreation = {
      ...this.incidentCreation,
      latitude: this.devices[0].latitudeComputed,
      longitude: this.devices[0].longitudeComputed,
      containerType: this.informationInputs[4].choices[0],
      lastAuthorizedSite: this.informationInputs[3].choices[0],
      totalQuantity: this.devices.length
    };
  }

  public onFilterChange(input): void {
    if (input.property === "currentSite") {
      input.filteredOptions = input.choices.filter((option) => {
        const label = option.label.toLowerCase();
        const code = option.code.toLowerCase();
        const filter = input.filter.toLowerCase();

        return label.includes(filter) || code.includes(filter);
      });
    } else {
      input.filteredOptions = input.choices.filter((option) =>
        option.toLowerCase().includes(input.filter.toLowerCase())
      );
    }
    if (input.filter === "" && input.property !== "assignedTo") {
      input.choices.unshift("");
      this.selectItem(input.filter, input);
      input.choices.splice(0, 1);
    }
  }

  public selectItem(choice, input): void {
    this.incidentCreation[input.property] = choice;
    if (input.search_selector !== undefined) {
      if (choice === "") {
        this.incidentCreation[input.property] = input.choices[0];
      } else {
        this.pipeData = true;
        input.filter = choice;
        this.incidentCreation[input.property] = choice;
      }
    }
    input.valid;
  }

  public setIsValid(value, input): void {
    input.valid = !!value;
  }

  public onSubmit(): void {
    if (this.submitted) return;

    let isValid: boolean = true;
    const invalidProperties: string[] = [];

    for (const input of this.informationInputs) {
      if (input.mandatory && !this.incidentCreation[input.property]) {
        isValid = false;
        input.valid = false;
        invalidProperties.push(input.title);
      }
    }
    for (const input of this.activityInputs) {
      if (input.mandatory && !this.incidentCreation[input.property]) {
        isValid = false;
        input.valid = false;
        invalidProperties.push(input.title);
      }
    }

    if (isValid) {
      this.incidentCreation.currentSite =
        this.incidentCreation.currentSite.code;
      this.incidentCreation.tantQuantity = this.devices.length;
      this.incidentCreation.totalQuantity = Number(
        this.incidentCreation.totalQuantity
      );

      this.subscription.add(
        this.incidentControllerService
          .createIncident(this.incidentCreation)
          .subscribe((res) => {
            const deviceArray: Array<Partial<IncidentAssociatedDeviceDTO>> = [];

            // Devices post Part
            this.devices.forEach((device) => {
              let oneDevice: Partial<IncidentAssociatedDeviceDTO> = {
                isOpened: false,
                incidentId: res.id,
                deviceId: device.deviceId,
                lastAuthorizedSite: res.lastAuthorizedSite,
                containerType: res.containerType,
                openingDate: res.creationDate,
                assignedBy: res.createdBy,
                closingDate: null
              };

              deviceArray.push(oneDevice);
            });

            this.subscription.add(
              this.incidentControllerService
                .postSelectDevices(deviceArray)
                .subscribe(() => {
                  if (!this.commentFromSidenav) {
                    this.incidentCreated.emit(res);
                    this.onClose();
                  }
                })
            );

            // Comment post part if not null input
            if (this.commentFromSidenav) {
              this.commentResult = {
                comment: this.commentFromSidenav,
                commentedBy: localStorage.getItem("username"),
                incidentId: res.id
              };
              this.subscription.add(
                this.incidentControllerService
                  .createIncidentComment(this.commentResult)
                  .subscribe(() => {
                    this.incidentCreated.emit(res);
                    this.onClose();
                  })
              );
            }
          })
      );
      this.submitted = true;
    } else {
      this.notifier.notify(
        "error",
        `Properties: [${invalidProperties.join(", ")}] are not valid.`
      );
    }
  }

  public onClose(): void {
    this.sidenav.close();
    setTimeout(() => {
      this.closed.emit();
    }, 300);
  }

  public changeArrow(input: any) {
    input.isOpen = input.isOpen ? false : true;
  }
}
