<app-container-type-sidenav></app-container-type-sidenav>

<div class="content">
  <app-header-kpi
    (containers)="changeContainers($event)"
    (toggleBattery)="toggleBattery()"
    [batteryVisible]="isAdmin('SuperUser')"
    [filterContainers]="filterContainers"
  >
  </app-header-kpi>

  <div class="content-kpi" *ngIf="!isBattery">
    <div class="tile-kpi">
      <div class="tile-kpi-title">
        {{ "kpi.global" | translate }}
      </div>
      <div class="tile-kpi-graph">
        <app-chart
          (changeTab)="onChangeTab($event)"
          [green]="green"
          [blue]="blue"
          [orange]="orange"
          [red]="red"
          [grey]="quiet"
        ></app-chart>
      </div>
    </div>
    <div class="tile-label">
      <div class="tile-label-title">
        {{ "kpi.detail" | translate }}
      </div>
      <div class="tile-label-center">
        <div class="tile-label-labels">
          <div class="tile-label-labels-column">
            <app-label
              [numberOfContainers]="green"
              [colorBackground]="greenColor"
              [label]="'misc.status.OK' | translate"
              [status]="'OK'"
            ></app-label>
            <app-label
              [numberOfContainers]="blue"
              [colorBackground]="blueColor"
              [label]="'misc.status.IN TRANSIT' | translate"
              [status]="'IN-TRANSIT'"
            ></app-label>
            <app-label
              [numberOfContainers]="quiet"
              [colorBackground]="'#757575'"
              [label]="'misc.status.QUIET' | translate"
              [status]="'QUIET'"
            ></app-label>
          </div>
          <div class="tile-label-labels-column">
            <app-label
              [numberOfContainers]="red"
              [colorBackground]="redColor"
              [label]="'misc.status.ALERT' | translate"
              [status]="'ALERT'"
            ></app-label>
            <app-label
              [numberOfContainers]="orange"
              [colorBackground]="orangeColor"
              [label]="'misc.status.WARNING' | translate"
              [status]="'WARNING'"
              [numberDetour]="orangeDetour"
              [numberStatic]="orangeStatic"
              [numberLate]="orangeLate"
            ></app-label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-battery
    *ngIf="isBattery"
    [allContainersType]="allContainersTypes"
    [filterContainers]="filterContainers"
  ></app-battery>
</div>
