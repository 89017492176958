<app-container-type-sidenav
  *ngIf="!infoCurrentState && !showInfo"
></app-container-type-sidenav>

<div class="list-wrapper" [ngClass]="{ hidden: showMap }">
  <app-detail
    *ngIf="infoCurrentState && showInfo"
    [(display)]="showInfo"
    [(currentState)]="infoCurrentState"
    [hidden]="!showInfo"
    (displayChange)="reloadList($event)"
    (navigate)="navChange($event)"
  ></app-detail>

  <app-header-list
    *ngIf="!infoCurrentState && !showInfo"
    (containers)="changeContainers($event)"
    (devices)="changeDevices($event)"
    (lastSites)="changeLastSites($event)"
    (filter)="updateFilter($event)"
    (site)="siteRequest($event)"
    (incident)="filterIncidentChange($event)"
    (incidents)="changeIncidents($event)"
    (addIncidentDevices)="changeIncidentDevices($event)"
    (statusDays)="changeStatusDays($event)"
    (confidenceRadius)="changeConfidenceRadius($event)"
    (torn)="changeTorn($event)"
    [filterContainers]="filterContainers"
    [filterDevices]="filterDevices"
    [filterLastSites]="filterLastSites"
    [filterIncidents]="filterIncidents"
    filterAddIncident="filterAddIncident"
    [filterSite]="filterSiteCode"
    [filterIncident]="filterIncident"
    [filterStatusDays]="filterStatusDays"
    [filterConfidenceRadius]="filterConfidenceRadius"
    [listSites]="listSites"
    [listIncidents]="listIncidents"
    [listAddIncident]="listAddIncident"
    [listIncidentDevices]="listIncidentDevices"
    [isEmptyIncident]="isEmptyIncident"
    [isEmptyIncidentDevice]="isEmptyIncidentDevice"
    [loading]="loading"
    [exportData]="exportData"
    [exportType]="exportType"
    (showList)="reloadList()"
  >
  </app-header-list>
  <div class="list-content" *ngIf="!showInfo">
    <ng-container
      *ngIf="!sortedCurrentStateList || sortedCurrentStateList.length > 0"
    >
      <div class="array-wrapper">
        <app-state-displayer-column-names
          (checkboxUpdate)="updateAllIncidentDevices($event)"
          type="List"
          [hasCheckbox]="hasHeaderCheckbox()"
        ></app-state-displayer-column-names>
        <div
          class="data"
          [infiniteScroll]="loadMore"
          [scrollRatio]="0.4"
          [initScrollThreshold]="50"
        >
          <div *ngIf="!sortedCurrentStateList">
            <div
              *ngFor="let _ of [].constructor(20)"
              class="skeleton-placeholders"
            >
              <app-state-displayer-skeleton-line
                type="List"
              ></app-state-displayer-skeleton-line>
            </div>
          </div>
          <div
            *ngFor="let currentState of sortedCurrentStateList; let i = index"
            (click)="showInfoContainer($event, currentState)"
          >
            <div *ngIf="i" class="information-divider"></div>
            <app-state-displayer
              (openCategory)="onOpenCategory($event)"
              (handleDevice)="handleDeviceIncident($event)"
              [(currentState)]="sortedCurrentStateList[i]"
              [isChecked]="allChecked"
              [listDisplay]="true"
            ></app-state-displayer>
          </div>
        </div>
      </div>
      <div class="list-info">
        <p
          *ngIf="
            sortedCurrentStateList?.length > 0 &&
            totalFiteredCurrentStates !== undefined
          "
          class="total-devices"
        >
          {{
            "list.totalDevices"
              | translate
                : {
                    currentDeviceNumber: sortedCurrentStateList.length,
                    totalDeviceNumber: totalFiteredCurrentStates
                  }
          }}
        </p>
        <div class="download-button">
          <app-export-excel
            class="list-tfoot-export"
            [type]="exportType"
            [data]="exportData"
            [disabled]="!sortedCurrentStateList?.length"
          ></app-export-excel>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="sortedCurrentStateList && !sortedCurrentStateList.length"
    >
      <p>{{ "list.noContainers" | translate }}</p>
    </ng-container>
  </div>
  <app-incident-sidenav
    *ngIf="showCreateIncident && !showInfo"
    (closed)="closeIncidentCreation()"
    (incidentCreated)="addIncidentList($event)"
    [devices]="listIncidentDevices"
  ></app-incident-sidenav>
</div>
