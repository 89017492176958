<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over" class="sidenav">
    <div class="no-v-scroll-sidebar">
      <div class="header-sidebar">
        <div
          (click)="onCloseSidenav(sidenav, listSidebar)"
          class="sidenav-button-close"
        >
          <img class="icon-close-nav" src="./assets/img/close-blue.png" />
        </div>

        <div class="toggle-group" name="toggleType" aria-label="Font Style">
          <button
            class="toggle-button line"
            [class.active]="filterCategory.all"
            (click)="filterCategoryUpdate('all')"
          >
            {{ "sideNav.all" | translate }}
          </button>
          <button
            [class.active]="filterCategory.specific"
            class="toggle-button line"
            (click)="filterCategoryUpdate('specific')"
          >
            {{ "sideNav.specific" | translate }}
          </button>
          <button
            [class.active]="filterCategory.polyvalent"
            class="toggle-button"
            (click)="filterCategoryUpdate('polyvalent')"
          >
            {{ "sideNav.polyvalent" | translate }}
          </button>
        </div>

        <input
          [(ngModel)]="containerType"
          (ngModelChange)="
            containerType = $event.toUpperCase(); containerTypeChanged()
          "
          type="search"
          id="containertypeSidebar"
          placeholder="{{ 'misc.placeholder.ctType' | translate }}"
        />

        <div class="selectAll" id="selectAll">
          <div id="checkboxFilterContainer">
            <div (click)="manageAllContainerTypes($event)" id="selectAllDiv">
              <mat-checkbox
                color="primary"
                id="checkbox"
                disabled
                [checked]="allContainerTypeAreChecked"
                [indeterminate]="allContainertypeIsIndeterminate"
              >
              </mat-checkbox>
            </div>
          </div>
          <div id="arrowContainer">
            <mat-menu #appMenu="matMenu">
              <button mat-menu-item (click)="checkAllContainer()">
                {{ "sideNav.all" | translate }}
              </button>
              <button mat-menu-item (click)="uncheckAllContainer()">
                {{ "sideNav.nothing" | translate }}
              </button>
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="appMenu">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div
        #listSidebar
        class="list-sidebar"
        [infiniteScroll]="loadMore"
        [scrollRatio]="0.66"
        [initScrollThreshold]="200"
      >
        <mat-table class="sidenav-table" #table [dataSource]="dataSource">
          <ng-container matColumnDef="checked">
            <mat-header-cell *matHeaderCellDef matSort>
              {{ columnName }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-checkbox
                color="primary"
                (change)="cTypeFilterUpdated($event, element)"
                [checked]="element.checked"
              >
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>
              {{ columnName }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.code }} - {{ element.label }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

<div (click)="sidenav.toggle()" class="sidenav-button-open">
  <img class="icon" src="./assets/img/drawer.png" />
</div>
