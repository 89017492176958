import { Component, OnChanges } from "@angular/core";
import { Input } from "@angular/core";

@Component({
  selector: "app-status-dots",
  templateUrl: "./status-dots.component.html",
  styleUrls: ["./status-dots.component.scss"]
})
export class StatusDotsComponent implements OnChanges {
  @Input() status: any;
  @Input() showType: string = "show";

  public statusActive = {
    red: false,
    orange: false,
    green: true,
    blue: false,
    grey: false
  };

  constructor() {}

  ngOnChanges(): void {
    if (this.status) this.updateStatus();
  }

  private updateStatus(): void {
    let orangeActive: boolean = false;

    for (let status of this.status) {
      if (status?.marker.includes("orange")) {
        if (status[this.showType]) orangeActive = true;
      } else {
        this.statusActive[status.marker] = status[this.showType];
      }
    }
    this.statusActive["orange"] = orangeActive;
  }
}
