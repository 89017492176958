<div class="buttons-holder">
  <div class="hybrids">
    <app-button
      *ngIf="!isUploaded"
      iconRight="upload"
      [labelPath]="'manager.Reference.matching.hybrides.upload' | translate"
      type="filled"
      (clicked)="openDialog('Hybrides')"
    >
    </app-button>
    <app-button
      *ngIf="!isUploaded"
      iconRight="download"
      [labelPath]="
        'manager.Reference.matching.hybrides.downloadFile' | translate
      "
      type="filled"
      (clicked)="downloadFile('Hybrides')"
      [loading]="hybridDownloadLoading"
    >
    </app-button>
    <app-button
      *ngIf="!isUploaded"
      iconRight="download"
      [labelPath]="
        'manager.Reference.matching.hybrides.downloadTemplate' | translate
      "
      type="filled"
      (clicked)="downloadTemplate('Hybrides')"
    >
    </app-button>
  </div>
  <div class="bfr-cu">
    <app-button
      *ngIf="!isUploaded"
      iconRight="upload"
      [labelPath]="'manager.Reference.matching.BFR_CU.upload' | translate"
      type="filled"
      (clicked)="openDialog('BFR_CU')"
    >
    </app-button>
    <app-button
      *ngIf="!isUploaded"
      iconRight="download"
      [labelPath]="'manager.Reference.matching.BFR_CU.downloadFile' | translate"
      type="filled"
      (clicked)="downloadFile('BFR_CU')"
      [loading]="bfrCuDownloadLoading"
    >
    </app-button>
    <app-button
      *ngIf="!isUploaded"
      iconRight="download"
      [labelPath]="
        'manager.Reference.matching.BFR_CU.downloadTemplate' | translate
      "
      type="filled"
      (clicked)="downloadTemplate('BFR_CU')"
    >
    </app-button>
  </div>
  <app-file-uploader
    *ngIf="isUploaded"
    [datas]="uploadDatas"
    [properties]="properties[typeSelected]"
    [fileName]="fileName"
    [referenceSummary]="referenceSummary"
    uploaderType="reference"
    [columnNames]="columnNames"
    [separatorError]="separatorError"
    [separatorErrorWarnings]="separatorErrorWarnings"
    (goBack)="backOnList()"
    (doProvisioning)="doProvisioning($event)"
  >
  </app-file-uploader>
</div>
