<button
  class="single-info"
  [class]="value ? 'enabled' : 'disabled'"
  [cdkCopyToClipboard]="value ?? ''"
  (cdkCopyToClipboardCopied)="onClipboardCopy($event)"
>
  <div class="text-content">
    <p class="label-info">{{ labelPath | translate }}</p>
    <p class="value-info">
      {{ value || ("map-detail.infoPlaceholder" | translate) }}
    </p>
  </div>
  <div *ngIf="icon && !displayCheck" class="button-icon">
    <svg-icon
      *ngIf="iconColor"
      [name]="icon"
      [svgStyle]="{ fill: iconColor, 'height.px': 32, 'width.px': 32 }"
    ></svg-icon>
    <svg-icon
      *ngIf="!iconColor"
      [name]="icon"
      [svgStyle]="{ fill: '#161616', 'height.px': 32, 'width.px': 32 }"
    ></svg-icon>
  </div>
  <div class="copy-content" *ngIf="!displayCheck && value">
    <svg-icon
      name="copy"
      [svgStyle]="{ fill: '#757575', 'height.px': 16, 'width.px': 16 }"
    ></svg-icon>
    <p>{{ "map-detail.copy" | translate }}</p>
  </div>
  <div class="copied-content" *ngIf="displayCheck && value">
    <svg-icon
      name="check"
      [svgStyle]="{ fill: '#757575', 'height.px': 16, 'width.px': 16 }"
    ></svg-icon>
    <p>{{ "map-detail.copied" | translate }}</p>
  </div>
</button>
