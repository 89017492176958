import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from "@angular/core";
import { ReferenceSummary } from "../../../../../models/reference-summary";

@Component({
  selector: "app-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"]
})
export class FileUploaderComponent implements OnInit, OnChanges {
  @Input() datas: string[][];
  @Input() properties: any[] = [];
  @Input() columnNames: any[] = [];
  @Input() fileName: string;
  @Input() referenceSummary: ReferenceSummary;
  @Input() uploaderType: string;
  @Input() loadingPercentage?: number;
  @Input() separatorError: boolean;
  @Input() separatorErrorWarnings: string[];
  @Output() goBack: EventEmitter<any> = new EventEmitter();
  @Output() doVerifying: EventEmitter<any> = new EventEmitter();
  @Output() doProvisioning: EventEmitter<any> = new EventEmitter();

  private readonly VALID_PATH =
    "../../../../../assets/img/manager/valid-step.svg";
  private readonly NON_VALID_PATH =
    "../../../../../assets/img/manager/non-valid-step.svg";
  private readonly NOT_VERIFIED = "notVerified";
  private readonly VERIFIED = "Verified";
  private readonly NOT_UPLOADED = "notUploaded";
  private readonly UPLOADED = "Uploaded";

  authorizedExtensions: string[] = ["csv", "CSV"];
  displayDatas: string[][];

  fileColumns: number;
  fileRows: number;

  columnMatching: any[] = [];

  loading: boolean = true;

  finalDatas: any[];

  isProvisioning: boolean = false;

  constructor() {}

  ngOnInit() {
    this.loading = true;
    if (this.referenceSummary && !this.referenceSummary.details)
      this.referenceSummary.details = [];
  }

  ngOnChanges(changes) {
    if (
      changes.datas &&
      changes.datas.currentValue &&
      changes.datas.currentValue.length === 0 &&
      !changes.datas.firstChange
    ) {
      this.referenceSummary = { warnings: [], info: [] };
      this.referenceSummary.warnings.push(`Format issue:`);
      this.referenceSummary.warnings.push(` • The uploaded file is empty.`);
      this.loading = false;
    }
    if (changes.datas && this.datas && this.datas.length !== 0) {
      this.fileRows = this.datas.length;
      if (this.datas[0].length !== 0) {
        this.fileColumns =
          this.columnNames && this.columnNames.length !== 0
            ? this.columnNames.length
            : this.datas[0].length;
      }
      this.displayDatas = this.datas.slice(0, 6);
      for (let i = 0; i < this.fileColumns; i++) {
        this.columnMatching.push({});
      }
      this.doMatching();
      switch (this.uploaderType) {
        case "reference":
          if (!this.checkFormat()) {
            this.loading = false;
          } else {
            this.loading = false;
          }
          break;
        case "offContract":
          if (!this.checkFormat()) {
            this.loading = false;
          } else {
            this.loading = false;
          }
          break;
        default:
          this.loading = false;
          this.referenceSummary.warnings.push(
            `An error has occurred please try again.`
          );
          this.referenceSummary.details.push(
            `Wrong uploaderType : ${this.uploaderType}`
          );
          break;
      }
    }
    if (changes.referenceSummary) {
      this.loading = false;
      switch (this.uploaderType) {
        case "reference":
          break;
        case "offContract":
          if (
            changes.referenceSummary &&
            changes.referenceSummary.currentValue &&
            changes.referenceSummary.currentValue.step
          ) {
            switch (changes.referenceSummary.currentValue.step) {
              case this.NOT_VERIFIED:
                break;
              case this.VERIFIED:
                break;
              case this.NOT_UPLOADED:
                break;
              case this.UPLOADED:
                break;
              default:
                this.loading = false;
                this.referenceSummary.warnings.push(
                  `An error has occurred please try again.`
                );
                this.referenceSummary.details.push(
                  `Wrong step in referenceSummary : ${changes.referenceSummary.currentValue.step}`
                );
                break;
            }
          }
          break;
        default:
          this.loading = false;
          this.referenceSummary.warnings.push(
            `An error has occurred please try again.`
          );
          this.referenceSummary.details.push(
            `Wrong uploaderType : ${this.uploaderType}`
          );
          break;
      }
    }
    if (
      !this.isProvisioning &&
      this.datas &&
      this.datas.length &&
      this.columnMatching &&
      this.columnMatching.length
    ) {
      this.next();
    }
  }

  doMatching() {
    let i: number = 0;
    let propertiesNumber: number = this.properties.length;
    for (const column of this.columnMatching) {
      column.value =
        i < propertiesNumber ? this.properties[i].property : undefined;
      i += 1;
      this.doColumnMatching(column);
    }
  }

  doColumnMatching(column: any) {
    const choices: string[] = [];
    for (const prop of this.properties) {
      choices.push(prop.property);
    }
    column.choices = choices;
  }

  selectProp(choice: string, column: any) {
    column.value = choice;
    this.doColumnMatching(column);
  }

  next() {
    this.loading = true;
    this.finalDatas = this.datas.map((value, _index) => {
      if (value && value.length !== 0 && value[0] !== "") {
        const object: any = {};
        for (let i = 0; i < this.columnMatching.length; i++) {
          if (this.columnMatching[i].value) {
            object[this.columnMatching[i].value] = value[i];
          }
        }
        return object;
      } else {
        console.log("error", value);
      }
    });
    this.finalDatas = this.finalDatas.filter(
      (value) => value !== null && value !== undefined
    );
    if (this.uploaderType === "reference") {
      this.doProvisioning.emit({ datas: this.finalDatas });
      this.isProvisioning = true;
    }
  }

  checkFormat(): boolean {
    this.referenceSummary = { warnings: [], info: [], details: [] };
    if (
      this.authorizedExtensions.indexOf(this.fileName.split(".").pop()) === -1
    ) {
      this.referenceSummary.warnings.push(`Format issue:`);
      this.referenceSummary.warnings.push(
        ` • The uploaded file does not match any of the following formats :\n`,
        this.authorizedExtensions.join(" / ")
      );
      this.loading = false;
      return false;
    }
    if (this.properties.length !== this.columnMatching.length) {
      const diff: number = this.properties.length - this.columnMatching.length;
      if (diff > 0) {
        this.referenceSummary.warnings.push(`Format issue:`);
        this.referenceSummary.warnings.push(
          ` • ${diff} columns are missing in the uploaded file.`
        );
      } else {
        this.referenceSummary.warnings.push(`Format issue:`);
        this.referenceSummary.warnings.push(
          ` • There are ${diff * -1} extra columns in the uploaded file.`
        );
      }
      this.loading = false;
      return false;
    }
    if (this.separatorError) {
      this.referenceSummary.warnings = this.separatorErrorWarnings;
      this.loading = false;
      return false;
    } else {
      switch (this.uploaderType) {
        case "offContract":
          let init = true;
          for (let i = 0; i < this.columnMatching.length; i++) {
            if (!this.columnMatching[i].choices.includes(this.columnNames[i])) {
              if (init) {
                this.referenceSummary.warnings.push(`Format issue:`);
                init = false;
              }
              this.referenceSummary.warnings.push(
                ` • The column "${this.columnNames[i]}" in your file does not match any of the accepted "${this.columnMatching[i].choices}" properties.`
              );
              this.loading = false;
              return false;
            }
          }
          return true;
        case "reference":
          return true;
        default:
          this.loading = false;
          this.referenceSummary.warnings.push(
            `An error has occurred please try again.`
          );
          this.referenceSummary.details.push(
            `Wrong uploaderType : ${this.uploaderType}`
          );
          return false;
      }
    }
  }

  back() {
    this.goBack.emit({});
  }

  download() {
    let downloadDatas: string = "";
    if (this.referenceSummary.info.length != 0)
      downloadDatas = this.formateFile(
        this.referenceSummary.info,
        downloadDatas,
        "Infos:"
      );
    if (this.referenceSummary.warnings.length != 0)
      downloadDatas = this.formateFile(
        this.referenceSummary.warnings,
        downloadDatas,
        "Warnings:"
      );
    if (this.referenceSummary.details.length != 0)
      downloadDatas = this.formateFile(
        this.referenceSummary.details,
        downloadDatas,
        "Details:"
      );
    const blob = new Blob([downloadDatas], {
      type: "text/plain;charset=UTF-8"
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  formateFile(
    referenceSummaryPart: string[],
    downloadDatas: string,
    title: string
  ): string {
    downloadDatas += `${title.toUpperCase()}\n\n`;
    let count = 0;
    for (const line of referenceSummaryPart) {
      count++;
      downloadDatas += `${line}\n`;
      if (count === referenceSummaryPart.length) downloadDatas += "\n";
    }
    return downloadDatas;
  }
}
